import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue7/6-raport-2.jpg';
import pic1 from '../../assets/issue7/1_katarzyna_pindral.jpg';
import pic4 from '../../assets/issue7/4-pst-ok.jpg';

import famur1 from '../../assets/issue7/2_elektryki1.jpg'
import famur2 from '../../assets/issue7/2_elektryki2.jpg'

import chiny1 from '../../assets/issue7/7-chiny-1.jpg'
import chiny2 from '../../assets/issue7/7-chiny-2.jpg'
import chiny3 from '../../assets/issue7/7-chiny-3.jpg'
import chiny4 from '../../assets/issue7/7-chiny-4.jpg'
import chiny5 from '../../assets/issue7/7-chiny-5.jpg'

import elgor1 from '../../assets/issue7/3-elgor1.jpeg'
import elgor2 from '../../assets/issue7/3-elgor2.jpeg'
import elgor3 from '../../assets/issue7/3-elgor3.jpeg'

import raport1 from '../../assets/issue7/6-raport-1.jpg'
import raport2 from '../../assets/issue7/6-raport-2.jpg'
import raport3 from '../../assets/issue7/6-raport-3.jpg'

import impact1 from '../../assets/issue7/5-impact1.jpg'
import impact2 from '../../assets/issue7/5-impact2.jpg'
import impact3 from '../../assets/issue7/5-impact3.jpg'
import impact4 from '../../assets/issue7/5-impact4.jpg'





export default function Issue2() {

  const _links = [
    {label: "Grenevia: informujemy o zmianach w zarządzie Impactu", target: "section1"},
    {label: "FAMUR zaprezentował się na targach w Chinach", target: "section1b"},
    {label: "Elektryfikacja floty samochodowej Famuru", target: "section2"},
    {label: "Zarząd TDJ z wizytą w zakładach Elgór+Hansen", target: "section3"},
    {label: "Projekt Solartechnik sprzedał KGHM farmę PV o mocy 5,2 MW", target: "section4"},
    {label: "IMPACT na największych targach autobusowych w Europie", target: "section5"},
    {label: "Dostaliśmy nagrodę za nasz raport!", target: "section6"},
  ];
  const _title = "Newsletter #7, październik 2023";
;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Mamy to! Grupa Grenevia z nagrodą!</h1>
        <div>
          <p>Nasza praca została doceniona – raport zintegrowany Grupy Grenevia najlepszym debiutem w konkursie Forum Odpowiedzialnego Biznesu i Deloitte. Ponadto w nowym newsletterze o zmianach w zarządzie jednej ze spółek, wizytach na targach, sprzedaży farmy PV i eko-zmianie we flocie samochodowej.</p>
          <p>Zapraszamy do lektury!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Grenevia: <span className="grText">informujemy o zmianach w zarządzie Impactu</span></h1>
        <p><strong>W październiku do zarządu Impact Clean Power Technology dołączyła Katarzyna Pindral, która będzie odpowiadać za obszar finansowy tej spółki.</strong></p>
        <Image pic={pic1} />

        <p>Katarzyna Pindral jest ekspertką w dziedzinie zarządzania i finansów oraz biegłym rewidentem. Pełniła rolę CEO w FTF Services sp. z o.o. – centrum usług wsparcia biznesu w zakresie księgowym, prawnym, podatkowym i kadrowo-płacowym. Wcześniej jako CFO w Grupie Cersanit odpowiadała za finanse grupy kapitałowej działającej nie tylko w Polsce, ale także w innych krajach Europy. Swoje kompetencje zawodowe w zakresie podatkowym i audytorskim kształtowała również w Rödl & Partner, HSBC Bank Polska i innych firmach.</p>
        <p>Aktualny skład zarządu tej spółki przedstawia się następująco: prezes Ireneusz Kazimierski oraz na stanowiskach wiceprezesów Bartek Kras, Katarzyna Pindral, Ninoslav Sotirov.</p>
        </TextContainer>

        <TextContainer id="section1b" theme="light">

<h1>FAMUR <span className="grText">zaprezentował się na targach w Chinach</span></h1>
<p><strong>Od 25 do 28 października FAMUR uczestniczył w targach China Coal & Mining Expo 2023, na których miał okazję zaprezentować swoje najnowsze osiągnięcia i innowacje w branży górniczej.</strong></p>

<p>„Gwiazdą” stoiska Famuru był flagowy produkt tej spółki na rynek chiński - Mikrus. To kompaktowy i wszechstronny system do eksploatacji cienkich pokładów węgla, który zdobywa coraz większą popularność w międzynarodowym sektorze górniczym. Jego wydajność, niezawodność i innowacyjne rozwiązania przyciągnęły uwagę licznych odwiedzających z całego świata.</p>
<Gallery>
          <Image pic={chiny1} /> 
          <Image pic={chiny2} /> 
          <Image pic={chiny3} /> 
          <Image pic={chiny4} /> 
          <Image pic={chiny5} /> 
        </Gallery>

<p>Targi China Coal & Mining Expo to doskonała okazja do nawiązywania kontaktów, wymiany doświadczeń i prezentacji produktów globalnej publiczności.</p>  </TextContainer>


      <TextContainer id="section2" theme="dark">

        <h1>Elektryfikacja<span className="grText"> floty samochodowej Famuru</span></h1>
        <Gallery>
          <Image pic={famur1} /> 
          <Image pic={famur2} /> 
        </Gallery>
              <p><strong>W ostatnim czasie flota samochodowa Famuru powiększyła się o dwa nowe nabytki – samochody elektryczne.</strong></p>
        <p>FAMUR dokonał inwestycji w przyszłość, wprowadzając do swojego zespołu samochody elektryczne. Te pojazdy nie tylko pozwolą oszczędzać na kosztach paliwa, ale także redukują ślad węglowy, co jest zgodne z zobowiązaniem do dbania o środowisko.</p>
        <p>Dzięki tej nowej flocie samochodowej są gotowi przekształcić swoją mobilność, a także pokazać zorientowanie na zrównoważony rozwój.</p>        
        </TextContainer>

      <TextContainer id="section3" theme="light">

        <h1>Zarząd TDJ <span className="grText">z wizytą w zakładach Elgór+Hansen</span></h1>
        <Gallery>
          <Image pic={elgor1} /> 
          <Image pic={elgor2} /> 
          <Image pic={elgor3} /> 
        </Gallery>
 <p><strong>Na początku października Elgór+Hansen SA przywitał wizytę studyjną zarządu TDJ w zakładach produkcyjnych w Chorzowie oraz w Zabrzu. Podczas zwiedzania firma miała okazję zaprezentować rozwijającą się produkcję kontenerowych stacji transformatorowych.</strong></p>
        <p>– <em>To, co zobaczyliśmy, to nie tylko bardzo dobrze zorganizowana produkcja, ale także niezwykle zmotywowane i zaangażowane zespoły. I jest to zdecydowanie jeden z kluczowych atutów E+H. Jednak to nie wszystko! To, co zwróciło naszą uwagę, to ilość nowych pomysłów na rozwijanie sprzedaży. E+H nie stoi w miejscu. Dzięki swojej przewadze technologicznej, spółka realizuje coraz więcej projektów w segmencie nowej energetyki. To świetny przykład adaptacji i rozwoju w dynamicznie zmieniającym się środowisku biznesowym</em> – podsumował spotkanie <strong>Michał Ciszek, CPO w TDJ SA</strong>.</p>
        <p>Elgór + Hansen odpowiada za segment elektroenergetyki w Grupie Grenevia, tworząc rozwiązania z zakresu automatyki dla przemysłu i sektora dystrybucji energii. W 2022 roku poszerzył swoją ofertę o kontenerowe stacje transformatorowe, dzięki czemu wszedł na rynek OZE.</p>
      </TextContainer>

      <TextContainer id="section4" theme="dark">

        <h1>Projekt Solartechnik <span className="grText">sprzedał KGHM farmę PV o mocy 5,2 MW</span></h1>
        <p><strong>Niespełna miesiąc od podpisana przedwstępnej umowy Projekt Solartechnik z KGHM Polska Miedź na sprzedaż gotowych farm fotowoltaicznych, pierwsza z ośmiu zakontraktowanych elektrowni słonecznych zmienia właściciela.</strong></p>
        <Image pic={pic4  } />
<p>Farma fotowoltaiczna w Żukach w gminie Turek (woj. wielkopolskie) o mocy 5,2 MW należy już do miedziowej spółki.</p>
<p>– <em>Niecały rok temu, już w trakcie budowy elektrowni słonecznej w Żukach, podpisaliśmy z KGHM umowę typu Corporate Power Purchase Agreement (cPPA) na dostawę zielonej energii. Dziś gotowa i działająca farma PV staje się własnością jednej z największych polskich spółek skarbu państwa</em> – mówi <strong>Marta Rzepecka, Project Transaction Manager w Projekt Solartechnik</strong>.</p> 
<p>Eksperci Projekt Solartechnik odpowiedzialni byli za jej projekt, budowę oraz energetyzację. Instalację tworzy blisko 10 tys. modułów fotowoltaicznych marki JinkoSolar, falowniki oraz stacje transformatorowe dostarczył największy globalny producent tego typu urządzeń, firma Huawei, złącze kablowe do połączenia stacji z energetyką zapewniła firma Elgór+Hansen.</p> </TextContainer>

<TextContainer id="section5" theme="light">

<h1>IMPACT <span className="grText">na największych targach autobusowych w Europie</span></h1>
<p><strong>W dniach 7-12 września w Brukseli odbyły się targi Busworld Europe – to największe na naszym kontynencie i najważniejsze na świecie wydarzenie dla branży transportu autobusowego. Nie mogło tam zabraknąć przedstawicieli Impact Clean Power Technology. Odwiedzający stoisko naszej spółki mogli spotkać ekspertów z działów inżynieryjnych, sprzedaży, ale również członków zarządu firmy.</strong></p>

<p>W tym roku IMPACT zaprezentował baterie budowane pod konkretne oczekiwania klienta (custom design), nowe generacje uznanych na świecie produktów UVES POWER LTO GEN 3.0, UVES ENERGY i UVES LFP oraz autorski system zarządzania baterią BMS. Odwiedzający mogli również dowiedzieć się więcej o możliwościach GigafactoryX, gdzie produkcja będzie oparta na najnowocześniejszej w Europie linii produkcyjnej baterii heavy duty, a także o europejski łańcuchu dostaw m.in. dzięki umowom z LG i Freyrem.</p>
<Gallery>
          <Image pic={impact1} /> 
          <Image pic={impact2} /> 
          <Image pic={impact3} /> 
          <Image pic={impact4} /> 
        </Gallery>

<p>– <em>W samym sercu Europy zaprezentowaliśmy najnowocześniejsze systemy bateryjne, które od wielu lat wykorzystują najwięksi producenci autobusów na świecie tacy jak Solaris Bus&Coatch czy ADL. Doświadczenie zdobyte w trakcie wieloletniej współpracy z liderami rynku, tysiące dostarczonych systemów bateryjnych i BMS-ów jest czynnikiem, który wyróżnia nas na tle konkurencji i pozwala wyznaczać nowe trendy na rynku</em> – mówi <strong>Ireneusz Kazimierski, prezes zarządu Impact Clean Power Technology SA</strong>.</p>  </TextContainer>

<TextContainer id="section6" theme="dark">

<h1>Dostaliśmy nagrodę <span className="grText">za nasz raport!</span></h1>
<p><strong>25 października podczas gali na warszawskiej Giełdzie Papierów Wartościowych głoszono laureatów 17. edycji konkursu Raporty Zrównoważonego Rozwoju, organizowanego przez Forum Odpowiedzialnego Biznesu i Deloitte. Do konkursu zgłoszono 86 publikacji. Grenevia otrzymała nagrodę za raport zintegrowany w kategorii Debiut!</strong></p>
<Gallery>
          <Image pic={raport1} /> 
          <Image pic={raport2} /> 
          <Image pic={raport3} /> 
        </Gallery>
        
<p>Jest to najbardziej prestiżowy tego typy konkurs w naszym kraju, do którego przystępują największe marki na rynku. Grenevia zgłosiła się do niego po raz pierwszy, przedstawiając raport dokumentujący naszą zieloną przemianę od 2022 roku. W jego tworzeniu brali udział pracownicy wszystkich naszych segmentów biznesowych – FAMUR, Elgór + Hansen, Projekt Solartechnik i Impact Clean Power Technology.</p>

<p>Konkursowe raporty oceniało jury złożone z ekspertów i ekspertek z dziedziny ESG, odpowiedzialnego biznesu, zrównoważonych finansów i pokrewnych obszarów. Dodatkowo przyznawano nagrodę internautów wyłanianą w głosowaniu online, do udziału w którym zachęcaliśmy we wrześniu.</p>
<p>Podczas gali nagrodę w imieniu Grenevii odebrała Agata Maj, menedżerka ds. zrównoważonego rozwoju i ESG w Grenevii. Gratulacje dla zespołów Grupy Grenevia złożyła też prezes Beata Zawiszowska.</p>
<p>– <em>Bez waszego zaangażowania i pracy zespołowej nie bylibyśmy w stanie przeprowadzić tego projektu. To dzięki temu, że potrafimy współpracować i dzielić się wiedzą, efekt wspólnej pracy został zauważony przez wymagające grono ekspertów rynkowych. Docenili oni wysiłek, transparentność oraz umiejętność komunikowania przez nas działań z obszaru zrównoważonego rozwoju. ESG to aspekty, na które coraz większy nacisk kładzie rynek finansowy, zarówno inwestorzy, jak i nasi kontrahenci. Tym samym nie zamierzamy ustawać w wysiłkach na rzecz realizacji Strategii Zrównoważonego Rozwoju poszczególnych segmentów i Grupy Grenevia</em> – pogratulowała <strong>prezes Zawiszowska</strong>.</p>
<p>Oto pełna lista nagrodzonych firm i organizacji:</p>
<p><strong>Kategoria Raport zrównoważonego rozwoju</strong></p>
<ul><li>Nagroda główna: Santander Bank Polska</li>
<li>Wyróżnienie (ex aequo): LPP i CCC Group</li></ul>

<p><strong>Kategoria Raport zintegrowany</strong></p>
<ul><li>Nagroda główna: BNP Paribas Bank Polska</li>
<li>Wyróżnienie: ING Bank Śląski</li></ul>


<p><strong>Kategoria Debiut</strong></p>
<ul><li>Nagroda za raport zrównoważonego rozwoju: Światłowód Inwestycje</li>
<li>Nagroda za raport zintegrowany: Grenevia</li></ul>


<p><strong>Kategoria Raport organizacji pozabiznesowej</strong></p>
<ul><li>Nagroda: Fundacja Dorastaj z Nami</li></ul>


<p><strong>Nagrody specjalne</strong></p>
<ul><li>Nagrodę Giełdy Papierów Wartościowych otrzymał ING Bank Śląski</li>
<li>Nagroda internautów/ek powędrowała do FAKRO</li></ul>


 </TextContainer>

     

    <SiteFooter />

    </>
  )
}

import React from 'react';


// elements:
import Aside from '../../elements/Aside';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';

// assets:
import introPic from '../../assets/issue1/intro.jpg';
import pic1 from '../../assets/issue1/pic1.jpg';


export default function Issue1() {

  const _links = [{label: "Nowe otwarcie - nowa nazwa", target: "section1"},{label: "ESG kluczem", target: "section2"}];
  const _title = "Newsletter #1, kwiecień 2023";
  const _aside = <Aside image= {pic1} theme='light'>
    <p>– Przez dwie dekady udowodniliśmy, że potrafimy rozwijać FAMUR, także przez dokonywanie przemyślanych i sprawnie przeprowadzonych przejęć w branży okołogórniczej i wydobywczej. W sumie zrealizowaliśmy 19 akwizycji. Dzięki temu zbudowaliśmy działającą globalnie organizację. Takie samo podejście – głęboko przemyślane i przeanalizowane pod kątem wpływu na biznes i rozwój naszej Grupy kontynuujemy w przypadku Grenevii. Adaptujemy w obszarze OZE wypracowany wcześniej model budowy wartości. Posiadane przez nas kompetencje i doświadczenia wykorzystujemy do dokonania bardzo istotnej zmiany modelu biznesowego, który będzie odpowiadał nowym czasom i wyzwaniom, jakie stoją przed gospodarką na drodze do niskoemisyjności. Doskonale wpisują się w to projekty związane z megratrendem zielonej transformacji, w tym sektor OZE i elektromobilność – mówi Mirosław Bendzera, prezes zarządu Grenevii.</p>
  </Aside>;

  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>To już oficjalne! <br />Grenevia z&nbsp;wpisem do KRS</h1>
        <div>
          <p>Dziś zakończyliśmy proces zmiany nazwy - Famur SA stał się Grenevią SA. Tym samym, przed naszą spółką oraz jej segmentami biznesowymi otwiera się nowy rozdział historii. </p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark" aside={_aside}>
        <h1>Nowe otwarcie – nowa nazwa</h1>
        <p><strong>Chcemy działać w oparciu o zasady zrównoważonego rozwoju, dlatego kluczowym elementem naszej strategii biznesowej są obszary ESG (E – Środowisko, S – Społeczna odpowiedzialność i G – Ład korporacyjny). Znajduje to odzwierciedlenie w zaprezentowanej w styczniu 2023 roku Strategii Zrównoważonego Rozwoju Grupy Grenevia.</strong></p>

        <p>Nasza SZR jest oparta o pięć filarów i głównych celów. Ich wybór jest konsekwencją analizy tak globalnych trendów, jak i naszych możliwości. W ich ramach zdefiniowaliśmy 22 projekty wdrożeniowe, a ich realizacja ma przynosić wymierne i pozytywne efekty dla naszego biznesu i jego otoczenia.</p>

        <p>Przypominamy główne cele naszego zrównoważonego rozwoju:</p>
        <ul>
          <li>Poprzez rozwój nowych segmentów ~70%* przychodów Grupy w 2024 roku powinno pochodzić z innych źródeł niż sektor węgla energetycznego.</li>
          <li>Do 2030 roku zredukujemy emisje gazów cieplarnianych w Zakresach 1 i 2, w stosunku do roku 2021, o 40% i jednocześnie będziemy rozwijali nowe segmenty biznesowe, które pozwolą na uniknięcie w gospodarce nawet do 2,3 mln t CO<sub>2</sub>; emisji do roku 2030.</li>
          <li>W odniesieniu do cyklu życia naszych produktów sukcesywnie wdrażamy zasady gospodarki o obiegu zamkniętym i zarządzania emisyjnością produktów.</li>
          <li>Wdrażamy najlepsze międzynarodowe praktyki w zakresie ładu korporacyjnego, zarządzania ryzykiem oraz postępowania z partnerami biznesowymi.</li>
          <li>Prowadzimy inicjatywy pozytywnie oddziałujące na pracowników, lokalne społeczności oraz dostawców.</li>
        </ul>
        
        <p>Wierzymy, że oparcie strategii biznesowej o zrównoważony rozwój pozwoli nam zbudować wartość nie na lata, a dekady, wspierając naszą konkurencyjność.</p>
      
      </TextContainer>

      <TextContainer id="section2" theme="light">
        <h1>ESG kluczem</h1>
        <p><strong>Chcemy działać w oparciu o zasady zrównoważonego rozwoju, dlatego kluczowym elementem naszej strategii biznesowej są obszary ESG (E – Środowisko, S – Społeczna odpowiedzialność i G – Ład korporacyjny). Znajduje to odzwierciedlenie w zaprezentowanej w styczniu 2023 roku Strategii Zrównoważonego Rozwoju Grupy Grenevia.</strong></p>

        <p>Nasza SZR jest oparta o pięć filarów i głównych celów. Ich wybór jest konsekwencją analizy tak globalnych trendów, jak i naszych możliwości. W ich ramach zdefiniowaliśmy 22 projekty wdrożeniowe, a ich realizacja ma przynosić wymierne i pozytywne efekty dla naszego biznesu i jego otoczenia.</p>

        <p>Przypominamy główne cele naszego zrównoważonego rozwoju:</p>
        <ul>
          <li>Poprzez rozwój nowych segmentów ~70%* przychodów Grupy w 2024 roku powinno pochodzić z innych źródeł niż sektor węgla energetycznego.</li>
          <li>Do 2030 roku zredukujemy emisje gazów cieplarnianych w Zakresach 1 i 2, w stosunku do roku 2021, o 40% i jednocześnie będziemy rozwijali nowe segmenty biznesowe, które pozwolą na uniknięcie w gospodarce nawet do 2,3 mln t CO<sub>2</sub>; emisji do roku 2030.</li>
          <li>W odniesieniu do cyklu życia naszych produktów sukcesywnie wdrażamy zasady gospodarki o obiegu zamkniętym i zarządzania emisyjnością produktów.</li>
          <li>Wdrażamy najlepsze międzynarodowe praktyki w zakresie ładu korporacyjnego, zarządzania ryzykiem oraz postępowania z partnerami biznesowymi.</li>
          <li>Prowadzimy inicjatywy pozytywnie oddziałujące na pracowników, lokalne społeczności oraz dostawców.</li>
        </ul>
        
        <p>Wierzymy, że oparcie strategii biznesowej o zrównoważony rozwój pozwoli nam zbudować wartość nie na lata, a dekady, wspierając naszą konkurencyjność.</p>
      
      </TextContainer>
  


    <SiteFooter />

    </>
  )
}

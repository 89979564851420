import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue16/16_hero.jpg';
import pic1 from '../../assets/issue15/Grenevia.jpg';
import pic2 from '../../assets/issue16/bendzera-jakubowski.jpg';
import pic3 from '../../assets/issue16/pst.jpg';
import pic4 from '../../assets/issue16/impact.jpg';
import pic5 from '../../assets/issue12/bzawiszowska.jpg'
import pic6 from '../../assets/issue16/yearbook.jpg'
import pic7 from '../../assets/issue16/pst2.jpg'
import pic8 from '../../assets/issue15/Impact5.jpg'
import pic9 from '../../assets/issue16/1.jpg'
import pic10 from '../../assets/issue16/2.jpg'
import pic11 from '../../assets/issue16/3.jpg'
import pic12 from '../../assets/issue16/4.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'
import tj from '../../assets/issue14/jakubowski.jpg'

export default function Issue2() {

  const _links = [
    {label: "Dziękujemy za udział w badaniu!", target: "section1"},
    {label: "Zmiany z zarządzie FAMUR SA", target: "section2"},
    {label: "Stacja E+H stanęła na farmie PV w Silesia Innovation Park", target: "section3"},
    {label: "PST Trade i Amiblu Poland z kontraktem cPPA", target: "section4"},
    {label: "Program poleceń PST Trade", target: "section4a"},
    {label: "IMPACT Clean Power Technology na 4 branżowych targach", target: "section5"},
    {label: "Trwa głosowanie na Kobietę Biznesu 2024", target: "section6"},
    {label: "Premiera Yearbooka 2024 UNGC", target: "section7"},
  ];

  const _title = "Newsletter #8(16), październik 2024";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– Grupa Grenevia kontynuowała swoją działalność, skupiając się na rozwoju czterech segmentów biznesowych, co przełożyło się na wzrost przychodów operacyjnych w każdym z nich. Naszym priorytetem pozostaje tworzenie wartości we wszystkich segmentach, z naciskiem na wspieranie transformacji w kierunku gospodarki niskoemisyjnej – komentuje <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={bz} theme='light'>
      <p>– Umowa zawarta z konsorcjum banków stanowi ważny krok z punktu widzenia dalszego rozwoju Grupy Grenevia. Pozwala nam na zapewnienie średnioterminowego, elastycznego finansowania, które będzie wspierać zarówno tradycyjną część naszego biznesu, jak i zieloną transformację pozostałych segmentów – mówi <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Badanie zaangażowania za nami</h1>
        <div>
          <p>W części spółek Grupy Grenevia po raz kolejny odbyło się badanie zaangażowania pracowników. Jego wyniki poznamy dopiero na początku grudnia, ale już teraz dziękujemy za Wasz udział!</p><p>
To jednak nie jedyne ważne informacje dot. naszej Grupy.</p><p>
Zapraszamy do lektury!
</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="light">

<h1>Dziękujemy<span className="grText"> za udział w badaniu!</span></h1>


<p><strong>Ostatnie dni października upłynęły w części naszej grupy pod znakiem badania zaangażowania pracowników. Dziękujemy wszystkim za tak liczny odzew i wypełnienie ankiet! To one pozwalają doskonalić naszą organizację.</strong></p>
<Image pic={pic1} />

<p>W badaniu zaangażowania wzięły udział: segment FAMUR, Grenevia SA, Elgór + Hansen SA oraz Impact Clean Power Technology SA. Przeprowadzono je – zależnie od spółki – między 14 a 24/25 października, przy wsparciu zewnętrznej firmy Mercer (dawniej Kincentric).</p>
<h3>Dlaczego to badanie jest tak istotne? </h3>
<ul><li>Pozwala zrozumieć faktyczny poziom satysfakcji i motywacji.</li>
<li>Identyfikuje obszary, w których odnosimy sukcesy i te wymagające poprawy.</li>
<li>Dostarcza cennych informacji, które wpływają na decyzje dotyczące rozwoju firmy.</li></ul>
<p>Wyniki tego badania będą fundamentem dla udoskonalenia procesów wewnętrznych i poprawy środowiska pracy i kultury organizacyjnej.</p>

<h2>Poznaj frekwencję w badaniu zaangażowania:</h2>

<Gallery>
          <Image pic={pic9} /> 
          <Image pic={pic10} />
          <Image pic={pic11} /> 
          <Image pic={pic12} /> 
        </Gallery>

</TextContainer>


      <TextContainer id="section2" theme="dark">

        <h1>Zmiany<span className="grText"> w zarządzie FAMUR SA</span></h1>
       
        <p><strong>Mirosław Bendzera złożył rezygnację z funkcji prezesa zarządu FAMUR SA. Prezes Bendzera był związany z TDJ od 16 lat, a od dekady stał na czele Famuru, który jest obecnie częścią Grupy Grenevia. Na stanowisku prezesa zastąpił go Tomasz Jakubowski, dotychczasowy wiceprezes FAMUR SA.</strong></p>
        <Image pic={pic2} />
  
        <p><strong>Mirosław Bendzera</strong> zasiadał w zarządach spółek związanych z TDJ od 2009 roku. Pełnił funkcję prezesa zarządu w Odlewni Żeliwa Śrem SA, Pioma-Odlewnia sp. z o.o. oraz Polskiej Grupy Odlewniczej SA. Do zarządu FAMUR S dołączył w 2014 roku. W czasie jego prezesury doszło do konsolidacji polskiej branży okołogórniczej, a FAMUR poszerzył swoją globalną obecność. W 2021 roku ogłoszono strategiczną zmianę biznesowych kierunków rozwoju Famuru zgodnie z zielonym megatrendem. Formalna transformacja FAMUR SA w holding inwestycyjny Grenevia nastąpiła w 2023 roku. Prezes Bendzera podjął się wówczas roli kierowania rozwojem segmentu FAMUR, który wszedł w nowy obszar działalności – energetykę wiatrową.</p>
        <p>Prezesowi Bendzerze dziękujemy za lata pracy i trud włożony w rozwój Famuru, a następnie za wspieranie jego transformacji. Życzymy również dalszych sukcesów.</p><p>
Od 26 października br. nowym prezesem FAMUR SA jest <strong>Tomasz Jakubowski</strong>. Podczas swojej kariery zawodowej pełnił on funkcje nadzorcze i był członkiem zarządów spółek związanych z TDJ, m.in. w Grupie Zamet. W latach 2003-2010 piastował stanowiska prezesa i wiceprezesa zarządu FAMUR SA oraz prezesa FAZOS SA. Był również związany z Kompanią Węglową SA jako członek rady nadzorczej, a następnie wiceprezes zarządu. Do FAMUR SA powrócił w 2018 roku.</p>
<p>Na stanowiskach wiceprezesów w zarządzaniu spółką Tomaszowi Jakubowskiemu towarzyszyć będą dotychczasowi członkowie zarządu: Beata Zawiszowska, Dawid Gruszczyk oraz Paweł Majcherkiewicz.</p>
        
        
        </TextContainer>

        <TextContainer id="section3" theme="light">

<h1>Stacja E+H<span className="grText"> stanęła na farmie PV w Silesia Innovation Park</span></h1>
<p><strong>W Silesia Innovation Park w Zabrzu stanęła, wyprodukowana przez Elgór + Hansen, kontenerowa stacja transformatorowa FUTURA. Jest ona częścią infrastruktury nowo powstałej farmy fotowoltaicznej.</strong></p>
<p>Stacja transformatorowa FUTURA wyróżnia się łatwością montażu oraz elastycznością, umożliwiającą dostosowanie obudowy do wymagań inwestora. W tym przypadku wybrano model FUTURA GLASS, którego estetyka doskonale wpisuje się w nowoczesny charakter Silesia Innovation Park. Lekka konstrukcja stacji sprawia, że nie wymaga ona budowy fundamentów, co znacząco redukuje koszty transportu i instalacji.</p>
<Youtube src='MErwi-q_x3s' />
<p>Nowa inwestycja w Silesia Innovation Park jest kolejnym przykładem na to, jak innowacyjne rozwiązania infrastrukturalne mogą wspierać rozwój energetyki odnawialnej, jednocześnie wpasowując się w architekturę przemysłową regionu.</p>
 </TextContainer>

 <TextContainer id="section4" theme="dark">

<h1>PST Trade <span className="grText">i Amiblu Poland z kontraktem cPPA</span></h1>
<p><strong>PST Trade zapewni firmie Amiblu Poland 100% zielonej energii, pozyskiwanej m.in. z farm fotowoltaicznych należących do Projekt Solartechnik.</strong></p>
<Image pic={pic3} />
<p>Firma Amiblu projektuje i dostarcza rury i kształtki GRP o długiej żywotności dla ścieków, wody deszczowej, wody pitnej, nawadniania, energetyki wodnej i zastosowań przemysłowych.</p>
<p>– <em>Korporacyjną umową na zakup energii połączyliśmy słońce i wodę. Elektrownie fotowoltaiczne Projekt Solartechnik generują energię, którą w ramach PST Trade oferujemy naszym klientom. Tym razem naszym partnerem biznesowym jest firma, która chce wyjść naprzeciw kryzysowi wodnemu za pomocą nowoczesnych i inteligentnych rozwiązań oraz inspirować ludzi na całym świecie do doceniania wody</em> – mówi <strong>Tomasz Bodetko, wiceprezes PST Trade</strong>. – <em>Cieszymy się z tej współpracy</em> – dodaje.</p>
<p>PST Trade dostarczać będzie Amiblu zakontraktowaną energię od początku 2025 r. W transakcji doradzała, obsługująca ponad 200 przedsiębiorstw z całej Polski w zakresie optymalizacji strategii zakupu energii elektrycznej, firma EIDOS.</p>
 </TextContainer>

 <TextContainer id="section4a" theme="light">

<h1>Program poleceń<span className="grText"> PST Trade</span></h1>
<p><strong>Przedsiębiorcy mogą wybierać dostawcę energii spoza lokalnych sieci – PST Trade, spółka z grupy Projekt Solartechnik, oferuje alternatywę dla firm produkcyjnych i usługowych, niezależnie od poziomu ich zużycia energii. PST stworzyło również programu poleceń, przygotowanym specjalnie dla pracowników spółek z Grupy TDJ i Grenevia.
</strong></p>
<Image pic={pic7} />
<p>Aby wziąć w nim udział, należy skontaktować się drogą mailową, korzystając z adresu polecenia@projektsolartechnik.com – zainteresowani otrzymają szczegółowe informacje, regulamin programu oraz dokumenty do wypełnienia.</p>
<p>Po pozytywnej weryfikacji zgłoszenia i podpisaniu umowy pomiędzy zgłoszonym podmiotem a PST Trade, nastąpi wypłata świadczenia pieniężnego dla osoby polecającej.</p>
<h3>Wysokość świadczenia wynosi:</h3>
<ul><li>3 PLN/MWh brutto dla wolumenu do 1000 MWh</li>
<li>2 PLN/MWh brutto dla wolumenu od 1001 MWh do 10000 MWh</li>
<li>1  PLN/MWh brutto dla wolumenu powyżej 10001 MWh</li></ul>
 </TextContainer>


      <TextContainer id="section5" theme="dark">

        <h1>IMPACT Clean Power Technology<span className="grText"> na 4 branżowych targach</span></h1>
              <p><strong>Wrzesień i październik to intensywny okres w branży e-mobilności, pełen inspirujących wydarzeń. IMPACT zaprezentował swoje rozwiązania aż na czterech międzynarodowych targach i konferencjach. Firma zdobyła również tytuł Lidera Elektromobilności 2024, odebrany przez wiceprezesa Bartka Krasa podczas Kongresu Nowej Mobilności w Łodzi.</strong></p>
              <Image pic={pic4} />
               <p>Zespół IMPACT przedstawił możliwości GigafactoryX oraz pełnowymiarowe makiety produktów na międzynarodowych targach IAA Transportation 2024 w Hanowerze oraz podczas Energetab w Bielsku-Białej. Na obu wydarzeniach zaprezentowano stanowisko GigaCube – nowoczesna, trójwymiarowa konstrukcja przyciągała odwiedzających swoją innowacyjnością i dynamizmem.</p>
               <p>Firma była także partnerem Kongresu Nowej Mobilności w Łodzi, gdzie eksperci IMPACT wzięli udział w panelach dyskusyjnych na temat przyszłości e-mobilności i kierunków rozwoju tej branży w Polsce i na świecie.</p>
               <p>Jesienny kalendarz zamknęły targi TRANSEXPO w Kielcach, gdzie wyróżniony tytułem Bus of the Year 2025 został autobus marki Solaris – wyposażony w baterie IMPACT, które stanowią serce jego napędu elektrycznego. To wyjątkowy powód do dumy dla obu współpracujących firm!</p>
        </TextContainer>


        <TextContainer id="section6" theme="light">

        <h1>Trwa głosowanie na <span className="grText">Kobietę Biznesu 2024</span></h1>
              <p><strong>Prezes Grenevii Beata Zawiszowska znalazła się w gronie 10 kobiet nominowanych przez Puls Biznesu do plebiscytu Kobieta Biznesu 2024. Zachęcamy do głosowania!</strong></p>
              <Image pic={pic5} />
               <p>Zagłosować w plebiscycie Pulsu Biznesu możesz: <a href="https://www.pb.pl/100kobietbiznesu/#glosowanie" target='_blank'><strong>TUTAJ</strong></a>.</p>
               <p>Plebiscyt trwa do 13 listopada, a wyniki zostaną ogłoszone 29 listopada. Głosować można raz, a po oddaniu głosu warto zostawić adres e-mail, aby Puls Biznesu mógł przesłać podsumowanie plebiscytu oraz ranking „100 Kobiet Biznesu 2024” w dniu ogłoszenia wyników.</p>
               <p>Projekt Kobiet Biznesu, zainaugurowany w 2011 roku, obejmuje plebiscyt, w którym głosy oddają czytelnicy i internauci, oraz ranking 100 przedsiębiorczyń opracowywany przez ekspertów. Celem obu inicjatyw jest promowanie przedsiębiorczości kobiet w polskim biznesie, ich integracja oraz inspirowanie do realizacji celów i marzeń zawodowych.</p>
        </TextContainer>


        <TextContainer id="section7" theme="esg">

<h1>Premiera <span className="grText">Yearbooka 2024 UNGC</span></h1>
      <p><strong>24 października br. swoją premierę miał tegoroczny Yearbook UN Global Compact Network Poland, podsumowująca mijający rok i projekty realizowane w ramach programów prowadzonych przez polską część UNGC. Wśród zaprezentowanych w księdze firma znalazła się Grenevia.</strong></p>
      <Image pic={pic6} />
       <p>Premiera raportu odbyła się 24 października 2024 r. podczas Gali UN DAY z udziałem przedstawicieli rządu, administracji, NGOs, biznesu, ambasadorów UN Global Compact Network Poland, świata nauki, kultury i sportu.</p>
       <p>Yearbook 2024 zobaczysz <a href="https://ungc.org.pl/wp-content/uploads/2024/10/Yearbook_2024_UNGCNP_.pdf" target='_blank'><strong>TUTAJ</strong></a>.</p>
       <p>Grenevia jest członkiem UNGC od 2023 roku. Organizacja ta skupia ﬁrmy tworzące strategie i działania w oparciu o polityki ONZ, w szczególności o Cele Zrównoważonego Rozwoju i Dziesięć Zasad United Nations Global Compact dotyczących takich obszarów jak prawa człowieka, standardy pracy, ochrona środowiska, przeciwdziałanie korupcji.</p>
</TextContainer>
    


    <SiteFooter />

    </>
  )
}

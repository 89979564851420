import React from 'react';

export default function Button(props) {

  const _button = props.type === 'link' ?
  <a className="grButton" href={props.action}>
    {props.label}
  </a> 
  :
  <button className="grButton" onClick={props.action}>
    {props.label}
  </button> 
  
  return _button
  
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue15/Grenevia.jpg';
import pic1 from '../../assets/issue15/Grenevia.jpg';
import pic2 from '../../assets/issue15/targi2.jpg';
import pic3 from '../../assets/issue15/PST.jpg';
import pic4 from '../../assets/issue15/Impact1.jpg';
import pic5 from '../../assets/issue15/Impact2.jpg'
import pic6 from '../../assets/issue15/Impact3.jpg'
import pic7 from '../../assets/issue15/Impact4.jpg'
import pic8 from '../../assets/issue15/Impact5.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'
import tj from '../../assets/issue14/jakubowski.jpg'

export default function Issue2() {

  const _links = [
    {label: "Grenevia pozyskała 850 mln zł finansowania", target: "section1"},
    {label: "Wyniki finansowe Grupy Grenevia 1H2024", target: "section2"},
    {label: "FAMUR i Elgór + Hansen: zapraszamy na targi EXPO Katowice", target: "section3"},
    {label: "Projekt Solartechnik: zawarliśmy przedwstępną umowę sprzedaży farm PV", target: "section4"},
    {label: "IMPACT: trwa rozruch nowej linii produkcyjnej", target: "section5"},
  ];

  const _title = "Newsletter #7(15), wrzesień 2024";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– Grupa Grenevia kontynuowała swoją działalność, skupiając się na rozwoju czterech segmentów biznesowych, co przełożyło się na wzrost przychodów operacyjnych w każdym z nich. Naszym priorytetem pozostaje tworzenie wartości we wszystkich segmentach, z naciskiem na wspieranie transformacji w kierunku gospodarki niskoemisyjnej – komentuje <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={bz} theme='light'>
      <p>– Umowa zawarta z konsorcjum banków stanowi ważny krok z punktu widzenia dalszego rozwoju Grupy Grenevia. Pozwala nam na zapewnienie średnioterminowego, elastycznego finansowania, które będzie wspierać zarówno tradycyjną część naszego biznesu, jak i zieloną transformację pozostałych segmentów – mówi <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Grenevia pozyskała 850 mln zł finansowania</h1>
        <div>
          <p>Wracamy po wakacyjnej przerwie i od razu z ważnymi informacjami! W ostatnich dniach Grenevia SA podpisała umowę kredytową z konsorcjum banków na 850 mln zł – o szczegółach tego finansowania przeczytacie w pierwszej wiadomości. Zachęcamy również do zapoznania się z wynikami finansowymi Grupy Grenevia i jej segmentów za pierwsze półrocze tego roku. W kolejnych newsach informujemy o targach EXPO w Katowicach, w których udział wzięli FAMUR i Elgór + Hansen oraz przedwstępnej umowie sprzedaży farm PV Projekt Solartechnik. IMPACT z kolei przedstawia, jak wygląda nowa linia produkcyjna do systemów bateryjnych.
          </p><p>Zapraszamy do lektury!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="light">

<h1>850 mln zł<span className="grText"> finansowania dla Grenevii</span></h1>


<p><strong>Grenevia SA zawarła umowę kredytową z konsorcjum banków na kwotę 850 mln zł. Środki te zapewnią kilkuletnie, elastyczne finansowanie, które będzie wspierać zieloną transformację Grupy Grenevia, jak i działalność tradycyjnej części biznesu naszej spółki portfelowej. Zastąpią również aktualne bankowe źródła finansowania oraz obligacje o łącznej wartości ok. 930 mln zł.</strong></p>
<Image pic={pic1} />

<p>Kredytodawcami Grenevii jest konsorcjum banków Pekao SA, PKO BP SA i BNP Paribas Bank Polska SA, a kredyt został podzielony na dwie transze – Transzę A w wysokości 700 mln złotych z finansowaniem typu Sustainability-Linked Loan (SLL) oraz Transzę B w kwocie 150 mln zł.</p>
<p>To pierwsza umowa na taki rodzaj finansowania o tak dużej skali w historii Grenevii.</p>
<p>– <em>Umowa zawarta z konsorcjum banków stanowi ważny krok z punktu widzenia dalszego rozwoju Grupy Grenevia. Pozwala nam na zapewnienie średnioterminowego, elastycznego finansowania, które będzie wspierać zarówno tradycyjną część naszego biznesu, jak i zieloną transformację pozostałych segmentów</em> – mówi <strong>prezes Grenevia SA Beata Zawiszowska</strong>.</p>
<p>Pozyskane w ramach Transzy A środki będę przede wszystkim przeznaczone na finansowanie inwestycji realizowanych zgodnie ze strategią biznesowej transformacji spółki, m.in. rozwoju lub modernizacji projektów czy transakcji nabycia udziałów lub akcji w innych podmiotach, jak również na wcześniejszy wykup wyemitowanych w 2021 roku obligacji. Finansowanie to może również być wykorzystane na działalność segmentu FAMUR niezwiązaną z sektorem wydobywczym, a w szczególności koncentrującą się na sektorze energetyki wiatrowej.</p>
<p>Z kolei przeznaczeniem Transzy B jest finansowanie ogólnych potrzeb korporacyjnych segmentu FAMUR oraz refinansowanie istniejących kredytów krótkoterminowych.</p>

</TextContainer>


      <TextContainer id="section2" theme="dark" aside={_aside1}>

        <h1>Wyniki finansowe<span className="grText"> Grupy Grenevia 1H2024</span></h1>
       
       
        <p><strong>W pierwszym półroczu 2024 roku Grupa Grenevia osiągnęła przychody w wysokości 922 mln zł. EBITDA wyniosła 244 mln zł, a zysk netto - 52 mln zł.</strong></p>
  
        <p>Przychody Grupy Grenevia w pierwszym półroczu 2024 roku były o 158 mln zł wyższe niż w analogicznym okresie ubiegłego roku. Ich wzrost jest efektem zwiększenia przychodów we wszystkich segmentach biznesowych Grupy. EBITDA wzrosła o 7 proc. rok do roku. Z kolei zysk netto Grupy Grenevia w pierwszym półroczu 2024 roku wyniósł 52 mln zł. Zanotowano również spadek długu netto.</p>
        <p>Konsekwentnie wdrażamy również strategię zrównoważonego rozwoju naszej Grupy. W pierwszym półroczu tego roku 62% przychodów Grupy Grenevia (uwzględniając przychody ze sprzedaży farm fotowoltaicznych klasyfikowanych jako środki trwałe i nie wliczanych do przychodów zgodnie ze MSSF) pochodziło z innych źródeł niż sektor węgla energetycznego.</p>
        <p>Więcej o wynikach w segmentach naszej Grupy przeczytacie <a href="www.grenevia.com.
https://grenevia.com/2024/08/29/grupa-grenevia-podsumowala-i-polrocze-2024-roku/" target='_blank'>TUTAJ</a>.</p>
        
        
        </TextContainer>

        <TextContainer id="section3" theme="light">

<h1>FAMUR i Elgór + Hansen: <span className="grText">za nami targi EXPO Katowice 2024!</span></h1>
<p><strong>FAMUR i Elgór + Hansen przedstawiły swoje oferty na wspólnym stoisku na Międzynarodowych Targach EXPO Katowice 2024, które odbyły się między 4 a 6 września w Katowicach.</strong></p>
<Image pic={pic2} />
<p>Na stanowisku dwóch naszych spółek przygotowano dla odwiedzających do przetestowania innowacyjne rozwiązania, m.in.: aplikacje multimedialne oparte na wirtualnej i rozszerzonej rzeczywistości oraz rozwiązania umożliwiające zdalne sterowanie maszynami.</p>
<p>Dużym zainteresowaniem cieszyła się pilotażowa wersja stanowiska zdalnego sterowania maszyną - odwiedzający mogli sterować kombajnem chodnikowym R-150, który znajdował się w tunelu testowym w oddziale FAMUR Mining.</p>
<p>Zaprezentowano także prototyp pierwszej polskiej przekładni wiatrowej, która została wyprodukowana w zakładzie FAMUR Gearo!</p>
<p>Międzynarodowe Targi EXPO Katowice są dedykowane firmom związanym z przemysłem ciężkim. Ekspozycja, organizowana od 40 lat, to platforma licznych spotkań biznesowych, kongresów, konferencji i sympozjów. Wystawcy i uczestniczący w targach mają okazję przedstawienia oferty i zaprezentowania maszyn, urządzeń oraz technologicznych rozwiązań, wspierających transformację energetyczną, działającą w otoczeniu górnictwa, energetyki, hutnictwa i innych gałęzi przemysłu ciężkiego.</p>
<Youtube src='BK6xD3-J_pw?' />
 </TextContainer>

 <TextContainer id="section4" theme="dark">

<h1>Projekt Solartechnik: <span className="grText">zawarliśmy przedwstępną umowę sprzedaży farm PV</span></h1>
<p><strong>Pod koniec lipca tego roku podpisano przedwstępną umowę sprzedaży farm fotowoltaicznych między spółką projektową z Grupy Projekt Solartechnik a NextEnergy Capital. Wartość kontraktu to ok. 180 mln zł</strong></p>
<Image pic={pic3} />
<p>Przedwstępna umowa obejmuje sprzedaż gotowych farm fotowoltaicznych o łącznej mocy blisko 50 MW, dla których Projekt Solartechnik świadczy obecnie także kompleksową obsługę z zakresu O&M (ang. Operation and Maintenance). Farmy fotowoltaiczne zlokalizowane są w województwach: dolnośląskim, kujawsko-pomorskim, lubuskim, łódzkim, małopolskim, pomorskim, podlaskim, podkarpackim, wielkopolskim i zachodniopomorskim.</p>
<p>Jest to pierwsza transakcja Projekt Solartechnik zrealizowana przez wewnętrzy dział M&A Grupy Projekt Solartechnik.</p>
<p>Zgodnie z ostatnim raportem okresowym w pierwszym półroczu tego roku Projekt Solartechnik posiadał w swoim portfelu projekty na różnym etapie rozwoju w Polsce i zagranicą o łącznej mocy ok. 5,3 GW. Prawie 3,2 GW łącznej mocy projektów posiadało warunki przyłączeniowe, w tym ok. 1,2 GW PV i ok. 1.8 GW magazyny energii.</p>
 </TextContainer>


      <TextContainer id="section5" theme="light">

        <h1>IMPACT: <span className="grText">trwa rozruch nowej linii produkcyjnej</span></h1>
              <p><strong>W spółce IMPACT Clean Power Technology zakończono już inwestycję w GigafactoryX i rozpoczęto rozruch nowej, wysoko zautomatyzowanej linii produkcyjnej. Jej pełne uruchomienie planowane jest na III kwartał tego roku. Zapraszamy do obejrzenia galerii zdjęć.</strong></p>
              <Gallery>
          <Image pic={pic4} /> 
          <Image pic={pic5} />
          <Image pic={pic6} /> 
          <Image pic={pic7} /> 
          <Image pic={pic8} /> 
        </Gallery>
               <p>Wykorzystanie nowej linii umożliwi poprawę jakości produkowanych baterii, zwiększenie mocy produkcyjnych ICPT z 0,6 GWh do 1,2 GWh jeszcze w tym w roku, a docelowo do nawet 4 GWh, stosownie do dynamiki popytu.</p>
               <p>Warto również dodać, że w segmencie e-mobilności zakończono wdrożenie systemu SAP, którego uruchomienie nastąpiło w lipcu.</p>
        </TextContainer>

    

    <SiteFooter />

    </>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue14/0.jpg';
import pic1 from '../../assets/issue14/1.jpg';
import pic2 from '../../assets/issue14/2.jpg';
import pic3 from '../../assets/issue14/3.jpg';
import pic4 from '../../assets/issue14/4.jpg';
import pic5 from '../../assets/issue14/5.jpg'
import pic6 from '../../assets/issue14/6.jpg'
import pasek from '../../assets/issue14/pasek.png'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'
import tj from '../../assets/issue14/jakubowski.jpg'

export default function Issue2() {

  const _links = [
    {label: "Milionowe dofinansowania z UE", target: "section1"},
    {label: "FAMUR: nasz kombajn chodnikowy pojedzie do Czech", target: "section2"},
    {label: "Elgór + Hansen: mamy dwa nowe kontrakty na stacje transformatorowe", target: "section3"},
    {label: "Projekt Solartechnik: to był czas targów i konferencji", target: "section4"},
    {label: "IMPACT: uczestniczyliśmy w największych targach OZE w Europie", target: "section5"},
    {label: "ESG: FAMUR przechodzi na zieloną energię dzięki umowie z PST Trade", target: "section6"},
  ];

  const _title = "Newsletter #6(14), lipiec 2024";
  const _aside1 =
    <Aside image={tj} theme='light'>
      <p>– W segmencie FAMUR dokładamy wszelkich starań, aby nasze zakłady produkcyjne miały wypracowane plany na rzecz zwiększenia efektywności energetycznej i redukcji emisji gazów cieplarnianych, a tym samym dążymy w naszej działalności operacyjnej do ograniczenia zużycia zasobów takich jak m.in. energia elektryczna. Umowa z PST Trade wpisuje się w Strategię Zrównoważonego Rozwoju Grupy Grenevia, w ramach której zobowiązaliśmy się, że do końca 2024 r. 35% energii w całej Grupie będzie pochodzić z OZE – podsumowuje <strong>Tomasz Jakubowski, wiceprezes zarządu FAMUR SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Unijna pomoc napędzi nasz rozwój</h1>
        <div>
          <p>Segmenty Grupy Grenevia mają szansę skorzystać z unijnego wsparcia na projekty, które pozwolą im się rozwijać w obszarach OZE. Grenevia SA podpisała już umowę na dofinansowanie z Funduszy UE, dzięki któremu rusza duża inwestycja w segmencie FAMUR. Natomiast wnioski Elgór + Hansen oraz Impact Clean Power Technology zostały pozytywnie ocenione, a ich projekty wybrane do dofinansowania. Spółki Grupy Grenevia podpisały również nowe kontrakty i wzięły udział w branżowych targach.
          </p><p>Zapraszamy do lektury!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Milionowe <span className="grText">dofinansowania z UE</span></h1>
       
       
        <p><strong>Grenevia SA otrzymała dofinansowanie w ramach programu Fundusze Europejskie dla Śląskiego 2021-2027 – pod koniec czerwca została podpisana umowa w tej sprawie. Z kolei projekty Elgór + Hansen oraz Impact Clean Power Technology uzyskały pozytywne rekomendacje do dofinansowania do swoich projektów badawczo-rozwojowych w ramach Ścieżki Smart w obrębie Programu Fundusze Europejskie dla Nowoczesnej Gospodarki. Obecnie są na etapie przygotowań do umowy.</strong></p>
        <Image pic={pic1} />
        <Image pic={pasek} />
        <p>Grenevia SA otrzymała dofinansowanie na rozwój technologii i usług dla energetyki wiatrowej. W ramach projektu przewidziano m.in. zakup licencji na technologię produkcji turbiny wiatrowej, rozwój parku maszynowego pozwalający na rozszerzenie zakresu produkcji i modernizacji przekładni wiatrowych, a także doposażenie stanowiska do badań przekładni pod obciążeniem. <strong>Wartość całej inwestycji to ok. 68 mln zł, zaś przyznane dofinansowanie wynosi niemal 21 mln zł.</strong></p>
        <p>– <em>Przyznane dofinansowanie to ważny krok w naszej biznesowej transformacji, w szczególności dla segmentu FAMUR </em> – mówi prezes Grenevia SA <strong>Beata Zawiszowska</strong>, zaznaczając, że inwestycja wpisuje się także w strategię zrównoważonego rozwoju naszej Grupy.</p>
        <p>- <em>Dzięki dofinansowaniu z UE Grenevia, w ramach segmentu FAMUR, będzie mogła zrealizować ambitny projekt, który wzmacnia budowę polskiego, a tym samym europejskiego potencjału w zakresie energetyki wiatrowej, ale ma też drugi wymiar – będzie wspierał zatrudnienie w regionie mocno odczuwającym zmiany wywołane transformacją energetyczną</em> – dodaje <strong>Mirosław Bendzera</strong>, prezes Famuru. Szacuje się, że projekt pozwoli na przekwalifikowanie i utrzymanie lub utworzenie nowych miejsc pracy dla łącznie 100 osób. </p><p>Inwestycja wpisuje się w plany budowy polskiego wiatraka, czyli uruchomienia produkcji turbin wiatrowych, wykorzystując krajowe zasoby.</p>
        <h3>Projekty E+H i Impact</h3>
        <p>Projekt spółki Elgór + Hansen dotyczył będzie opracowania i rozpoczęcia produkcji nowych linii zarządzanych stacji transformatorowych SN/nN z innowacyjnym oprogramowaniem predykcyjnym. <strong>Wartość projektu to 26,28 mln zł, zaś przyznane na ten projekt dofinansowanie wynosi 9,18 mln zł.</strong></p>
        <p>Z kolei przedsięwzięcie Impactu dotyczy opracowania wielkoskalowego magazynu energii wykorzystującego ogniwa LTO second life wraz z systemem zarządzania energią, świadczenia usług sieciowych i zasilania zakładu przemysłowego. <strong>Wartość tego projektu to 14,91 mln zł, a przyznane dofinansowanie wynosi 5,61 mln zł.</strong></p>
        <p>Warto zaznaczyć, iż dofinansowanie w konkursie skierowanym wyłącznie do dużych przedsiębiorców otrzymały jedynie 73 projekty spośród 339 skierowanych do oceny merytorycznej.</p>
        <h2>Gratulujemy wszystkim zespołom projektowym!</h2>
        
        
        </TextContainer>

        <TextContainer id="section2" theme="light">

<h1>FAMUR: <span className="grText">nasz kombajn chodnikowy pojedzie do Czech</span></h1>
<p><strong>Jeden z produkowanych w segmencie FAMUR zaawansowanych kombajnów chodnikowych AM-50z-w weźmie udział w budowie i przebudowie sieci wodociągowej, kanalizacyjnej oraz kablowej w Pradze, dzięki czemu znacząco przyczyni się do poprawy infrastruktury czeskiej stolicy.</strong></p>
<Image pic={pic2} />
<p>Jak zaznacza FAMUR, nasza elastyczność i otwartość na potrzeby klienta pozwoliły nam dostosować kombajn do specyficznych wymagań czeskiej firmy Čermák a Hrachovec a.s.. Jesteśmy dumni, że udało nam się spełnić oczekiwania, dzięki czemu możemy realizować kontrakt, który już wkrótce przyniesie realne korzyści mieszkańcom miasta.</p>
<p>Kombajn chodnikowy AM-50z-w jest przeznaczony do drążenia wyrobisk o przekroju do 16,4 m² (wersja niska) oraz do 22 m² (wersja wysoka) w skałach o wytrzymałości na ściskanie do 60 MPa. Maszyna może być stosowana w podziemnych zakładach górniczych, w polach niemetanowych i metanowych, a dzięki elastycznemu podejściu inżynierów Famuru najnowsza maszyna będzie wspierać pracę drążeniowe zupełnie nie związane w górnictwem podziemnym!</p>
 </TextContainer>

 <TextContainer id="section3" theme="dark">

<h1>Elgór + Hansen: <span className="grText">mamy dwa nowe kontrakty na stacje transformatorowe</span></h1>
<p><strong>Mimo spowolnienia na rynku PV, spółce Elgór + Hansen udało się podpisać nowe kontrakty na dostawy kontenerowych stacji transformatorowych.</strong></p>
<Image pic={pic3} />
<p>W czerwcu br. podpisano kolejną już umowę z firmą Spectris Energy Sp. z o.o. na współpracę przy realizację budowy farm fotowoltaicznych o łącznej mocy 30MW na terenie Polski. W ramach umowy E+H dostarczy trzy zestawy kontenerowych stacji transformatorowych z transformatorami olejowymi, wraz z projektami uzgodnieniowymi. Nasz segment elektroenergetyki zaznacza, że jest to znaczący krok w rozwoju portfela produktów spółki.</p>
<p>Warto także podkreślić, że dzięki działaniom w mediach społecznościowych zdobyto nowego klienta - warszawską spółkę Energy Technologies Sp. z o.o.</p>
<p>– <em>Oferowany przez nas ośmiotygodniowy termin dostawy stacji na farmę okazał się decydujący w realizacji tego projektu. Mamy nadzieję, że współpraca ta będzie kontynuowana i zaowocuje kolejnymi projektami w przyszłości</em> – komentuje <strong>Rafał Szołtysik</strong>, dyrektor handlowy ds. rynku przemysłowego z Elgór + Hansen</p>
 </TextContainer>


      <TextContainer id="section4" theme="light">

        <h1>Projekt Solartechnik:<span className="grText">to był czas targów i konferencji</span></h1>
              <p><strong>Konferencja PSEW, Konferencja XTB Wall Street, Ogólnopolski Szczyt Energetyczny, targi Intersolar Europe 2024, będące częścią The smarter E Europe – dla Projekt Solartechnik czerwiec był intensywnym miesiącem targowo-konferencyjnym.</strong></p>
              <Image pic={pic4} />
               <p>Podczas konferencji Polskiego Stowarzyszenia Energetyki Wiatrowej, która była organizowana w Świnoujściu, przedstawiciele naszego segmentu OZE wymieniali się wiedzą dotyczącymi transformacji energetycznej, aktualnych wyzwań związanych z cable poolingiem i magazynami energii.</p>
               <p>Reprezentacji Projekt Solartechnik nie mogło również zabraknąć na 28. edycji Konferencji XTB Wall Street, zorganizowanej pod egidą Stowarzyszenie Inwestorów Indywidualnych.</p>
               <p>Z kolei tegoroczny Ogólnopolski Szczyt Energetyczny w Gdańsku odbywał się pod hasłem „Bezpieczeństwo energetyczne Europy – rewizja europejskiej polityki energetycznej.” Eksperci zgromadzeni na tym wydarzeniu dyskutowali m.in. o konieczności zwiększenia inwestycji w odnawialne źródła energii, co jest niezbędne dla przyszłości i bezpieczeństwa energetycznego Polski.</p>
               <p>Ostatni tydzień czerwca przedstawiciele segmentu OZE Grupy Grenevia zamknęli wizytą w Monachium, gdzie odbywały się najważniejsze targi dot. odnawialnej energii na naszym kontynencie, czyli The smarter E Europe, którego częścią jest wystawa Intersolar Europe 2024.</p>
        </TextContainer>

      <TextContainer id="section5" theme="dark">

        <h1>IMPACT: <span className="grText">uczestniczyliśmy w&nbsp;największych targach OZE w&nbsp;Europie</span></h1>
        
 <p><strong>W czerwcu w Monachium odbyły się największe w Europie i jedne z najważniejszych na świecie targi dotyczące odnawialnych źródeł energii i innowacji w energetyce – The smarter E Europe 2024. Ich częścią była konferencja InterBattery Europe 2024, w ramach której na zaproszenie Polskiego Stowarzyszenia Nowej Mobilności oraz Korea Battery Industry Association wystąpił wiceprezes IMPACT Bartek Kras.</strong></p>
 <Image pic={pic5} />
         <p>Wygłosił on prezentację na temat "Dobór ogniw do nowoczesnych systemów magazynowania energii w świetle ewoluujących technologii". W swoim wystąpieniu podkreślił m.in., że technologie ogniw stosowane obecnie w bateriach i magazynach energii już wkrótce zostaną zastąpione nowymi, o lepszych parametrach pod względem pojemności, gęstości energii, żywotności, bezpieczeństwa i możliwości recyklingu.</p>
         <p>Z kolei w zakresie zgodności ze stanem faktycznym poszczególnych parametrów technicznych, takich jak żywotność czy bezpieczeństwo deklarowanych przez producentów, zaznaczył, że kluczowe jest korzystanie wyłącznie z renomowanych i audytowalnych dostawców. Nadal do rozwiązania pozostaje kwestia bezpieczeństwa pożarowego i ogólnego bezpieczeństwa systemów, jak również poważny problem wysokich kosztów recyklingu technologii LFP.</p>
         <p>Warto dodać, że spółka Impact Clean Power Technology była już kilka lat temu zaangażowana w projekt dot. drugiego życia dla baterii autobusowych, które mimo że nie nadawałyby się już do pojazdów transportu publicznego ze względu na zaawansowany poziom zużycia, mogłyby zostać z powodzeniem wykorzystane w magazynach energii.</p>
         <p>Podczas targów obecny na miejscu zespół IMPACT uczestniczył też w spotkaniach biznesowych oraz poszerzał swoją wiedzę o rynku, poznając nowinki ze świata baterii i magazynów energii.</p>
         
         
         </TextContainer>

      <TextContainer id="section6" theme="esg" aside={_aside1}>

        <h1>ESG: <span className="grText">FAMUR przechodzi na zieloną energię dzięki umowie z PST Trade</span></h1>
        <p><strong>Oddziały produkcyjne Famuru są od tego miesiąca zasilane energią pochodzącą z OZE. Wszystko dzięki umowie z gwarancjami pochodzenia podpisanej z PST Trade, które należy do Grupy Projekt Solartechnik.</strong></p>
        <Image pic={pic6} />
        <p>Od 1 lipca br. do końca 2025 r. PST Trade będzie dostarczać Famurowi energię elektryczną pochodzącą z farm fotowoltaicznych należących do Projekt Solartechnik. Kontrakt przewiduje także pokrycie 100% wolumenu dostarczanej energii gwarancjami pochodzenia.</p>
        <p>Warto zaznaczyć, że oddziały Famuru posiadają również własne instalacje fotowoltaiczne o mocy 820kW.</p>
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue9/0_glowne2.jpg';
import pic1 from '../../assets/issue9/1_famur.jpg';
import pic2 from '../../assets/issue9/2_elgor-ok.jpg';
import pic3 from '../../assets/issue9/3_projektsolartechnik.jpg'
import pic4 from '../../assets/issue9/4_impact-ok.jpg'

import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'

export default function Issue2() {

  const _links = [
    {label: "FAMUR: ważny krok w dywersyfikacji oferty", target: "section1"},
    {label: "Elgór + Hansen: przechodzimy na zieloną energię", target: "section2"},
    {label: "Projekt Solartechnik: pozyskaliśmy 71 mln zł na budowę farm PV", target: "section3"},
    {label: "IMPACT: zyskaliśmy do 300 mln zł na zielony rozwój", target: "section4"},
    {label: "Rok od ogłoszenia Strategii Zrównoważonego Rozwoju Grupy Grenevia", target: "section5"},
  ];

  const _title = "Newsletter #1(9), styczeń 2024";
  const _aside1 =
    <Aside image={mb} theme='light'>
      <p>– FAMUR jest od lat związany z energetyką, co w naturalny sposób sprawia, że aspirujemy do mocnej pozycji w dynamicznie kształtującym się sektorze energetyki odnawialnej. Już dziś możemy się pochwalić wieloletnim doświadczeniem w projektowaniu i budowie urządzeń dla przemysłu, w tym zaawansowanych przekładni przemysłowych. Dlatego jestem przekonany, że FAMUR Gearo pomoże nam wykorzystać ten potencjał jeszcze lepiej i wesprze rozwój oraz budowę naszej rozpoznawalności w nowych obszarach pozagórniczych – mówi <strong>Mirosław Bendzera, prezes zarządu FAMUR SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Powstaje FAMUR Gearo</h1>
        <div>
          <p>W nowym obszarze będą skupione działania Famuru w zakresie energetyki wiatrowej i przekładni przemysłowych. Zapraszamy do lektury newslettera, gdzie znajdziecie więcej informacji ten temat FAMUR Gearo oraz inne najnowsze wiadomości o&nbsp;segmentach Grupy Grenevia.</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark" aside={[_aside1, _aside2]}>

        <h1>FAMUR: <span className="grText">ważny krok w&nbsp;dywersyfikacji oferty</span></h1>
        <p><strong>Coraz większe zaangażowanie segmentu FAMUR na rynku OZE poskutkowało podjęciem decyzji o utworzeniu w jego ramach obszaru Gearo, który będzie koncentrował działania prowadzone w zakresie energetyki wiatrowej i produkcji przekładni przemysłowych dla sektorów pozagórniczych.</strong></p>
        <Image pic={pic1} />

        <p>FAMUR ma ponad 40-letnie doświadczenie w produkcji przekładni zębatych dla przemysłu. Dzięki tym kompetencjom mógł poszerzyć - w ramach swojej biznesowej transformacji - działalność o rynki OZE. Oferta Famuru z zakresu energetyki wiatrowej obejmuje remonty, badania i doradztwo techniczne oraz serwis przekładni. Od lipca 2023 r., dzięki akwizycji Total Wind PL, wzmocniono ją o wymianę głównych komponentów turbin, a także usługi serwisowe i instalacyjne.</p>
        <p>Działalność operacyjna obszaru FAMUR Gearo będzie prowadzona w zakładzie w Katowicach-Piotrowicach, który od 1 lutego br. będzie posługiwał się nową nazwą, tj. Grenevia SA FAMUR Gearo Oddział w Katowicach. Zmieni się również jego struktura organizacyjna, którą uzupełni pięć nowych komórek: Sprzedaż, Wsparcie Sprzedaży, R&D, Serwis Wind i Zarządzanie Projektami.</p>
        <p>Rozwój FAMUR Gearo powierzono Pawłowi Majcherkiewiczowi, który od 1&nbsp;lutego br. wejdzie w skład zarządu FAMUR SA, a także uzupełni skład rady nadzorczej Total Wind PL sp. z o.o. Natomiast dyrektorem operacyjnym oddziału pozostaje Tomasz Marczak.</p>
        <p>Sama nazwa Gearo (czyt. giro) nawiązuje do angielskiego słowa gearbox oznaczającego przekładnię.</p></TextContainer>

        <TextContainer id="section2" theme="light">

<h1>Elgór + Hansen: <span className="grText">przechodzimy na zieloną energię</span></h1>
<p><strong>Od 1 stycznia tego roku w chorzowskiej siedzibie i zakładach Elgór+Hansen płynie prąd pochodzący wyłącznie z odnawialnych źródeł. To efekt podpisania miesiąc wcześniej umowy na dostawę zielonej energii elektrycznej z gwarancjami pochodzenia, ale również budowy w ub.r. własnych instalacji PV.</strong></p>
<Image pic={pic2} />
<p>Umowa na dostawę prądu pozyskiwanego w 100% z odnawialnych źródeł to ważny krok w realizacji strategii zrównoważonego rozwoju. Dzięki wykorzystaniu zielonych źródeł do zasilania chorzowskiej części produkcji (spółka ma także zakłady w Zabrzu), Elgór + Hansen prognozuje, że w 2024 roku zredukuje emisję CO<sub>2</sub> o ponad 350 ton.</p>
<p>Dodatkowo, E+H zakończył w ubiegłym roku pierwszy etap budowy instalacji fotowoltaicznej, umiejscowionej na dachu budynku administracyjnego w Chorzowie. Zainstalowana infrastruktura jest w stanie wyprodukować 48,5 MWh energii rocznie. W przyszłości firma planuje rozszerzenie mocy instalacji do 250 kWp, wykorzystując do tego dachy pozostałych budynków i hal produkcyjno-magazynowych.</p>
 </TextContainer>


      <TextContainer id="section3" theme="dark">

        <h1>Projekt Solartechnik:<span className="grText"> pozyskaliśmy 71&nbsp;mln&nbsp;zł na budowę farm PV</span></h1>
              <p><strong>Nasza spółka odpowiedzialna za segment wielkoskalowej fotwoltaiki pozyskała pod koniec ub.r. od Banku Polska Kasa Opieki SA finansowanie kosztów budowy 28 farm fotowoltaicznych. Będą one zlokalizowanych w&nbsp;13&nbsp;województwach: dolnośląskim, kujawsko-pomorskim, lubelskim, lubuskim, łódzkim, małopolskim, mazowieckim, opolskim, podkarpackim, podlaskim, warmińsko-mazurskim, wielkopolskim i&nbsp;zachodniopomorskim.</strong></p>
        <p>26 z 28 farm objętych finansowaniem zostało zgłoszonych do systemu aukcyjnego. Łączna moc instalacji PV to ok. 27. MW. Wszystkie projekty realizowane są przez Projekt Solartechnik i powinny zostać zenergetyzowane do końca 2024 r.</p>        
        <Image pic={pic3} />
        <p>– <em>Działamy zgodnie z przyjętą w maju tego roku strategią biznesową i dążymy do coraz większego finansowania zewnętrznego projektów. Kolejna umowa typu project finance przybliża nas do realizacji nakreślonych na lata 2023-2027 celów</em> – ocenił <strong>Łukasz Paśnik, finance manager Projekt Solartechnik</strong>.</p>
        </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>IMPACT: <span className="grText">zyskaliśmy do 300 mln zł na zielony rozwój</span></h1>
        
 <p><strong>Impact Clean Power Technology zawarła w grudniu ub. roku umowy finansowania z Bankiem Pekao SA i mBank SA. Środki, pozyskane dzięki wsparciu Grenevia SA, będą przeznaczone na finansowanie projektów zgodnych z naszą strategią zrównoważonego rozwoju, w tym na inwestycje w obszarze e-mobilności.</strong></p>
          <p>Umowy Impactu z bankami przewidują, że spółka będzie mogła skorzystać z kredytu terminowego na finansowanie lub refinansowanie wydatków inwestycyjnych, kredytu odnawialnego oraz innych produktów finansowych na łączną kwotę ok. 300 mln PLN. Kredyty zostały zabezpieczone gwarancjami KUKE udzielanymi polskim eksporterom.
<p>– <em>Dzięki podpisaniu umów kredytowych z bankami, uzyskaliśmy dostęp do produktów finansowych, które są kluczowe z perspektywy naszych inwestycji w sektorze e-mobilności, w tym przede wszystkim na ukończenie budowy GigafactoryX. To wielkoskalowa fabryka baterii przeznaczonych dla pojazdów elektrycznych i magazynów energii, która zostanie uruchomiona w podwarszawskim Pruszkowie. Nowoczesny zakład będzie jednym z największych tego typu w Europie. Umowy z Bankiem Pekao S.A. oraz mBank S.A., wsparte gwarancjami KUKE, zabezpieczają też naszą bieżącą płynność. To istotne, gdyż w ostatnim czasie podpisaliśmy kilka umów w obszarze e-mobility, między innymi z firmami Solaris czy Alexander Dennis, które swoje pojazdy dostarczają odbiorcom na całym świecie. Każde z tych partnerstw ugruntowuje naszą pozycję europejskiego lidera sektora elektromobilności. To między innymi efekt zainteresowania rynku naszymi nowoczesnymi rozwiązaniami, a my niezmiennie aktywnie poszukujemy możliwości, aby pozyskiwać środki na ich dalszy rozwój</em> – mówi <strong>Katarzyna Pindral, wiceprezes ds. finansowych Impact</strong>.</p>
</p><Image pic={pic4} />        <p>Budowa GigafactoryX jest flagowym projektem inwestycyjnym Impactu. Jej powstanie zwiększy roczne moce produkcyjne spółki do poziomu 1,2GWh w 2024, a docelowo nawet do ponad 4 GWh rocznie. GigafactoryX będzie wyposażona w pełni zautomatyzowaną, nowoczesną linię produkcyjną, która umożliwi wytworzenie co 11 minut nowej baterii. Inwestycja została zaprojektowana w zgodzie z aspektami ESG, które Grupa Grenevia realizuje zgodnie z przyjętą na początku bieżącego roku Strategią Zrównoważonego Rozwoju.
<p>– <em>Aspekty ESG są niezwykle istotne w działalności Impactu, jak i naszych partnerów biznesowych. Nasi odbiorcy coraz częściej stawiają nam wysokie wymogi w tym zakresie. Realizacja założeń Europejskiego Zielonego Ładu jest zatem jednym z fundamentów działalności Impactu oraz coraz powszechniejszym oczekiwaniem rynku. Środki pozyskane dzięki umowom z bankami przeznaczymy na zielone projekty zgodne ze Strategią Zrównoważonego Rozwoju Grupy Grenevia, które pozwolą nam mieć realny wkład w dążeniu do neutralności klimatycznej</em> – dodaje <strong>Bartek Kras, wiceprezes zarządu Impact</strong>.</p>
</p> </TextContainer>

      <TextContainer id="section5" theme="esg">

        <h1>Rok od ogłoszenia <span className="grText">Strategii Zrównoważonego Rozwoju Grupy Grenevia</span></h1>
        <p><strong>W styczniu ub.r. ogłosiliśmy naszą strategię zrównoważonego rozwoju i… zakasaliśmy rękawy, bo zespoły segmentów Grenevii stanęły przed wieloma zadaniami związanymi z wcielaniem w życie założeń tego dokumentu. Ta praca przyniosła efekty. Zapraszamy do obejrzenia krótkiego wideo, podsumowującego nasze działania wokół ESG w 2023 roku.</strong></p>
        <Youtube src='-xtM7_8yYC8' />
      
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

export default function Aside({image, theme, children}) {
  
  const css = `grAside ${image ? 'grAside--withImage' : ''} ${theme==='dark' ? 'grAside--dark' : ''}`;
  
  return (
    <aside className={css}>

      {image ? 
        <div className='grAside__image'>
          <img src={image} alt="" />
        </div>
        : ''}

      <div className='grAside__text'>
        {children}
      </div>

    </aside>
  )
}

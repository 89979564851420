import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';

// assets:
import introPic from '../../assets/issue2/intro.jpg';
import pic1_1 from '../../assets/issue2/pic1-1.jpg';
import pic1_2 from '../../assets/issue2/pic1-2.jpg';
import pic2_1 from '../../assets/issue2/pic2-1.jpg';
import pic2_2 from '../../assets/issue2/pic2-2.jpg';
import pic3 from '../../assets/issue2/pic3.jpg';
import pic4_1 from '../../assets/issue2/pic4-1.jpg';
import pic4_2 from '../../assets/issue2/pic4-2.jpg';
import pic5 from '../../assets/issue2/pic5.jpg';
import pic6 from '../../assets/issue2/pic6.jpg';
import pic7 from '../../assets/issue2/pic7.jpg';

export default function Issue2() {

  const _links = [
    {label: "Zaprezentowaliśmy pierwszy raport finansowy Grenevii", target: "section1"},
    {label: "Prezesi Grenevii i Impact CPT panelistami na EKG", target: "section2"},
    {label: "Zaprezentowaliśmy się na konferencji EuroPOWER", target: "section3"},
    {label: "Grenevia z nagrodą Lidera ESG", target: "section4"},
    {label: "PST: rozpoczynamy współpracę z Eiffel Investment Group", target: "section5"},
    {label: "FAMUR: zakończyliśmy 4. edycję Akademii Lidera Przyszłości", target: "section6"},
    {label: "Elgór+Hansen: instalujemy nową linię lakierniczą", target: "section7"},
    {label: "Impact CPT: przywitaliśmy nowych pracowników na pokładzie", target: "section8"}
  ];
  const _title = "Newsletter #2, kwiecień 2023";
  const _aside1 = <Aside image={pic1_2} theme='light'>
    <p>– Rok 2022 obfitował w wiele znaczących dla kraju i świata wydarzeń. Dla przedsiębiorców to okres wyjątkowej niepewności szczególnie w następstwie wybuchu wojny w Ukrainie. To również ciągłe zakłócenia w logistyce dostaw w związku z nasileniem pandemii Covid-19 w Chinach i wprowadzanych w związku z tym blokad, czemu towarzyszyło widoczne spowalnianie aktywności ekonomicznej i rosnąca inflacja. Niezależnie od dynamicznych zmian gospodarczych i politycznych konsekwentnie zmienialiśmy się, zgodnie z przyjętą w 2021 roku strategią, zakładającą przekształcenie naszej spółki w podmiot aktywnie inwestujący w zieloną transformację. Dla Grupy był to rok szczególnie wytężonej pracy skupionej na wypracowaniu nowej struktury i modelu biznesowego pozwalających elastycznie i szybko reagować na zmiany w otaczającej nas rzeczywistość. Podsumowując miniony rok mogę stwierdzić, że jako zespół – mimo nie zawsze sprzyjających okoliczności – zrealizowaliśmy zakładane na miniony rok cele. Jednak pragnę podkreślić, że mamy znacznie większe ambicje – podsumował wyniki Mirosław Bendzera, prezes zarządu Grenevii.</p>
  </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Dobre wyniki Grenevii</h1>
        <div>
          <h2>Budujemy wartość naszej Grupy</h2>
          <p>Za nami pierwszy miesiąc oficjalnej działalności Grenevia SA. Promowaliśmy naszą grupę na branżowych konferencjach, otrzymaliśmy nagrodę za działalność w zakresie ESG i zaprezentowaliśmy wyniki finansowe. Zapraszamy do zapoznania się z tymi informacjami, a także z wiadomościami ze spółek-segmentów biznesowych Grenevii.</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark" aside={_aside1}>

        <h1>Pierwszy <span className="grText">raport finansowy Grenevii</span></h1>
        <p><strong>Pod koniec miesiąca przedstawiliśmy raport finansowy za rok 2022 Grupy Grenevia. Zrobiliśmy to pierwszy raz pod nową nazwą, a wynik odzwierciedla działalność tak zielonych segmentów Grenevii, jak i naszego tradycyjnego biznesu. Wygenerowany zysk chcemy przeznaczyć na dalszy rozwój.</strong></p>

        <Image pic={pic1_1} />

        <p>
          Przychody sięgnęły niespełna 1,3 mld zł, EBITDA była na poziomie 406 mln zł, oraz osiągnęliśmy zysk netto w wysokości 120 mln zł.
        </p>
 
        <p>Biorąc pod uwagę realizację przyjętych w maju 2021 roku nowych kierunków strategicznych transformacji Grupy Grenevia oraz istotny wzrost niepewności na globalnych rynkach w wyniku wojny na terytorium Ukrainy planowane jest przeznaczenie wypracowanego zysku na kapitał zapasowy, w celu zapewnienia odpowiedniej płynności spółki oraz funduszy na potencjalne przejęcia firm w sektorze OZE.</p>

        <h2>Nasze segmenty</h2>
        
        <p>Działania operacyjne w segmencie fotowoltaiki (PST) obejmowały w 2022 roku dalszą, konsekwentną rozbudowę portfela nowych projektów, przygotowywanie kolejnych projektów do etapu budowy, budowę i uruchamianie farm solarnych, pozyskiwanie pierwszych umów cPPA, jak również rozwój struktur zagranicznych. Na koniec 2022 roku szacunkowa łączna moc projektów PV na różnym etapie rozwoju, po wzroście o 1,5 GW, osiągnęła poziom 3,1 GW. Na koniec 2022 r. łączna moc uruchomionych farm fotowoltaicznych wyniosła 91 MW. W trakcie budowy było 124 MW, a kolejne 65 MW na etapie przygotowania do budowy.</p>
          
        <p>Segment e-mobilności (Impact) w 2022 roku koncentrował się głównie na zwiększaniu portfela zamówień. Zawarte zostały umowy ramowe z klientami na dostawy cykliczne m.in. z Solaris oraz Voith. Pozyskano również pierwsze większe zamówienie z rynku azjatyckiego – wygrany przetarg w Hongkongu z partnerem ADL, w którym Impact jest dostawcą baterii do autobusów. Dzięki współpracy z Grenevią udało się trzykrotnie podnieść wydajności produkcji Impactu i jego efektywność w aktualnej lokalizacji z 0,2 GWh do 0,6 GWh przy jednoczesnym wzroście jakości produktu. W celu zapewnienia stabilności w łańcuchu dostaw intensyfikowano działania związane z dywersyfikacją dostawców głównych komponentów zgodnie z ogłoszoną strategią „European Supply Chain”. Jednocześnie została rozpoczęta budowa GigaFactoryX w Pruszkowie pod Warszawą.</p>

        <p>Na koniec 2022 roku łączny portfel zamówień segmentu FAMUR, obejmujący dostawy maszyn i urządzeń oraz dzierżawy zgodnie z terminami obowiązywania umów, osiągnął około 902 mln zł. Oprócz umów na rynkach zagranicznych wpływ na ten poziom miało pewne ożywienie na rynku krajowym, choć nadal odczuwalna jest presja zmian wynikających z przyjętego programu wygaszania kopalń węgla energetycznego.</p>

        <p>W segmencie elektroenergetyki (Elgór+Hansen) koncentrowaliśmy się na utrzymywaniu pozycji w sektorze górnictwa węglowego oraz zwiększaniu zaangażowania w nowym obszarze energetyki odnawialnej. Na koniec 2022 roku łączny portfel zamówień w tym obszarze wyniósł 55 mln zł, w tym 38 mln zł dla segmentów FAMUR i fotowoltaika.</p>
      
      </TextContainer>

      <TextContainer id="section2" theme="light">

        <h1>Prezesi Grenevii i Impact <span className="grText">panelistami na EKG</span></h1>
        <p><strong>Od 24 do 26 kwietnia Katowice stały się gospodarzem jednego z najważniejszych biznesowych wydarzeń w tej części Europy, czyli Europejskiego Kongresu Gospodarczego. W wydarzeniu tym wzięli udział jako paneliści prezes Grenevii Mirosław Bendzera oraz prezes Impact Clean Power Technology Bartek Kras.</strong></p>

        <Image pic={pic2_1} />
        <Image pic={pic2_2} />

        <p>Prezes Bendzera był uczestnikiem sesji pod hasłem „W kierunku zielonej gospodarki”. W swoim wystąpieniu podkreślał, że globalny trend transformacji energetycznej przyspiesza, stanowiąc także szansę na rozwój dla polskiej gospodarki. Przedstawił on również model biznesowy Grenevii, która wspiera budowę zielonej gospodarki i omówił działalność segmentów naszej Grupy.</p>

        <p>Z kolei Bartek Kras, prezes Impact Clean Power Technology wziął udział w panelu dyskusyjnym „Polski wodór: strategia i praktyka”. W swoim wystąpieniu opowiedział o konieczności przekształcenia prostej transformacji energetycznej w zaawansowaną, której istotną częścią będzie wodór oraz doliny wodorowe.</p>
        
        <p>Europejski Kongres Gospodarczy jest jednym z najważniejszych wydarzeń gospodarczych w Europie, który umożliwia wymianę doświadczeń i prezentację innowacyjnych rozwiązań związanych z rozwojem gospodarczym. EKG to około 150 sesji tematycznych, 1 000 prelegentów oraz 15 000 uczestników.</p>
      
      </TextContainer>

      <TextContainer id="section3" theme="dark">

        <h1>Zaprezentowaliśmy się <span className="grText">na konferencji EuroPOWER</span></h1>
        <p><strong>13-14 kwietnia 2023 r. w Warszawie odbyła się konferencja EuroPOWER &amp; OZE POWER, skupiająca się na tematyce transformacji energetycznej. Podczas tego wydarzenia omówiono nowe technologie i rozwiązania z zakresu energii odnawialnej oraz zastanawiano się nad przyszłością tego sektora w Polsce i regionie CEE. Grupę Grenevia reprezentował prezes Mirosław Bendzera.</strong></p>

        <Image pic={pic3} />
          
        <p>Konferencja EuroPOWER zgromadziła przedstawicieli z sektora energetycznego, w tym ekspertów, inżynierów oraz przedsiębiorców. Podczas wydarzenia poruszono tematy związane z innowacyjnymi technologiami wytwarzania, magazynowania oraz dystrybucji energii, a także zastosowaniem energii odnawialnej w przemyśle. Prezes Bendzera wziął udział w panelu na temat przyszłości sektora energetycznego w Polsce w ujęciu makro, podczas którego poruszano takie tematy elektryfikacja przemysłu i transportu czy wpływ wojny w Ukrainie na transformację energetyczną Polski.</p>

        <p>Misją organizatorów EuroPOWER &amp; OZE POWER jest stworzenie platformy dialogu i konsultacji społecznych przedstawicieli rządu z reprezentantami branży.</p>
      
      </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>Grenevia z nagrodą <span className="grText">Lidera ESG</span></h1>
        <p><strong>Grenevia znalazła się w gronie laureatów konkursu Liderzy Świata Energii za rok 2022. Otrzymaliśmy wyróżnienie w kategorii Lider ESG. Nagrody wręczono 13 kwietnia podczas gali wieńczącej pierwszy dzień 37. Konferencji Energetycznej EuroPOWER &amp; OZE POWER.</strong></p>
          
        <Image pic={pic4_1} />
        <Image pic={pic4_2} />

        <p>Nagrody Liderów Świata Energii przyznano w sześciu kategoriach. Grenevia otrzymała statuetkę za swoją zieloną transformację biznesową i działania w obszarze zrównoważonego rozwoju.</p>

        <p>„Obszar ESG jest kluczowym elementem strategii rozwoju Grupy FAMUR, która obecnie przekształca się w Grenevię, czyli pierwszy w Polsce koncern przemysłowy, który inwestuje w projekty korzystające z trendu zielonej transformacji. Zarówno dotychczasowa działalność Famuru oraz cele biznesowe i rozwojowe Grenevii są ściśle związane z wpływem na klimat i środowisko. To przejście od biznesu emisyjnego w stronę czystej gospodarki. To właśnie na podstawie komponentu ESG spółka rozpoczęła w 2021 roku odpowiedzialną transformację biznesową, której zwieńczeniem jest zmiana w Grenevię” – odczytano w uzasadnieniu przyznania wyróżnienia.</p>
        <p>Nagrodę w imieniu firmy odebrał prezes Grenevia SA Mirosław Bendzera.</p>
        
        <p>Wśród pozostałych laureatów Liderów Świata Energii znaleźli się: Tauron (Innowacja Roku), SAP Polska (Dostawca rozwiązań dla sektora), Polskie Elektrownie Jądrowe Westinghouse Electric Company (Inicjatywa inwestycyjna roku), Alicja Rybczyńska, CIO, Veolia Poland (Manager roku), Rafał Gawin, prezes URE (Człowiek roku).</p>
      
      </TextContainer>

      <TextContainer id="section5" theme="dark">

        <h1>PST: rozpoczynamy współpracę z <span className="grText">Eiffel Investment Group</span></h1>
        <p><strong>Do grona partnerów biznesowych Projekt Solartechnik dołączył znany francuski fundusz inwestycyjny Eiffel Investment Group. Pozyskane z emisji obligacji środki Projekt Solartechnik przeznaczy na realizacje konkretnych projektów fotowoltaicznych, z których energia zakontraktowana jest umowami typu PPA (Power Purchase Agreement).</strong></p>

        <Image pic={pic5} />
          
        <p>– Jesteśmy bardzo zadowoleni, że możemy wspierać Projekt Solartechnik naszym programem finansowania pomostowego. Rynek północno-wschodniej Europy jest strategicznym regionem dla przyspieszenia europejskiej transformacji energetycznej, który Eiffel Investment Group z dumą wspiera – podsumował Pierre-Antoine Machelon, szef zespołu ds. infrastruktury, Eiffel Investment Group.</p>
          
        <p>Transakcja z Eiffel Investment Group zostanie zrealizowana w jednej transzy. Finansowanie to pozwoli na szybsze uruchomienie kolejnych trzech elektrowni słonecznych Projekt Solartechnik w Polsce.</p>

        <p>– Na co dzień z zaangażowaniem pracujemy na rzecz transformacji energetycznej. Płynące ze wspólnych wartości zrozumienie daje nam silne przekonanie, że zawarta umowa jest początkiem długoterminowej współpracy – mówił Roman Pluszczew, wiceprezes zarządu ds. finansów Projekt Solartechnik.</p>

        <p>Projekt Solartechnik po raz pierwszy wdraża narzędzie, jakim jest finansowanie pomostowe od zagranicznego partnera. Wcześniej, w 2022 r. firma zawarła umowę kredytową typu project finance z konsorcjum banków ING Bank Śląski SA, Bank Polska Kasa Opieki SA oraz BNP Paribas Bank Polska SA na finansowanie i refinansowanie kosztów budowy 140 farm fotowoltaicznych o łącznej mocy około 134 MW.</p>
      
      </TextContainer>
  
      <TextContainer id="section6" theme="light">

        <h1>FAMUR: zakończyliśmy 4. edycję <span className="grText">Akademii Lidera Przyszłości</span></h1>
        <p><strong>W kwietniu w Oddziale Machinery oraz Mining segmentu FAMUR zakończył się cykl ostatnich warsztatów w ramach Akademii Lidera Przyszłości. To już czwarta edycja tego programu. Pierwsze tego typu szkolenia rozpoczęły się w maju ubiegłego roku.</strong></p>

        <Image pic={pic6} />

        <p>Akademia to cykl szkoleń z zakresu komunikacji, inteligencji emocjonalnej i zarządzania zmianą. W kolejnych edycjach na warsztaty zapraszani są pracownicy różnych szczebli i oddziałów Famuru. Tym razem w spotkaniach wzięli udział kierownicy, mistrzowie, brygadziści i specjaliści z zakresu produkcji, a także kierownicy centrum serwisowego i magazynów. Prowadziła je Katarzyna Porembska z firmy szkoleniowej Grow.</p>
               
        <p>– Pomysł na tego typ szkolenia zrodził się na bazie badania zaangażowania w oddziałach produkcyjnych oraz obserwacji trendów rynkowych. Zależy nam na tym, by nasi pracownicy poszerzali swoje kompetencje miękkie, które pozwalają na rozwój m.in. w zakresie komunikacji, a także by w codziennej pracy zwracali coraz większą uwagę na człowieka, a nie tylko zadania. Ostatnie lata, wybuch epidemii Covid-19, później wojna w Ukrainie, pokazały, jak bardzo istotna zawodowo i prywatnie jest również inteligencja emocjonalna. To dlatego stawiamy na edukację w tym zakresie – tłumaczy Anna Łomnicka, dyrektor HR Grenevia SA Oddział FAMUR.</p>
        <p>Działalność Famuru w zakresie CSR, w tym szkolenia Akademii Lidera Przyszłości zostały docenione w raporcie „Odpowiedzialny biznes w Polsce. Dobre praktyki” opublikowanym przez Forum Odpowiedzialnego Biznesu.</p>
          
        <p>Gratulujemy!</p>

      </TextContainer>

      <TextContainer id="section7" theme="dark">

        <h1>Elgór+Hansen: instalujemy nową <span className="grText">linię lakierniczą</span></h1>
        <p><strong>Wkrótce w hali produkcji elektroniki Elgór + Hansen SA stanie nowa, w pełni zautomatyzowana linia do lakierowania selektywnego od jednego z największych na świecie producentów tego typu urządzeń - firmy PVA.</strong></p>


        <Image pic={pic7} />

        <p>Część elementów linii, a w szczególności piec UV Spectra dotarły już do E+H. Obecnie spółka czeka na dostawę kluczowej maszyny, jaką jest robot PVA Delta 8. Ma ona nastąpić już w połowie maja.</p>

        <p>W marcu tego roku delegacja Elgór + Hansen SA odwiedziła europejski oddział firmy PVA, który znajduje się w Holandii w mieście Helmond. Wspólnie przeprowadzono testy na partiach pilotażowych.</p>

        <p>Przy instalacji linii E+H współpracuje zarówno z PVA, jak i polskim dystrybutorem – PB Technik, zajmującym się sprzedażą i dostawami linii technologicznych, urządzeń i materiałów eksploatacyjnych oraz chemicznych.</p>
        
      </TextContainer>

      <TextContainer id="section8" theme="light">

        <h1>Impact: przywitaliśmy <span className="grText">nowych pracowników na pokładzie</span></h1>
        <p><strong>Spółka Impact Clean Power Technology, która odpowiada za segment e-mobilności Grenevii, co miesiąc organizuje Impact Day. To spotkania pracowników tej firmy z jej zarządem i kadrą menedżerską. Podczas kwietniowego wydarzenia poinformowano m.in. o zatrudnieniu nowych pracowników i awansach już obecnych członków załogi Impactu.</strong></p>
        
        <p>Impact Day to cykliczne spotkania, na których pracownicy są informowani o najważniejszych wydarzenia z życia firmy bezpośrednio przez jej zarząd. Tym razem prezes Impactu Bartek Kras miał przyjemność pogratulować aż 16 osobom zatrudnienia w podlegającej mu spółce, a także 14 osobom awansu.</p>
        
        <p>Ogłoszono wówczas również program stażowy Młodych Talentów Impactu, którego elementem była późniejsza organizacja Impact Open Day dla studentów.</p>
        
        <p>Podczas Impact Day omawiano także dalsze etapy budowy GigafactoryX, czyli nowej fabryki Impactu, dzięki której w ciągu kilku lat uda się zwiększyć moc aż 7-krotnie.</p>
        
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

export default function SiteFooter() {
  return (
    <footer className='grFooter'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <h1>Grenevia <span className='grText'>w mediach społecznościowych</span></h1>
          </div>
          <div className='col-lg-4'>
            <div className='grFooterSocialMedia'>
              <a href="https://www.facebook.com/profile.php?id=100089436909726" className='grFooterButton'>
                <img alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCA3NCA3NCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iNzQiIGhlaWdodD0iNzQiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PHBhdGggZD0iTTM3LDBjLTIwLjQzNSwwIC0zNywxNi41NjUgLTM3LDM3YzAsMjAuNDM1IDE2LjU2NSwzNyAzNywzN2MyMC40MzUsMCAzNywtMTYuNTY1IDM3LC0zN2MtMCwtMjAuNDM1IC0xNi41NjUsLTM3IC0zNywtMzdabTkuNzY5LDIyLjE3NWMtMCwwIC0zLjc4NiwtMC4yOTggLTUuMjc3LDAuMjUyYy0yLjQ2NiwwLjkxMSAtMi41MjQsMy4wNDEgLTIuNTI0LDUuMDcxbC0wLDQuMTQ3bDcuNTM3LDBsLTEuMTAxLDcuOTMzbC02LjQzNiwwbC0wLDE5LjU4M2wtOC41MTMsMGwtMCwtMTkuNTgzbC03LjA3OCwwbDAsLTcuOTMzbDcuMDc4LDBsMCwtNi43MjhjMCwtNy4xMjQgNS40ODQsLTkuNzA1IDguNzU0LC0xMC4wMTVjMy4yNjksLTAuMzEgNy41NiwwLjYyIDcuNTYsMC42MmwtMCw2LjY1M1oiIHN0eWxlPSJmaWxsOiNmZmY7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+" />
              </a>
              <a href="https://twitter.com/grenevia" className='grFooterButton'>
                <img alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxNTAgMTUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPjxyZWN0IGlkPSJBcnRib2FyZDEiIHg9IjAiIHk9IjAiIHdpZHRoPSIxNTAiIGhlaWdodD0iMTUwIiBzdHlsZT0iZmlsbDpub25lOyIvPjxwYXRoIGQ9Ik03NSwwYzQxLjM5NCwwIDc1LDMzLjYwNiA3NSw3NWMtMCw0MS4zOTQgLTMzLjYwNiw3NSAtNzUsNzVjLTQxLjM5NCwtMCAtNzUsLTMzLjYwNiAtNzUsLTc1YzAsLTQxLjM5NCAzMy42MDYsLTc1IDc1LC03NVptOC40OTIsNjguMTcxbDMzLjIwNCwtMzcuNzk2bC03Ljg2NiwtMGwtMjguODQyLDMyLjgxMWwtMjMuMDE5LC0zMi44MTFsLTI2LjU1NywtMGwzNC44MTcsNDkuNjJsLTM0LjgxNywzOS42M2w3Ljg2NSwtMGwzMC40MzksLTM0LjY1N2wyNC4zMTUsMzQuNjU3bDI2LjU1NywtMGwtMzYuMDk2LC01MS40NTRabS00Mi4zNzYsLTMxLjk4OGwxMi4wODQsMGw1NS42MjQsNzcuOTE5bC0xMi4wODYsMGwtNTUuNjIyLC03Ny45MTlaIiBzdHlsZT0iZmlsbDojZmZmOyIvPjwvc3ZnPg==" />
              </a>
              <a href="https://www.linkedin.com/company/grenevia" className='grFooterButton'>
                <img alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCA3NCA3NCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxuczpzZXJpZj0iaHR0cDovL3d3dy5zZXJpZi5jb20vIiBzdHlsZT0iZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO3N0cm9rZS1taXRlcmxpbWl0OjI7Ij48cmVjdCBpZD0iQXJ0Ym9hcmQxIiB4PSIwIiB5PSIwIiB3aWR0aD0iNzQiIGhlaWdodD0iNzQiIHN0eWxlPSJmaWxsOm5vbmU7Ii8+PHBhdGggZD0iTTM3LDBjLTIwLjQzNSwwIC0zNywxNi41NjUgLTM3LDM3YzAsMjAuNDM1IDE2LjU2NSwzNyAzNywzN2MyMC40MzUsLTAgMzcsLTE2LjU2NSAzNywtMzdjLTAsLTIwLjQzNSAtMTYuNTY1LC0zNyAtMzcsLTM3Wm0tMTAuNDg4LDU1LjMwN2wtOC4yNDYsMGwwLC0yNS44NzNsOC4yNDYsMGwwLDI1Ljg3M1ptLTQuMTIzLC0yOS4yNjFjLTIuNTQ1LC0wIC00LjYzOSwtMi4wOTUgLTQuNjM5LC00LjY0Yy0wLC0yLjU0NSAyLjA5NCwtNC42MzkgNC42MzksLTQuNjM5YzIuNTQ1LC0wIDQuNjM5LDIuMDk0IDQuNjM5LDQuNjM5YzAsMi41NDUgLTIuMDk0LDQuNjQgLTQuNjM5LDQuNjRabTMzLjg2MywyOS4yNjFsLTguMDk0LDBsLTAsLTE0LjE0N2MtMCwtMC43MTggMC4xMTMsLTUuNDg0IC0zLjkzNCwtNS40ODRjLTEuNDU5LC0wLjAzNyAtMi44NDYsMC42NDMgLTMuNzEyLDEuODE3Yy0wLjc0MywwLjk5MiAtMS4xNCwyLjIwMSAtMS4xMywzLjQ0bDAsMTQuMzc0bC04LjA4NCwtMGwwLC0yNS44NzNsNy42OTIsMGwtMCwzLjYzMWMxLjg0MSwtMi44MjQgNC4xNjEsLTMuODU4IDYuNDQzLC00LjI3NGM0LjA1LC0wLjczOCAxMC44MTgsMS43MjggMTAuODE4LDkuMDkxbDAuMDAxLDE3LjQyNVoiIHN0eWxlPSJmaWxsOiNmZmY7ZmlsbC1ydWxlOm5vbnplcm87Ii8+PC9zdmc+" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue4/grenevia_4_intro.jpg';
import pic1_1 from '../../assets/issue4/grenevia_4_intro3.jpg';
import pic2_1 from '../../assets/issue4/famur-serock.jpg';
import pic2_2 from '../../assets/issue4/famur-kazachstan.jpg';
import pic3_1 from '../../assets/issue4/elgor.jpg';
import pic4_1 from '../../assets/issue4/marcinmarcjanik.jpg';
import pic5_1 from '../../assets/issue4/agatamaj.jpg'

import impact1 from '../../assets/issue4/impact1.jpg';
import impact2 from '../../assets/issue4/impact2.jpg';
import impact3 from '../../assets/issue4/impact3.jpg';
import impact4 from '../../assets/issue4/impact4.jpg';


export default function Issue2() {

  const _links = [
    {label: "Grenevia: Beata Zawiszowska staje na czele Grenevii", target: "section1"},
    {label: "FAMUR na konferencji PSEW i targach MiningWeek Kazachstan", target: "section2"},
    {label: "Elgór + Hansen: to był sportowy miesiąc", target: "section3"},
    {label: "Projekt Solartechnik: mamy nową strategię na lata 2023-2027", target: "section4"},
    {label: "IMPACT: tak będzie wyglądać nasza GigafactoryX", target: "section5"},
    {label: "Wkrótce ruszy Letnia Szkoła ESG", target: "section6"},
  ];
  const _title = "Newsletter #4, czerwiec 2023";
  const _aside1 = <>
    <Aside image={pic4_1} theme='light'>
      <p>– Prowadzenie biznesu, który zmienia oblicze europejskiej energetyki oraz piłka nożna to moje dwie wielkie pasje. W obu można odnosić sukcesy, posiadając skuteczną taktykę uwzględniającą dynamicznie zmieniającą się rzeczywistość. Przez ostatnie miesiące pracowaliśmy nad strategią rozwoju na najbliższe pięć lat. Naszym celem jest zdobycie mistrzostwa, które rozumiemy jako doprowadzenie do maksymalizacji wartości grupy i wejście na podium czołowych europejskich spółek specjalizujących się w realizacji i inwestowaniu w odnawialne źródła energii – skomentował nową strategię <strong>Maciej Marcjanik, prezes Projekt Solartechnik</strong>.</p>
    </Aside>
</>
    const _aside2 = <>
    <Aside image={pic5_1} theme='light'>
      <p>– Coraz więcej firm zdaje sobie sprawę, że współczesny biznes musi być oparty o ESG. Nie jest to jednak łatwe zadanie, ponieważ związane ze zrównoważonym rozwojem regulacje mają wiele niuansów. To właśnie powód, dla którego zdecydowaliśmy się na zorganizowanie cyklu trzech webinarów prowadzonych przez doradców EY. Zapraszamy do udziału w nich każdego chętnego pracownika – wyjaśnia <strong>Agata Maj, piastująca stanowisko ESG Value Stream Manager w TDJ, a od czerwca również menedżerki ds. zrównoważonego rozwoju ESG Grenevii</strong>.</p>
    </Aside>
  </>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Zmiany w zarządzie Grenevia SA</h1>
        <div>
          <p>Beata Zawiszowska na czele Grenevia SA. Zmiany w zarządzie są wynikiem zmian w strukturze, które opisujemy w pierwszej wiadomości. Kolejne treści są poświęcone wydarzeniom w naszych segmentach – a&nbsp;działo się sporo.</p>
          <p>Zachęcamy do przeczytania newslettera!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Grenevia: <span className="grText">Beata Zawiszowska staje na czele Grenevii</span></h1>
        <p><strong>Od 1 lipca zarząd Grenevii SA stał się jednoosobowy. Funkcję prezes pełni Beata Zawiszowska, która do tej pory piastowała stanowisko wiceprezes zarządu i odpowiadała za obszary finansowe naszej spółki.</strong></p>

        <p>Z funkcji zarządczych w Grenevia SA z dniem 30 czerwca zrezygnowali: prezes zarządu Mirosław Bendzera, wiceprezes zarządu ds. sprzedaży Dawid Gruszczyk oraz wiceprezes zarządu ds. operacyjnych, Underground Tomasz Jakubowski. Wprowadzane obecnie zmiany mają charakter porządkujący strukturę organizacyjną Grupy, a członkowie zarządu, którzy zrezygnowali ze swoich stanowisk, będą teraz koncentrować się na skalowaniu i dywersyfikacji jednego ze strategicznych dla rozwoju Grenevii obszarów biznesowych, jakim jest segment FAMUR.</p>

        <Image pic={pic1_1} />

       <p><strong>Beata Zawiszowska</strong> ma wieloletnie doświadczenie w zarządzaniu i nadzorowaniu spółek z sektora przemysłowego. Jest związana z Grenevia SA (dawniej FAMUR SA) od 2002 roku, a od 2005 roku nieprzerwanie pełniła funkcję członka zarządu odpowiedzialnego za koordynowanie obszaru finansowego całej Grupy. W tym czasie uczestniczyła również w organach spółek portfelowych TDJ.</p>
      </TextContainer>

      <TextContainer id="section2" theme="light">

        <h1>FAMUR<span className="grText"> na konferencji PSEW i&nbsp;targach MiningWeek Kazachstan</span></h1>
        <p><strong>Między 20 a 22 czerwca miały miejsce aż dwa istotne dla segmentu FAMUR wydarzenia – konferencja Polskiego Stowarzyszenia Energetyki Wiatrowej (PSEW) w Serocku oraz MiningWeek Kazachstan 2023 w Karagandzie. Nie mogło na nich zabraknąć naszych przedstawicieli.</strong></p>

        <Image pic={pic2_1} />

        <p>Konferencja PSEW to jedno z największych w Europie Środkowo-Wschodniej wydarzeń branżowych, poświęconych energetyce wiatrowej. Podczas konferencji poruszono m.in. temat transformacji energetycznej, nowych technologii na rzecz ograniczania śladu środowiskowego, wykorzystania energetyki wiatrowej do produkcji zielonego wodoru czy przyszłości europejskiego łańcucha dostaw dla morskiej energetyki wiatrowej.</p>
        <p>W wydarzeniu reprezentowali FAMUR: <strong>Dominika Działach</strong> – starsza specjalistka wsparcia sprzedaży krajowej i eksportowej, <strong>Wojciech Frysz</strong> – dyrektor ds. technicznych, <strong>Łukasz Mech</strong> – doradca zarządu ds. energetyki wiatrowej oraz <strong>Piotr Monsiorski</strong> – dyrektor handlowy odpowiedzialny za rozwój tej branży.</p>
        
        <Image pic={pic2_2} />
        <p>W tym samym czasie w Kazachstanie odbywała się 18. edycja międzynarodowej wystawy MiningWeek 2023. Jest to wydarzenie o ogromnym prestiżu, które przyciąga uwagę branży z całego świata. Skupia się głównie na metalurgii żelaza, metali nieżelaznych, górnictwie oraz inżynierii i stanowi forum dla wymiany doświadczeń i prezentacji najnowszych osiągnięć. Markę FAMUR promowali tam: <strong>Gabriela Chruściel</strong> – dyrektor rynku Kazachstan, <strong>Anna Jakubczyk</strong> – wicedyrektor sprzedaży eksportowej, <strong>Agnieszka Mięczakowska</strong> – koordynator zespołu wsparcia sprzedaży eksportowej, <strong>Ireneusz Komoszyński</strong> – dyrektor działu sprzedaży eksportowej oraz przedstawiciele lokalnej spółki, TOO FAMUR Kazachstan.</p>       
      <p>Na obu opisanych konferencjach obecni byli także przedstawiciele innej spółki portfelowej Grenevii – Elgór + Hansen. W Mining Week Kazachstan 2023 wzięli udział <strong>Rafał Szołtysik</strong>, dyrektor Działu Rynku Elektroenergetyki i Przemysłu, oraz <strong>Alexander Kolesnikov</strong>, zastępca dyrektora Działu Rynku Elektroenergetyki i Przemysłu. Z kolei do Serocka z ramienia E+H wybrał się <strong>Adam Jonczyk</strong>, główny specjalista ds. handlowych.</p>
      </TextContainer>

      <TextContainer id="section3" theme="dark">

        <h1>Elgór + Hansen: <span className="grText">to był sportowy miesiąc</span></h1>
        <p><strong>Czerwiec w Elgór + Hansen minął pod znakiem sportowych wyzwań i inicjatyw CSR. Na początku miesiąca 16-osobowa drużyna tej spółki stanęła na starcie 4. charytatywnego biegu Tauron Biegnij dla Aniołów w katowickim Parku Kościuszki. Z kolei 24 czerwca reprezentanci E+H wystartowali w biegu Hałda Run Extreme w Suszcu.</strong></p>
        <Image pic={pic3_1} />
       <p>Tauron Biegnij dla Aniołów to wydarzenie zorganizowane przez Stowarzyszenie Pomocy Dzieciom i Młodzieży DOM ANIOŁÓW STRÓŻÓW. Sportowa rywalizacja była połączona z piknikiem rodzinnym z okazji dnia dziecka. Całkowity dochód z tego wydarzenia zostanie przekazany na „Anielskie Wakacje” dla podopiecznych organizacji.</p>
       <p>Natomiast w drugiej połowie czerwca sportowcy z E+H wystartowali w biegu Hałda Run Extreme – słynnych zawodach rozgrywanych na terenie suszeckich hałd i lasów, gdzie mieli okazję sprawdzić swoje możliwości na dwóch dystansach: 7,5 oraz 15 km. </p>
       <p>Zdecydowanie pracownicy E+H są zwolennikami inicjatyw łączących sportową rywalizację z firmową integracją.</p>
      </TextContainer>

      <TextContainer id="section4" theme="light" aside={_aside1}>

        <h1>Projekt Solartechnik: <span className="grText">mamy nową strategię na lata 2023-2027</span></h1>
        <p><strong>Dywersyfikacja produktowa i geograficzna, szeroki pipeline projektów, powołanie własnej spółki obrotu energią, sprzedaż projektów i jednocześnie działalność w formule samodzielnego wytwórcy energii elektrycznej z własnym portfelem elektrowni (IPP) – to główne założenia strategii biznesowej przyjętej przez Projekt Solartechnik na najbliższe lata.</strong></p>
        <p>Spółka postawiła sobie ambitne cele. To intensywniejsza zagraniczna ekspansja, zaczynając od rynków niemieckiego, francuskiego, rumuńskiego i hiszpańskiego; 1 500 MW projektów PV gotowych do budowy (RtB) w kraju i 1 500 MW RtB projektów PV na rynkach zagranicznych; rozwój projektów magazynów energii o mocy 400 MW RtB; rozwój projektów wiatrowych o mocy 170 MW RtB; budowa własnego portfela farm PV (IPP) o łącznej mocy 844 MW; powołanie spółki obrotu energią; sprzedaż 844 MW COD oraz zakontraktowanie PPA/cPPA z własnych farm o mocy 844 MW (IPP).</p>
        <p><strong>Zapraszamy do obejrzenia filmu o nowej strategii Projekt Solartechnik. Jako ciekawostkę zdradzimy, że został on stworzony z wykorzystaniem technologii AI.</strong></p>
<iframe width="560" height="315" src="https://www.youtube.com/embed/yWEMJaJxrIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>      
       


      </TextContainer>

      <TextContainer id="section5" theme="dark">

<h1>IMPACT: <span className="grText">tak będzie wyglądać nasza GigafactoryX</span></h1>
<p><strong>Impact Clean Power Technology, segment biznesowy Grenevii odpowiedzialny za elektromobilność, zaprezentował w czerwcu wizualizacje powstającej GigafactoryX. To wielkoskalowa fabryka baterii przeznaczonych dla pojazdów elektrycznych i magazynów energii. Ta nowoczesna inwestycja, zlokalizowana w podwarszawskim Pruszkowie, będzie jednym z największych tego typu zakładów w Europie.</strong></p>
<p>Dzięki GigafactoryX moce produkcyjne Impactu znacząco wzrosną, osiągając 1,2 GWh w 2024 roku i ponad 4 GWh rocznie w 2027 roku, a zaawansowana i w pełni zautomatyzowana linia produkcyjna zaprojektowana przez firmę teamtechnik Production Technology Poland pozwoli na zwiększenie produkcji do 6 baterii na godzinę.</p>
<p>Fabryka będzie zasilana częściowo energią pochodzącą z paneli fotowoltaicznych, co stanowi istotny krok w kierunku zrównoważonej i przyjaznej dla środowiska produkcji. Dodatkowo będzie wyposażona we własny magazyn energii, który pozwoli gromadzić nadwyżki energii  i wykorzystywać je w okresach zwiększonego zapotrzebowania.</p>
<p>Fasada GigafactoryX została zaprojektowana przez Medusa Group, a przestrzeń biurową zaaranżowała firma AAG we współpracy z Cobu Design.</p>
<Gallery>
          <Image pic={impact1} /> 
          <Image pic={impact2} /> 
          <Image pic={impact3} /> 
          <Image pic={impact4} /> 
        </Gallery>

</TextContainer>

      <TextContainer id="section6" theme="esg" aside={_aside2}>

        <h1>Wkrótce ruszy Letnia Szkoła ESG</h1> 
        <p><strong>Zasady ESG i zrównoważonego rozwoju są kompasem na biznesowej drodze Grupy Grenevia. By przybliżyć związane z tą tematyką zagadnienia i rozwiać ewentualne wątpliwości, Grupa Grenevia organizuje cykl webinarów na temat środowiska (E), społeczeństwa (S) i ładu korporacyjnego (G).</strong></p>
        <p>Cykl ruszy latem tego roku, a o dokładnych datach i możliwościach udziału będziemy informować w kolejnych newsletterach, a także wiadomościach do liderów ESG w segmentach Grenevii.</p>  
        <p>W razie pytań związanych ze zrównoważonym rozwojem, zachęcamy również do kontaktu z:</p>
        <ul><li><strong>Tomaszem Jankowskim</strong>, dyrektorem relacji inwestorskich: <a href="mailto:tjankowski@grenevia.com">tjankowski@grenevia.com</a></li>
        <li><strong>Agatą Maj</strong>, menedżerką ds. zrównoważonego rozwoju ESG: <a href="amaj@grenevia.com">amaj@grenevia.com</a></li></ul>
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue13/0-main.jpg';
import pic1 from '../../assets/issue13/1-grenevia.jpg';
import pic2 from '../../assets/issue13/2-famur.jpg';
import pic3 from '../../assets/issue13/eh.png';
import pic4 from '../../assets/issue13/4-pst.jpg';
import pic5 from '../../assets/issue13/5-impact.jpg'
import pic6 from '../../assets/issue13/szkolenie1.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'

export default function Issue2() {

  const _links = [
    {label: "Grenevia: tak skalowaliśmy działalność naszych segmentów", target: "section1"},
    {label: "FAMUR: nasza drużyn wsparła Zosię", target: "section2"},
    {label: "FAMUR: tak wygląda wymiana przekładni… 90 metrów nad ziemią!", target: "section3"},
    {label: "Projekt Solartechnik: wzięliśmy udział w Kongresu PV", target: "section4"},
    {label: "IMPACT: byliśmy na trzech konferencjach i targach w Las Vegas", target: "section5"},
    {label: "Dyrektywa CSRD – szkolimy się z unijnych przepisów", target: "section6"},
  ];

  const _title = "Newsletter #5(13), czerwiec 2024";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– W 2023 roku realizowaliśmy strategiczny proces przekształcenia Grenevii w holding inwestujący w zieloną transformację. Kontynuowaliśmy drogę wyznaczoną przez kierunki strategiczne przyjęte w 2021 roku. Skupialiśmy się na budowie wartości segmentów wielkoskalowej fotowoltaiki oraz e-mobilności. Jednocześnie wdrażaliśmy nowe inicjatywy w tradycyjnych obszarach działalności w celu zwiększania ich zaangażowania w sektory związane z energetyką odnawialną, w tym wiatrową. Myślę, że zbudowaliśmy już nową strukturę i model biznesowy, który pozwala nam elastycznie i szybko reagować na zmiany w otaczającej nas rzeczywistości – mówi <strong>Beata Zawiszowska, prezes zarządu Grenevia SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Opublikowaliśmy Raport zintegrowany Grupy Grenevia</h1>
        <div>
          <p>Wyniki finansowe, działania niefinansowe i kluczowe operacje – wszystko, co warto wiedzieć o naszej Grupie, znajdziecie w tym jednym dokumencie – Raporcie zintegrowanym Grupy Grenevia za rok 2023. Intensywnie pracowaliśmy nad jego przygotowaniem przez ostatnie tygodnie i teraz oddajemy go również w Wasze ręce.</p><p>Ponadto informacje o najważniejszych wydarzeniach w segmentach biznesowych i sekcja ESG. Działo się sporo, więc zapraszamy do lektury!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Grenevia: <span className="grText">tak skalowaliśmy działalność naszych segmentów</span></h1>
       
       
        <p><strong>Na początku czerwca Grupa Grenevia opublikowała raport zintegrowany za rok 2023. W&nbsp;przystępny sposób podsumowujemy w nim kluczowe dane i informacje o działaniach w&nbsp;holdingu i segmentach biznesowych. Za nasz ubiegłoroczny raport zintegrowany zdobyliśmy nagrodę w kategorii debiut konkursu Forum Odpowiedzialnego Biznesu i Deloitte na najlepsze Raporty Zrównoważonego Rozwoju.</strong></p>
        <Image pic={pic1} />
        
        <p>W minionym roku skupiliśmy się m.in. na budowie wartości zielonych segmentów – e-mobilności oraz fotowoltaiki, która rozszerzyła działalność o energetykę wiatrową oraz magazyny energii i zmieniła niedawno nazwę na segment OZE. Jednocześnie Grenevia wdrożyła nowe inicjatywy w tradycyjnych obszarach biznesowych, zwiększając ich zaangażowanie w sektorach energetyki odnawialnej, w tym wiatrowej. Warto dodać, że w 2023 roku 48% przychodów Grupy Grenevia pochodziło ze źródeł innych niż sektor węgla energetycznego.</p>
        <p>Poza skalowaniem działalności segmentów biznesowych, zaczęliśmy wdrażać naszą strategię zrównoważonego rozwoju. Pod ten cel dostosowaliśmy strukturę organizacyjną związaną z ESG, ale również wprowadziliśmy lub ujednoliciliśmy szereg polityk i procedur w tym obszarze.</p>
        <h3>O wszystkim możecie szczegółowo przeczytać w Raporcie zintegrowanym Grupy Grenevia za rok 2023: <a href="https://grenevia.com/report/raport-zintegrowany-grupy-grenevia-za-2023/" target='_blank'>KLIKNIJ</a>.</h3>
        
        </TextContainer>

        <TextContainer id="section2" theme="light">

<h1>FAMUR: <span className="grText">nasza drużyna wsparła Zosię</span></h1>
<p><strong>Mimo że ostatnie tygodnie w Famurze były pełne wydarzeń biznesowych i organizacyjnych, tym razem chcemy opowiedzieć Wam inną historię związaną z tym segmentem. Sam FAMUR podkreśla swoją dumę z postawy swojej drużyny piłkarskiej nie tylko na boisku, ale i poza nim.</strong></p>
<Image pic={pic2} />
<p>Niedawno FAMUR wywalczył drugie miejsce w konkursie na najlepszą koszulkę w Sportowej Lidze Firm „Silesia”, co zostało wyróżnione nagrodą pieniężną. Zawodnicy solidarnie postanowili przekazać zdobytą kwotę na rzecz chorej Zosi, którą wychowuje jeden z ich kolegów, pracownik Famuru.</p>
<p>Jeśli chcecie poznać historię Zosi i dołożyć swoją cegiełkę do zbiórki na jej kosztowną rehabilitację, wejdźcie tutaj: <a href="https://zrzutka.pl/kosztowna-rehablitiacja-zosi" target='_blank>'>KLIKNIJ</a>.</p>
 </TextContainer>

 <TextContainer id="section3" theme="dark">

<h1>FAMUR: <span className="grText">tak wygląda wymiana przekładni… 90 metrów nad ziemią!</span></h1>
<p><strong>Jeśli nie mieliście okazji zobaczyć tego nagrania w mediach społecznościowych Famuru, to zachęcamy Was do obejrzenia teraz. Od hali FAMUR Gearo w Katowicach po znajdującą się ponad 90 metrów na ziemią gondolę turbiny wiatrowej w Lipnikach – tak wyglądała wymiana przekładni dla Tauron Ekoenergia Sp. z o.o. na należącej do tego operatora farmie wiatrowej.</strong></p>
<p>To wideo naprawę robi wrażenie, a przede wszystkim pokazuje współpracę między FAMUR Gearo a Total Wind PL.</p>
<p>FAMUR Gearo to utworzony w lutym tego roku obszar segment biznesowego FAMUR, skupiający całą aktywność związaną z rozwojem i oferowaniem kompleksowych rozwiązań dla energetyki wiatrowej oraz produkcji przekładni przemysłowych.</p>
<p>W lipcu ubiegłego roku nabyliśmy pakietu ok. 75% akcji Total Wind PL, co pozwoliło na uzupełnienie kompetencji FAMUR w obszarze energetyki wiatrowej.</p>
<Youtube src='75s3LFV5Cy4' />
 </TextContainer>


      <TextContainer id="section4" theme="light">

        <h1>Projekt Solartechnik:<span className="grText"> wzięliśmy udział w Kongresie PV</span></h1>
              <p><strong>Wyzwania branży fotowoltaicznej, polityka energetyczna Polski, rola PV i magazynów energii w transformacji energetycznej przemysłu, łańcuch dostaw PV w Polsce i zrównoważony rozwój to tematy, o których przedstawiciele PST dyskutowali podczas tegorocznej edycji Kongresu PV. Na stoisku spółki zaprezentowano także ofertę PST Trade skoncentrowaną wokół sprzedaży wielkoskalowych farm fotowoltaicznych, obrotu energią oraz sprzedaży energii w formule cPPA/PPA.</strong></p>
              <Image pic={pic4} />
               <p>Z wielu prezentacji i paneli dyskusyjnych kongresu wynika, że integracja wielkoskalowej fotowoltaiki z magazynami energii, która pozwala na lepsze zarządzanie popytem i podażą energii, jest kluczowa dla stabilności sieci. Z kolei społeczna akceptacja projektów OZE, zdaniem panelistów, jest złożonym procesem wymagającym zintegrowanego podejścia, obejmującego edukację, zaangażowanie interesariuszy, przejrzystą komunikację, nakreślanie korzyści ekonomicznych oraz wsparcie polityczne i regulacyjne.</p>
               <p>Kongres PV integruje przedstawicieli branży fotowoltaicznej w Polsce i stanowi okazję do spotkań z liderami i ekspertami, którzy kształtują oblicze polskiego rynku PV, nawiązania nowych kontaktów biznesowych i udziału w inspirujących dyskusjach.</p>
        </TextContainer>

      <TextContainer id="section5" theme="dark">

        <h1>IMPACT: <span className="grText">byliśmy na trzech konferencjach i targach w Las Vegas</span></h1>
        
 <p><strong>Maj był dla IMPACT bardzo intensywnym miesiącem – przedstawiciele spółki wzięli udział w najważniejszych konferencjach naukowych i targach branżowych poświęconych elektromobilności. Wiceprezes spółki Bartek Kras gościł z wykładami na EVS37 w Seulu, AABC Europe w Strasburgu, a także podczas pierwszej edycji PESA Battery Conference. Zespół IMPACT odwiedził również największe targi technologii zaawansowanych pojazdów komercyjnych, ACTExpo w Las Vegas.</strong></p>
 <Image pic={pic5} />
         <p>Podczas konferencji w Seulu i Strasburgu Bartek Kras zaprezentował wyniki badań prowadzonych w IMPACT nad wykorzystaniem algorytmów AI w celu wprowadzania dynamicznych zmian parametrów eksploatacji baterii w taki sposób, aby wydłużyć ich żywotność, co mogłoby znacząco poprawić koszt użytkowania pojazdów elektrycznych. </p>
         <p>Z kolei na konferencji PESA Battery Conference wiceprezes Impactu uczestniczył w debacie „Przyszłość przemysłu bateryjnego w Polsce”, zrealizowanej w formie okrągłego stołu. Przedstawiciele branży bateryjnej i magazynowania energii, sektora bankowego oraz operatora systemu przesyłowego przedstawili diagnozę branży i nakreślili najpoważniejsze stojące przed nią wyzwania.</p>
         <p>Przedstawiciele Impactu byli też obecni na największych targach technologii zaawansowanych pojazdów komercyjnych ACTExpo w Las Vegas. Poznawali specyfikę amerykańskiego rynku e-mobility i baterii, jego potrzeby oraz dominujące na nim trendy technologiczne. Rozmawiali także z klientami o ich wyzwaniach oraz prezentowali rozwiązania spółki z zakresu systemów bateryjnych i magazynowania energii.</p></TextContainer>

      <TextContainer id="section6" theme="esg">

        <h1>Dyrektywa CSRD<span className="grText"> – szkolimy się z&nbsp;unijnych przepisów</span></h1>
        <p><strong>W maju odbyło się kilkudniowe warsztaty dla koordynatorów ESG z naszych segmentów biznesowych, a także specjalistów Grenevia SA, którzy są odpowiedzialni za raportowanie niefinansowe. Temat: nowe obowiązki dla przedsiębiorców związane z wprowadzeniem dyrektywy CSRD.</strong></p>
        <Image pic={pic6} />
        <p>Dyrektywa CSRD ma - dzięki ujednoliceniu standardów - zwiększyć transparentność raportowania w zakresie zrównoważonego rozwoju, w tym standardów ESG (Environmental, Social, Governance). Ma to pomóc inwestorom i konsumentom w rzetelnej ocenę działań przedsiębiorstw.</p>
        <p>Od 2024 roku największe firmy będą musiały spełniać nowe obowiązki sprawozdawcze, zgodne z unijnym dokumentem. A do 2029 roku dyrektywa obejmie wszystkie spółki regulowane w UE.</p>
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

export default function Intro({backgroundImg, children}) {
  return (
    <div className='grIntro' style={{backgroundImage: `url(${backgroundImg})`}}>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='grIntroText'>
              {children[0]}

              <div className='grIntroBox'>
                {children[1]}
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

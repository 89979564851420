import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue10/0impact.jpg';
import pic1 from '../../assets/issue10/1famur.jpg';
import pic2 from '../../assets/issue10/2eh.jpg';
import pic3 from '../../assets/issue10/3pst.jpg'
import pic4 from '../../assets/issue10/4impact.jpg'
import pic5 from '../../assets/issue10/5esg.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'

export default function Issue2() {

  const _links = [
    {label: "FAMUR: nagrodziliśmy pomysły zwiększające bezpieczeństwo w FAMUR Gearo", target: "section1"},
    {label: "Elgór + Hansen: zwiększamy moce naszych stacji transformatorowych", target: "section2"},
    {label: "Projekt Solartechnik: PST Trade na targach ENEX", target: "section3"},
    {label: "IMPACT: autobus ADL z naszymi bateriami pomyślnie przeszedł testy", target: "section4"},
    {label: "Zmiany wśród koordynatorów ESG", target: "section5"},
  ];

  const _title = "Newsletter #2(10), luty 2024";
  const _aside1 =
    <Aside image={mb} theme='light'>
      <p>– FAMUR jest od lat związany z energetyką, co w naturalny sposób sprawia, że aspirujemy do mocnej pozycji w dynamicznie kształtującym się sektorze energetyki odnawialnej. Już dziś możemy się pochwalić wieloletnim doświadczeniem w projektowaniu i budowie urządzeń dla przemysłu, w tym zaawansowanych przekładni przemysłowych. Dlatego jestem przekonany, że FAMUR Gearo pomoże nam wykorzystać ten potencjał jeszcze lepiej i wesprze rozwój oraz budowę naszej rozpoznawalności w nowych obszarach pozagórniczych – mówi <strong>Mirosław Bendzera, prezes zarządu FAMUR SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Baterie IMPACT pomogą zwiększyć wydajność nowego autobusu ADL</h1>
        <div>
          <p>Autobus ADL Enviro400, do którego Impact Clean Power Technology dostarczył innowacyjne baterie, przeszedł program testów i certyfikacji do ruchu. Dzięki polskim systemom bateryjnym będzie on najbardziej wydajnym piętrowym pojazdem elektrycznym w Wielkiej Brytanii w tej kategorii wielkości akumulatorów! Więcej informacji na ten temat, a także aktualności z pozostałych segmentów Grupy Grenevia w newsletterze.</p>
          <p>Zapraszamy do lektury!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>FAMUR: <span className="grText">nagrodziliśmy pomysły zwiększające bezpieczeństwo w&nbsp;FAMUR Gearo</span></h1>
        <p><strong>FAMUR ogłosił laureatów inicjatywy zgłaszania zagrożeń i zdarzeń, które mogą prowadzić do wypadków. Akcja była zorganizowana w ramach programu „Pracujemy bezpiecznie”.</strong></p>
        <Image pic={pic1} />

        <p>Podnoszenie świadomości bezpiecznej pracy wśród pracowników, a co za tym idzie szybka reakcja i eliminacja ryzyka, nim dojdzie do wypadku, to cel przyświecający programowi „Pracujemy bezpiecznie”. W tym roku w oddziale FAMUR Gearo zostało nagrodzonych 21 osób, które zgłosiły swoje pomysły, mające wpływ na zmniejszenie liczby wypadków w miejscu pracy.</p>
        <p>Inicjatywa ta jest częścią szeroko zakrojonych działań FAMUR mających na celu nie tylko zapewnienie bezpieczeństwa pracowników, ale również zaangażowanie ich w procesy decyzyjne dotyczące tego aspektu, co przekłada się na budowanie pozytywnej i bezpiecznej kultury pracy.</p>
        <p>Gratulujemy!</p></TextContainer>

        <TextContainer id="section2" theme="light">

<h1>Elgór + Hansen: <span className="grText">zwiększamy moce naszych stacji transformatorowych</span></h1>
<p><strong>Nasz segment elektroenergetyki wdrożył kolejne projekty stacji transformatorowych o coraz większych mocach. Do tej pory firma dostarczała głównie kontenerowe stacje transformatorowe dla farm fotowoltaicznych o mocy do 1 MVA. Ostatnio jednak zrealizowała projekty stacji o większych mocach: 3,15 MVA w Drzeńsku i 1,60 MVA w Popowie-Borowym.</strong></p>
<Image pic={pic2} />
<p>Zwiększenie mocy pociągnęło za sobą konieczność zmiany konstrukcji samego kontenera – wprowadzono zdejmowane dachy. To z kolei sprawia, że montaż musi odbywać się w dobrych warunkach pogodowych, bez opadów. W przypadku stacji do 1 MVA, gdzie używane są zamknięte, jednoelementowe kontenery, deszcz czy śnieg nie były problemem, a montaż odbywał się pod odpowiednim okapturzeniem transformatora podczas wprowadzania go do wnętrza stacji.</p>
<p>Kolejne wyzwanie dla E+H odhaczone.</p>
 </TextContainer>


      <TextContainer id="section3" theme="dark">

        <h1>Projekt Solartechnik:<span className="grText"> PST Trade na targach ENEX</span></h1>
              <p><strong>Na tegorocznych Międzynarodowych Targach Energetyki i Elektrotechniki oraz Odnawialnych Źródeł Energii ENEX prezentowaliśmy ofertę spółki obrotu PST Trade. </strong></p>
              <Image pic={pic3} />
               <p>Na stoisku PST Trade rozmawialiśmy o możliwości zakupu gotowych farm fotowoltaicznych na terenie całej Polski, obniżeniu kosztów energii dzięki kontraktom cPPA/PPA, sprzedaży energii elektrycznej, gwarancjach pochodzenia i raportowaniu ESG. Z kolei przedstawiciele działu zakupów Projekt Solartechnik sprawdzali najnowsze trendy w branży OZE, innowacyjne rozwiązania i modele produktów. Wydarzenie było również świetną okazją do wymiany doświadczeń i efektywnego networkingu.</p>        
        <p>Targi ENEX w Kielcach to jedno z największych wydarzeń branży energetycznej. Obecność przedstawicieli firm z całej Europy umożliwia porównanie oferty produktowej i usługowej. To także okazja do zadawania pytań oraz konfrontacji pomysłów z oczekiwaniami rynku OZE.</p>
        </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>IMPACT: <span className="grText">autobus ADL z naszymi bateriami pomyślnie przeszedł testy</span></h1>
        
 <p><strong>Piętrowy autobus Enviro400, najnowszy pojazd brytyjskiej marki Alexander Dennis Ltd., zbudowany w oparciu o innowacyjne baterie IMPACT, zakończył pomyślnie program testów i certyfikacji do ruchu.</strong></p>
         <Image pic={pic4} />    
         <p>Brytyjski „piętrus” miał swoją premier w listopadzie ubiegłego roku. Jednak dopiero przejście wymagających testów pozwoliło na wprowadzenie go na rynek. </p>
         <p>Prace przeprowadzone w ośrodku testowym UTAC w Millbrook Proving Ground potwierdzają średnie zużycie energii na poziomie zaledwie 0,67 kWh/km w cyklu autobusowym w Wielkiej Brytanii. To sprawia, że Enviro400EV nowej generacji jest najbardziej wydajnym piętrowym pojazdem elektrycznym, zapewniającym 10-procentową przewagę wydajności w porównaniu z pojazdami konkurencji o porównywalnej wielkości akumulatora.</p> 
         <p>System bateryjny IMPACT z ogniwami litowo-jonowymi NMC umożliwia wykorzystanie 88% jego pojemności, która wynosi 472 kWh, oraz zużycie energii na poziomie 0,67 kWh/km. W brytyjskim cyklu autobusowym pozwala to modelowi Enviro400V na teoretyczny zasięg działania do 620 km (385 mil) na jednym ładowaniu.</p></TextContainer>

      <TextContainer id="section5" theme="esg">

        <h1>Zmiany <span className="grText">wśród koordynatorów ESG</span></h1>
        <p><strong>Od lutego dwie nasze spółki mają nowych koordynatorów ESG. W Elgór + Hansen funkcję tę objął Janusz Woźniok (z prawej), a w Projekt Solartechnik - Jakub Dzierzęga (z lewej). Witamy w nowych rolach!</strong></p>
        <Image pic={pic5} />
        <p>Jednocześnie z roli koordynatorów ESG zrezygnowali Agnieszka Sadowska (Projekt Solartechnik) oraz Maciej Frycz (Elgór + Hansen), którzy nadal pozostają na swoich podstawowych stanowiskach w segmentach Grenevii. Dziękujemy im serdecznie za ich dotychczasową pracę na rzecz wdrożenia strategii zrównoważonego rozwoju i wprowadzania procedur ESG w naszej grupie.</p>    
        <p><strong>Kim są nowi koordynatorzy?</strong></p>
        <p><strong>Janusz Woźniok</strong> to ekspert w dziedzinie zintegrowanych systemów zarządzania oraz zrównoważonego rozwoju, który od ponad 12 lat jest związany ze spółką Elgór + Hansen. W tym czasie awansował od stanowiska technologa, przez m.in. koordynatora ds. Lean Management, po obecnie pełnioną funkcję pełnomocnika zarządu ds. zintegrowanych systemów zarządzania. Od lutego objął również stanowisko koordynatora ds. ESG.</p>
        <p>Janusz jest absolwentem Wyższej Szkoły Zarządzania Ochroną Pracy na kierunku Zarządzanie i Inżynieria Produkcji. Ukończył również kilka studiów podyplomowych m.in. w zakresie Lean Management oraz ESG. Posiada również szereg certyfikatów, które potwierdzają jego umiejętności, w tym zarządzania ryzykiem czy ESG w strategii przedsiębiorstwa przyznawane przez EY Academy of Business Polska, zarządzania efektywnością energetyczną TUV NORD Polska oraz Lean Six Sigma Green Belt.
          <p><strong>Jakub Dzierzęga</strong> to doświadczony specjalista w zakresie finansów i inwestycji, obecnie pełniący funkcję dyrektora finansowego ds. rynków zagranicznych w Projekt Solartechnik oraz członka zarządu w Primetech (dawniej Kopex). Jakub posiada bogate doświadczenie zdobyte w Grupie FAMUR, gdzie przez niemal dekadę pełnił różnorodne role związane z finansami i relacjami inwestorskimi, zajmując się m.in. restrukturyzacją finansową i operacyjną, zarządzaniem płynnością finansową, a także nadzorem nad nowymi obszarami inwestycji i procesami M&A.</p>
          <p>Ukończył studia magisterskie na kierunku Finanse i Rachunkowość - Inżynieria Finansowa na Uniwersytecie Ekonomicznym w Katowicach. Obecnie finalizuje proces uzyskania certyfikacji ACCA.</p>
        </p>
      </TextContainer>

    <SiteFooter />

    </>
  )
}

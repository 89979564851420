import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue12/hero.jpg';
import pic1 from '../../assets/issue12/zarzad.jpg';
import pic2 from '../../assets/issue12/europower1.jpg';
import pic3 from '../../assets/issue12/europower2.jpg';
import pic4 from '../../assets/issue12/europower3.jpg';
import pic5 from '../../assets/issue12/eh.jpg'
import pic6 from '../../assets/issue12/pst.jpg'
import pic7 from '../../assets/issue12/impact.jpg'
import pic8 from '../../assets/issue12/raport.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'
import bz from '../../assets/issue12/bzawiszowska.jpg'

export default function Issue2() {

  const _links = [
    {label: "Wzrost przychodów i dalszy rozwój segmentów", target: "section1"},
    {label: "FAMUR: uczestniczyliśmy w Konferencja Energetyczna EuroPOWER & OZE POWER", target: "section2"},
    {label: "Elgór + Hansen: rozpoczęliśmy współprace z nowym klientem", target: "section3"},
    {label: "Projekt Solartechnik: wzięliśmy udział w Kongresie Energetyki Przyszłości", target: "section4"},
    {label: "IMPACT: otrzymaliśmy prestiżowy certyfikat IATF 16949:2016", target: "section5"},
    {label: "Cztery inicjatywy FAMURU wyróżnione w raporcie FOB", target: "section6"},
  ];

  const _title = "Newsletter #4(12), kwiecień 2024";
  const _aside1 =
    <Aside image={bz} theme='light'>
      <p>– W 2023 roku realizowaliśmy strategiczny proces przekształcenia Grenevii w holding inwestujący w zieloną transformację. Kontynuowaliśmy drogę wyznaczoną przez kierunki strategiczne przyjęte w 2021 roku. Skupialiśmy się na budowie wartości segmentów wielkoskalowej fotowoltaiki oraz e-mobilności. Jednocześnie wdrażaliśmy nowe inicjatywy w tradycyjnych obszarach działalności w celu zwiększania ich zaangażowania w sektory związane z energetyką odnawialną, w tym wiatrową. Myślę, że zbudowaliśmy już nową strukturę i model biznesowy, który pozwala nam elastycznie i szybko reagować na zmiany w otaczającej nas rzeczywistości – mówi <strong>Beata Zawiszowska, prezes zarządu Grenevia SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Podsumowaliśmy 2023 rok. Wyniki Grupy Grenevia</h1>
        <div>
          <p>W najnowszym newsletterze publikujemy nasze wyniki finansowe i&nbsp;informacje o działaniach niefinansowych za ubiegły rok, ale również pokazujemy najważniejsze wydarzenia z segmentów. IMPACT może pochwalić się ważnym certyfikatem, FAMUR i Projekt Solartechnik udziałem w&nbsp;branżowych konferencjach, a Elgór+ Hansen nowym klientem.
</p><p>Zapraszamy do lektury!
</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark" aside={_aside1}>

        <h1>Wzrost przychodów <span className="grText">i&nbsp;dalszy rozwój segmentów</span></h1>
       
       
        <p><strong>W 2023 roku przychody Grupy Grenevia wyniosły 1 644 mln zł. To wzrost o 27 proc. w stosunku do 2022 roku. Taki wynik osiągnęliśmy dzięki zwiększeniu przychodów w segmentach elektroenergetyka i PV, stabilnym przychodom segmentu FAMUR oraz objęciu konsolidacją za pełen okres segmentu e-mobilności. Z kolei EBITDA wyniosła 414 mln zł, a zysk netto 144 mln zł, co stanowi wzrost o 20 proc. rok do roku.</strong></p>
        
        <p>Miniony rok upłynął pod znakiem intensywnego rozwoju naszych segmentów biznesowych. W segmencie e-mobilności wzrosła skala działalności Impact Clean Power Technology. Spółka kontynuuje współpracę i pozyskuje nowe kontrakty od światowych liderów produkcji elektrycznych autobusów. Finalizuje także inwestycję w GigafactoryX - wielkoskalową fabrykę baterii przeznaczoną dla pojazdów elektrycznych.</p>
        <p>W segmencie fotowoltaiki w 2023 roku Projekt Solartechnik zawarł przedwstępną umowę sprzedaży firmie KGHM portfela projektów PV o łącznej mocy ok. 50 MW. Ostateczne zamknięcie i rozliczenie transakcji nastąpiło w lutym 2024 roku. </p>
        <p>W segmencie elektroenergetyki Elgór + Hansen zanotował znaczący wzrost przychodów. Spółka ta rozwija ofertę związaną z rozdziałem i dystrybucją energii, zwłaszcza w obszarze OZE, obejmującą m.in. kontenerowe stacje transformatorowe dla farm PV.</p>
        <p>Z kolei w obszarze rozwiązań i usług dla sektora wiatrowego swoją ofertę rozszerzył segment FAMUR, między innymi za sprawą nabycia pakietu 75,24 proc. akcji spółki Total Wind PL. Konsekwencją rozwoju działalności w tym obszarze było utworzenie w lutym 2024 roku obszaru FAMUR Gearo, który w ramach segmentu koncentruje całą aktywność związaną z rozwiązaniami dla energetyki wiatrowej.</p>
        <h2>Zrównoważony rozwój</h2><Image pic={pic1} />
        <p>W ramach realizacji Strategii Zrównoważonego Rozwoju powołaliśmy Komitet Sterujący z prezes Grenevii SA na czele oraz rozpoczęliśmy wdrażanie wewnętrznych procedur i polityk ESG oraz ujednolicanie ich w ramach segmentów. Usprawniono również proces agregacji danych niefinansowych w ramach wszystkich spółek portfelowych Grupy. </p>
        <p>Rozbudowywano także instalacje dachowych paneli słonecznych w zakładach FAMUR oraz Elgór + Hansen. A rozwój i wzrost skali działania segmentów fotowoltaiki i e-mobilności Grenevii pozwolił na uniknięcie w gospodarce łącznie 254 tys. ton emisji CO<sub>2</sub> za lata 2022-2023. </p>
        <p>Udział przychodów Grupy z innych źródeł niż sektor węgla energetycznego wyniósł w ubiegłym roku 48 proc.</p>
        
        </TextContainer>

        <TextContainer id="section2" theme="light">

<h1>FAMUR: <span className="grText">uczestniczyliśmy w&nbsp;Konferencji Energetyczna EuroPOWER & OZE POWER</span></h1>
<p><strong>Za naszym segmentem FAMUR 39. Konferencja Energetyczna EuroPOWER & 9. OZE POWER. W ciągu dwóch dni zaproszeni goście prowadzili dyskusje na temat priorytetów i wyzwań w sektorze energetycznym.</strong></p>

<p>Obywające się od 18 do 19 kwietnia 2024 r. w Warszawie wydarzenie jest kluczowym miejscem rozmów dla polskiego sektora energetycznego. To właśnie tu przedstawiciele zarządów, politycy, eksperci i naukowcy spotykają się, aby omówić kluczowe sprawy sektorowe.</p>
<p>W pierwszym dniu wydarzenia w panelu "Zrównoważony rozwój i odnawialne źródła energii w Polsce: strategie i innowacje" wystąpił prezes zarządu FAMUR SA Mirosław Bendzera, który podzielił się swoimi opiniami i doświadczeniem z uczestnikami konferencji.</p>
<Gallery>
          <Image pic={pic2} /> 
          <Image pic={pic3} />
          <Image pic={pic4} /> 
        </Gallery>
 </TextContainer>

 <TextContainer id="section3" theme="dark">

<h1>Elgór + Hansen:<span className="grText"> rozpoczęliśmy współpracę z nowym klientem</span></h1>
      <p><strong>Nasz segment elektroenergetyki poszerza swoją obecność na rynku OZE. Niedawno Elgór + Hansen pozyskał nowego klienta – firmę Solfinity, której dostarczy trzy stacje transformatorowe.</strong></p>
      <Image pic={pic5} />
       <p>Jedna z zamówionych stacji będzie w obudowie betonowej, która jest nowością w ofercie E+H. Dodatkowo zostanie wyposażona w wydzielone, odrębne pomieszczenie obsługi, obok typowych pomieszczeń dla transformatora oraz urządzeń rozdziału energii.</p>
       <p>Stacje transformatorowe zostaną dostarczone do klienta latem tego roku i wszystkie będą zainstalowane na terenach farm PV w regionie Mazur.</p>
       <p>W związku z rozwojem oferty stacji transformatorowych Elgór + Hansen usprawnia również procesy produkcyjne w swoich zakładach w Zabrzu, m.in. dzięki współpracy z zespołem Lean Management w ramach Business Excellence TDJ.</p>
</TextContainer>


      <TextContainer id="section4" theme="light">

        <h1>Projekt Solartechnik:<span className="grText"> wzięliśmy udział w Kongresie Energetyki Przyszłości</span></h1>
              <p><strong>Kongres Energetyki Przyszłości w Toruniu to jedno z ważniejszych wydarzeń branży energetycznej i jednocześnie świetna przestrzeń do inicjowania modelowych rozwiązań kształtujących przyszłość polskiej energetyki. Nie mogło tam więc zabraknąć naszych przedstawicieli z Projekt Solartechnik.</strong></p>
              <Image pic={pic6} />
               <p>- <em>Jeśli chcemy przejść z energetyki scentralizowanej na zdecentralizowaną potrzebna jest szeroka modernizacja sieci energetycznych i spore środki na ten cel</em> – stwierdziła <strong>Marta Rzepecka, M&A director PST Trade</strong>, podczas panelu „Zielona transformacja przemysłu” w trakcie Kongresu. Nasza reprezentantka zwróciła także uwagę na potrzebę zachęt finansowych dla inwestycji w magazyny energii, które pozwolą przedsiębiorcom perspektywicznie spojrzeć na OZE i zwiększyć konkurencyjność na rynku.</p>
               <p>Dyskusja ekspercka dotyczyła wyzwań i możliwości zielonej transformacji dla przedsiębiorstw energochłonnych. Wspieramy ją dzięki ofercie PST Trade dla biznesu – sprzedaży gotowych farm fotowoltaicznych na terenie całej Polski oraz sprzedaży zielonej energii w formule PPA/cPAA i gwarancji pochodzenia. Ofertę prezentowaliśmy na naszym stoisku.</p>
        </TextContainer>

      <TextContainer id="section5" theme="dark">

        <h1>IMPACT: <span className="grText">otrzymaliśmy prestiżowy certyfikat IATF 16949:2016</span></h1>
        
 <p><strong>Z początkiem kwietnia do Impact Clean Power Technology wpłynęła fizyczna wersja certyfikatu IATF 16 949:2016 w zakresie projektowania i wytwarzania magazynów energii elektrycznej do zasilania pojazdów z napędem elektrycznym. Od tej pory spółka może się nim oficjalnie posługiwać.</strong></p>
 <Image pic={pic7} />
         <p>Certyfikat nadany przez jednostkę certyfikującą TÜV Saarland stanowi potwierdzenie zgodności wyrobów i procesów spółki ze specyfikacją norm technicznych oraz systemu zarządzania obowiązujących w obrębie globalnego przemysłu motoryzacyjnego.</p>
         <p>Posiadanie certyfikatu otwiera przed Impactem nowe możliwości biznesowe, ponieważ wielu czołowych, globalnych producentów z branży motoryzacyjnej wskazuje go jako jeden z wymogów podjęcia współpracy. Należą do nich m.in. Daimler, ZF, Scania, Volvo czy Iveco.</p></TextContainer>

      <TextContainer id="section6" theme="esg">

        <h1>Cztery inicjatywy <span className="grText">FAMURU wyróżnione w raporcie FOB</span></h1>
        <p><strong>„Róże Wiatru”, aplikacja ServiceInGrid, Program Go!World! i Zielone Kilometry FAMUR to inicjatywy jednego z naszych segmentów biznesowych, które zostały wyróżnione w raporcie „Odpowiedzialny biznes w Polsce 2023. Dobre praktyki” tworzonym przez Forum Odpowiedzialnego Biznesu.</strong></p>
        <Image pic={pic8} />
        <p>Raport FOB miał swoją premierę 16 kwietnia, a tegoroczne wydanie jest już jego 22. edycją. W publikacji zaprezentowano 1046 praktyk realizowanych przez 266 firm - jej celem jest dzielenie się inspirującymi działaniami i projektami.</p>
        <p>Również projekty segmentu FAMUR znalazł się na tej liście. W sekcji „Prawa człowieka” wyszczególniono „Róże Wiatru”, czyli program skierowany do damskiej części załogi Famuru, dzięki któremu stworzono przestrzeń do doskonalenia i dzielenia się doświadczeniami kobiet z branży przemysłowej. Warsztaty są dostępne dla wszystkich pań, niezależnie od stanowiska. Szkolenia obejmują takie tematy jak radzenie sobie ze stresem czy budowanie poczucia własnej wartości.</p>
  
        <p>Z kolei w rozdziale raportu FOB dot. „Praktyk z zakresu prawa praca” opisano dwie inicjatywy Famuru. Pierwsza to aplikacja ServiceInGrid, dzięki której udało się ograniczyć korzystanie z papierowej dokumentacji i poprawić komunikację w zespole serwisowym. Druga to FAMUR GO! World! - program rozwojowo-językowy przygotowany we współpracy z TDJ Foundation dla dzieci i wnuków pracowników segmentu FAMUR.</p>
        <p>Wyróżniono także akcję Zielone Kilometry FAMUR, której celem było zachęcenie do aktywnego spędzania wolnego czasu oraz propagowanie zdrowego trybu życia. Dodatkowo integrowano pracowników działów poprzez wdrożenie elementu grupowej rywalizacji.</p>
        <p>Wszystkie te działania mają swoja kontynuację w 2024 roku.</p>
      </TextContainer>

    <SiteFooter />

    </>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue6/1top.jpg';
import pic1 from '../../assets/issue6/1.jpg';
import pic2 from '../../assets/issue6/2gielda.jpg';
import pic3 from '../../assets/issue6/3minex.jpg';
import pic4 from '../../assets/issue6/4elgor.jpg';
import pic5 from '../../assets/issue6/5pst.jpg';
import pic7 from '../../assets/issue6/7raport.jpg'
import mm from '../../assets/issue6/marcinmarcjanik.jpg'
import bz from '../../assets/issue6/beatazawiszowska.jpg'

import impact1 from '../../assets/issue6/impact1.jpg'
import impact2 from '../../assets/issue6/impact2.jpg'
import impact3 from '../../assets/issue6/impact3.jpg'
import impact4 from '../../assets/issue6/impact4.jpg'
import impact5 from '../../assets/issue6/impact5.jpg'
import impact6 from '../../assets/issue6/impact6.jpg'




export default function Issue2() {

  const _links = [
    {label: "Pomóż się nam doskonalić. Oceń swoje miejsce pracy!", target: "section1"},
    {label: "Grenevia: wzrost przychodów we wszystkich segmentach", target: "section2"},
    {label: "FAMUR: pokazaliśmy się na targach MINEX i HUSUM WIND", target: "section3"},
    {label: "Elgór + Hansen: wzięliśmy udział w targach Energetab 2023", target: "section4"},
    {label: "Projekt Solartechnik: KGHM zamierza kupić osiem farm słonecznych", target: "section5"},
    {label: "IMPACT: przeprowadziliśmy się do biur GigafactoryX", target: "section6"},
    {label: "Bierzemy udział w konkursie na najlepszy raport zrównoważonego rozwoju", target: "section7"},
  ];
  const _title = "Newsletter #6, wrzesień 2023";
  const _aside1 = <>
    <Aside image={bz} theme='light'>
      <p>– Istotny jest fakt, że w I półroczu br. wzrost przychodów dotyczył wszystkich segmentów biznesowych Grupy Grenevia. Intensywnie rozwijamy nasze spółki portfelowe, utrzymując wysoką rentowność na poziomie EBITDA wynoszącą 29%. Z kolei wysokość długu netto względem EBITDA utrzymuje się na bezpiecznym poziomie i pozwala nam wspierać rozwój naszych „zielonych inwestycji” – <strong>Beata Zawiszowska, prezes zarządu Grenevia SA</strong>.</p>
    </Aside>
</>
    const _aside2 = <>
    <Aside image={mm} theme='light'>
      <p>– Cieszy nas bardzo, gdy duże firmy, które dążą do osiągnięcia neutralności klimatycznej, decydują się na współpracę z nami. Osiągamy efekt synergii w aktywnej działalności na rzecz naszej przyszłości – <strong>Maciej Marcjanik, CEO Projekt Solartechnik</strong></p>
    </Aside>
  </>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Trwa badanie zaangażowania pracowników!</h1>
        <div>
          <p>Ostatnie tygodnie upłynęły pod znakiem targów. Możemy się też pochwalić obiecującą umową oraz otwarciem nowego biura. A w zakresie ESG – udziałem w prestiżowym konkursie. Przede wszystkim jednak w tym miesiącu zachęcamy do udziału w badaniach zaangażowania pracowników!</p>
          <p>Zapraszamy do lektury newslettera!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Pomóż się nam doskonalić. <span className="grText">Oceń swoje miejsce pracy!</span></h1>
        <p><strong>27 września w Grenevia SA, FAMUR, Elgór + Hansen oraz Projekt Solartechnik rozpoczęło się badanie zaangażowania pracowników. Chcemy poznać Twoją opinię, aby tworzyć jeszcze lepsze miejsca pracy i warunki rozwoju dla naszych zespołów i organizacji jako całości. Czas na wypełnianie ankiet upływa 10 października.</strong></p>
        <Image pic={pic1} />

        <p>Badanie jest prowadzone przy wsparciu firmy Kincentric, która od ponad 25 lat pomaga największym organizacjom w Polsce i na świecie w tego typu działaniach. Udział w badaniu jest dobrowolny, a odpowiedzi pracowników są poufne i trafiają bezpośrednio do specjalistów z firmy Kincentric.</p>
        <p>Dzięki analizie wyników, którą otrzymamy po zakończeniu badania, możemy bardziej efektywnie kształtować środowiska pracy w Grupie Grenevia. Zachęcamy więc do udziału i wypełniania ankiet. Badania są koordynowane przez działy HR w poszczególnych spółkach.</p>
        </TextContainer>

      <TextContainer id="section2" theme="light" aside={_aside1}>

        <h1>Grenevia:<span className="grText"> wzrost przychodów we wszystkich segmentach</span></h1>
        <Image pic={pic2} />
              <p><strong>Grupa Grenevia ogłosiła 29 sierpnia wyniki finansowe za I półroczu 2023 roku. Osiągnęliśmy przychody w wysokości 781 mln zł, co stanowiło wzrost o 42% w porównaniu do analogicznego okresu 2022 roku. Zysk Grupy wyniósł 92 mln zł, natomiast EBITDA na poziomie 229 mln jest o 20% wyższa niż w pierwszych sześciu miesiącach ubiegłego roku. Warto podkreślić, że 40% przychodów Grenevii pochodziło z „zielonego biznesu”.</strong></p>

  

        <p>W segmencie FAMUR przychody zewnętrzne (tj. bez sprzedaży między segmentami) były wyższe o 74 mln zł, w obszarze elektroenergetyki (Elgór+Hansen) o 18 mln zł, a w fotowoltaice (PST) o 13 mln zł. Z kolei objęcie konsolidacją przychodów e-mobilności (IMPACT) pozwoliło dodać do wyniku Grupy 125 mln zł.</p>
        
        </TextContainer>

      <TextContainer id="section3" theme="dark">

        <h1>FAMUR: <span className="grText">pokazaliśmy się na targach MINEX i HUSUM WIND</span></h1>
        <Image pic={pic3} />
 <p><strong>W mijającym miesiącu przedstawiciele Famuru wzięli udział w dwóch różnych imprezach targowych, podkreślając swoje zaangażowanie w sektorze wydobywczym oraz energetyki wiatrowej – MINEX w Turcji i HUSUM WIND w Niemczech. To był dla nas czas nie tylko prezentacji, ale również okazja do nawiązania inspirujących kontaktów.</strong></p>
        <p>Między 13 a 16 września uczestniczyliśmy w Targach Górnictwa, Zasobów Naturalnych i Technologii MINEX, podczas których wystawialiśmy się razem z naszym lokalnym partnerem – firmą Albacore.</p>
        <p>Z kolei od 12 do 15 września wspólnie z Total Wind wzięliśmy udział w targach HUSUM WIND w Niemczech. FAMUR prezentował swoją rozwijającą się ofertę z zakresu remontów przekładni wiatrowych, a także montażu i serwisu turbin wiatrowych.</p>
      </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>Elgór + Hansen: <span className="grText">wzięliśmy udział w targach Energetab 2023</span></h1>
        <p><strong>Wrzesień minął w Elgór+ Hansen pod znakiem Międzynarodowych Energetycznych Targów Bielskich Energetab 2023, które odbyły się od 12 do 14 września. W wydarzeniu uczestniczyli również przedstawiciele innej spółki-segmentu biznesowego Grenevii – Impact Clean Power Technology. To trzy intensywne dni, które dały nam szerokie spojrzenie na przyszłość energetyki.</strong></p>
        <Image pic={pic4  } />
<p>Energetab jest najważniejszym w Polsce spotkaniem przedstawicieli branży energetycznej. W tym roku wzięło w nim udział 433 wystawców, którzy zaprezentowali swoje najnowsze i innowacyjne produkty.</p>
<p>Dla przedstawicieli Elgór + Hansen były to dni pełne rozmów, nowo nawiązanych kontaktów biznesowych oraz wymiany doświadczeń, które, mamy nadzieję, zaowocują wspólnymi projektami.</p>  </TextContainer>

      <TextContainer id="section5" theme="dark" aside={_aside2}>

      <h1>Projekt Solartechnik: <span className="grText">KGHM zamierza kupić osiem farm słonecznych</span></h1>
        <p><strong>Projekt Solartechnik podpisał przedwstępną umowę sprzedaży portfela instalacji fotowoltaicznych o łącznej mocy blisko 50 MW grupie KGHM Polska Miedź. Będzie również świadczyć posprzedażową usługę utrzymania farm PV będących przedmiotem transakcji.</strong></p>
        <Image pic={pic5  } />
<p>Zakupione przez KGHM elektrownie słoneczne zlokalizowane są w województwach: dolnośląskim, łódzkim, pomorskim i wielkopolskim. Instalacje zapewnią około 2 proc. pokrycia zapotrzebowania na energię elektryczną w KGHM.</p>
<p>– Współpracę ze spółką KGHM rozpoczęliśmy od pionierskiego kontraktu typu fizycznego PPA w strukturze Pay-As-Produced na dostarczenie zielonej energii z jednej należących do Projekt Solartechnik farm fotowoltaicznych. Teraz nasz partner zostanie właścicielem tej oraz siedmiu innych elektrowni słonecznych – mówi <strong>Krzysztof Czajka, wiceprezes ds. sprzedaży Projekt Solartechnik.</strong></p>  </TextContainer>

<TextContainer id="section6" theme="light">

<h1>IMPACT: <span className="grText">przeprowadziliśmy się do biur GigafactoryX</span></h1>
<p><strong>29 września oficjalnie otwarto część biurową GigafactoryX, czyli nowej inwestycji spółki Impact Clean Power Technology, dzięki której zostaną znacznie zwiększone moce produkcyjne, ale też stworzono bardziej przyjazną przestrzeń do pracy dla inżynierów, specjalistów i pracowników administracyjnych.</strong></p>

<p>W nowym biurze Impactu zadbano o komfort pracy i chwile wytchnienia. Dla pracowników dostępne są ergonomiczne miejsca pracy, budki telefoniczne, które umożliwiają ciche rozmowy, sale konferencyjne wyposażone w sprzęt AV najwyższej jakości, jak i pokój do chilloutu z wygodnymi sofami i fotelami oraz punkty kawowe. Stworzono również dogodne warunki akustyczne w biurach, które dodatkowo ozdobiono roślinami, co nie tylko nadaje uroku, ale także poprawia jakość powietrza. Utworzono również wypożyczalnię rowerów dla pracowników Impactu.</p>
<Gallery>
          <Image pic={impact1} /> 
          <Image pic={impact2} /> 
          <Image pic={impact3} /> 
          <Image pic={impact4} /> 
          <Image pic={impact5} /> 
          <Image pic={impact6} /> 
        </Gallery>

<p>Oficjalne otwarcie całej GigafactoryX z nową linią produkcyjną jest planowane na 2024 r.</p>  </TextContainer>

<TextContainer id="section7" theme="dark">

<h1>Bierzemy udział w konkursie <span className="grText">na najlepszy raport zrównoważonego rozwoju</span></h1>
<p><strong>Grenevia walczy w 17. edycji konkursu „Raporty Zrównoważonego Rozwoju”, którego organizatorami są Forum Odpowiedzialnego Biznesu oraz Deloitte. To nasz pierwszy udział w tym wydarzeniu.
</strong></p>
<Image pic={pic7  } />
<p>Jak podkreślają organizatorzy konkursu, „to inicjatywa mająca na celu upowszechnianie idei odpowiedzialnego biznesu (CSR), zrównoważonego rozwoju, ochrony środowiska i zaangażowania społecznego, skierowana do firm i organizacji publikujących raporty ze swojej aktywności w tych obszarach.”.</p>
<p>Zgłoszony przez nas raport jest dokumentacją transformacji całej grupy i deklaracją kolejnych kroków. Przede wszystkim jednak to efekt pracy wielu osób we wszystkich naszych segmentach, które gromadziły, weryfikowały i analizowały przedstawione dane i informacje.</p>
<p><strong>Nagrody są przyznawane w kategoriach:</strong></p>
<ul><li>Nagroda za najlepszy raport zrównoważonego rozwoju – przyznaje jury</li>
<li>Nagroda za najlepszy raport zintegrowany – przyznaje jury</li>
<li>Nagroda za najlepszy debiut – przyznaje jury</li>
<li>Nagroda internautów – zostaje przyznana w wyniku głosowania on-line, które trwało do 30 września. Dziękujemy wszystkim, którzy oddali na nas głos!</li></ul>

<p>🟢 Więcej o konkursie: <a href="https://raportyzr.pl/" target="_blank">https://raportyzr.pl/</a></p>
<p>🟢 Zapoznaj się z raportem Grupy Grenevia: <a href="https://grenevia.com/report/raport-zintegrowany-grupy-grenevia-za-2022/" target="_blank">https://grenevia.com/report/raport-zintegrowany-grupy-grenevia-za-2022/</a> </p> </TextContainer>

     

    <SiteFooter />

    </>
  )
}

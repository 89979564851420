import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';
import Youtube from '../../elements/Youtube';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue11/easter5.jpg';
import pic1 from '../../assets/issue11/famur.jpg';
import pic2 from '../../assets/issue11/eh.jpg';
import pic3 from '../../assets/issue11/pst.jpg'
import pic4 from '../../assets/issue11/impact1.jpg'
import pic5 from '../../assets/issue11/impact2.jpg'
import pic6 from '../../assets/issue11/policy.jpg'


import mb from '../../assets/issue9/mbendzera.jpg'
import pm from '../../assets/issue9/pmajcherkiewicz.jpg'

export default function Issue2() {

  const _links = [
    {label: "FAMUR: byliśmy partnerem Forum Operatorów Farm Wiatrowych 2024", target: "section1"},
    {label: "Elgór + Hansen: dostarczyliśmy stacje transformatorowe dla nowych klientów", target: "section2"},
    {label: "Projekt Solartechnik: sfinalizowaliśmy umowę z KGHM", target: "section3"},
    {label: "IMPACT: trwa montaż naszej nowej linii produkcyjnej", target: "section4"},
    {label: "Polityki społeczne w Grupie Grenevia", target: "section5"},
  ];

  const _title = "Newsletter #3(11), marzec 2024";
  const _aside1 =
    <Aside image={mb} theme='light'>
      <p>– FAMUR jest od lat związany z energetyką, co w naturalny sposób sprawia, że aspirujemy do mocnej pozycji w dynamicznie kształtującym się sektorze energetyki odnawialnej. Już dziś możemy się pochwalić wieloletnim doświadczeniem w projektowaniu i budowie urządzeń dla przemysłu, w tym zaawansowanych przekładni przemysłowych. Dlatego jestem przekonany, że FAMUR Gearo pomoże nam wykorzystać ten potencjał jeszcze lepiej i wesprze rozwój oraz budowę naszej rozpoznawalności w nowych obszarach pozagórniczych – mówi <strong>Mirosław Bendzera, prezes zarządu FAMUR SA</strong>.</p>
    </Aside>;

    const _aside2 =
    <Aside image={pm} theme='light'>
      <p>– Dzięki akwizycji spółki Total Wind PL stworzyliśmy kompleksową i unikatową na rynku ofertę. W naszym zakładzie produkcyjnym w Katowicach-Piotrowicach prowadzimy remonty przekładni turbin wiatrowych wraz z ich testowaniem na hamowni, a wspólnie z Total Wind PL jesteśmy w stanie oferować ich wymianę oraz podstawowe usługi serwisowe. Obecnie pracujemy nad stworzeniem własnego magazynu przekładni, aby jeszcze lepiej odpowiadać na potrzeby naszych klientów. Energetyka wiatrowa to obszar, który w Polsce w najbliższych latach będzie się bardzo dynamicznie rozwijał, a FAMUR Gearo chce brać czynny udział w tym wzroście, systematycznie rozszerzając zakres oferty dla klientów tego sektora, czego przykładem jest projekt własnej przekładni wiatrowej, który zostanie zakończony w bieżącym roku – podsumowuje <strong>Paweł Majcherkiewicz</strong>.</p>
    </Aside>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Radosnych Świąt Wielkanocnych</h1>
        <div>
          <p>Już za chwilę będziemy obchodzić Wielkanoc, dlatego przyjmijcie życzenia wielu powodów do radości, wzajemnej życzliwości i pomyślności. Nim jednak zaczniemy w pełni celebrować święta, zachęcamy do przeczytania marcowego numeru newslettera Grenevii.</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>FAMUR: <span className="grText">byliśmy partnerem Forum Operatorów Farm Wiatrowych 2024</span></h1>
        <p><strong>FAMUR był Platynowym Partnerem na tegorocznym Forum Operatorów Farm Wiatrowych (FOFW) 2024, które odbyło się w dniach 6 i 7 marca w Sopocie. To jedno z najważniejszych spotkań branżowych w Polsce i regionie Europy Środkowo-Wschodniej, które od lat przyciąga uwagę kluczowych graczy z sektora wiatrowego.</strong></p>
        <Image pic={pic1} />

        <p>W ramach sesji otwierającej wystąpił wiceprezes zarządu FAMUR SA odpowiedzialny za rozwoju energetyki wiatrowej Paweł Majcherkiewicz, który miał okazję podzielić się naszą ofertą dla tego sektora.</p>
        <p>Z kolei drugiego dnia forum FAMUR zorganizowała warsztat pt. „Wymiana głównych komponentów – przekładnia. Współpraca na linii operator farmy wiatrowej – dostawca kompleksowych usług”.</p>
        <p>Na miejscu obecni byli także przedstawiciele obszaru FAMUR Gearo oraz spółki Total Wind PL, którzy aktywnie budowali relacje biznesowe z innymi uczestnikami tego spotkania.</p></TextContainer>

        <TextContainer id="section2" theme="light">

<h1>Elgór + Hansen: <span className="grText">dostarczyliśmy stacje transformatorowe dla nowych klientów</span></h1>
<p><strong>Elgór + Hansen kontynuuje swój rozwój jako dostawca stacji transformatorowych. Firma rozpoczęła w ostatnim czasie dostawy dla kilku kluczowych klientów.</strong></p>
<Image pic={pic2} />
<p>Rozpoczęto dostawy m.in. dla Spectris Energy Sp. z o.o., jednego z większych deweloperów farm PV w Polsce. Umowa między firmami, zawarta jeszcze w grudniu 2023 roku, obejmuje dostawę kontenerowych stacji transformatorowych dla 28 obiektów oraz usługę projektu włączenia instalacji do sieci OSD. Planowane dostawy mają objąć farmy o różnych mocach, w tym także instalację o mocy 10MWp.</p>
<p>Kolejnym kamieniem milowym dla Elgór + Hansen było zrealizowanie kontraktu dla Altrafo Sp. z o.o., dla której to spółki dostarczono pierwszą stację w obudowie betonowej. Stacja stworzona przez E+H trafiła na farmę PV w okolicy Kalisza.</p>
<p>Dodatkowo, Elgór + Hansen z sukcesem zrealizował dostawę dla firmy Power Sp. z o.o. Projekty obejmował posadowienie dwóch kontenerowych stacji transformatorowych, każdej o mocy 2x630kVA, na terenie obszaru Natura 2000 w południowo-wschodniej Polsce. Nasza spółka była odpowiedzialna nie tylko za dostawę, ale również za współpracę przy uzgodnieniach z operatorami sieci oraz za poprawne posadowienie i włączenie instalacji farmy.</p>
 </TextContainer>


      <TextContainer id="section3" theme="dark">

        <h1>Projekt Solartechnik:<span className="grText"> sfinalizowaliśmy umowę z KGHM</span></h1>
              <p><strong>Projekt Solartechnik sfinalizował transakcję sprzedaży ośmiu farm fotowoltaicznych dla KGHM Polska Miedź. Instalacje o łącznej mocy ok. 50 MW, które należą już do jednego z największych odbiorców energii elektrycznej w Polsce, zlokalizowane są w Głogowie (woj. dolnośląskie), Gniewinie (woj. pomorskie), Korytach (woj. łódzkie), od października 2023 r. do miedziowej spółki należała już instalacja Żuki (woj. wielkopolskie).</strong></p>
              <Image pic={pic3} />
               <p>Zakupione przez KGHM farmy fotowoltaiczne zapewnią około dwa proc. pokrycia zapotrzebowania na energię elektryczną w KGHM.</p>
               <p>W ramach usługi posprzedażowej O&M Projekt Solartechnik będzie odpowiadać za utrzymanie wielkoskalowych projektów.</p>
               <p>Współpracę z spółką KGHM Polska Miedź rozpoczęto w 2022 r. od pionierskiego kontraktu PPA na sprzedaż energii produkowanej na jednej z naszych farm PV. Dziękujemy za zaufanie i współpracę.</p>
        </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>IMPACT: <span className="grText">trwa montaż naszej nowej linii produkcyjnej</span></h1>
        
 <p><strong>Będzie to najnowocześniejsza tego typu instalacja w Europie, dzięki której co 11 minut powstanie nowa bateria. Pierwsze systemy bateryjne powinny zjechać z taśmy jeszcze w kwietniu br. Tak znaczące podniesienie zdolności produkcyjnych pozwoli spółce wzmocnić jej pozycję wśród wiodących producentów systemów bateryjnych dla transportu publicznego i przemysłu. </strong></p>
 <Gallery>
          <Image pic={pic4} /> 
          <Image pic={pic5} /> 
        </Gallery>
         <p>Nowa linia umożliwi wyprodukowanie 16 tysięcy sztuk baterii, przy obecnych zdolnościach wynoszących około 2,5 tys. egzemplarzy. Jest także gwarantem powtarzalnej jakości produktu, dzięki automatyzacji procesów, w tym montażu poszczególnych komponentów z wykorzystaniem robotów, precyzyjnemu dozowaniu, kontroli wizyjnej oraz możliwości przeprowadzenia szeregu testów.</p>
         <p>Dla klientów oznacza to z kolei pewność realizacji wielkoskalowych wolumenów zamówień i terminowe dostawy oparte o europejski łańcuch dostaw. </p>
         <p>Nowa linia będzie miała 58 metrów długości i 19 szerokości. Jest to rozwiązanie typu Flex. Zastosowanie pętli produkcyjnej pozwala na optymalizację produkcji w zależności od typu baterii. W efekcie gwarantuje oszczędność zarówno miejsca, jak i kosztów ponoszonych przez inwestora. </p>
         <p>Dodatkowo, dbając o bezpieczeństwo pracowników na linii produkcyjnej, zastosowano kamery termowizyjne. W przypadku wykrycia podwyższonej temperatury modułu, uruchamiana jest natychmiastowa procedura jego zabezpieczenia.</p></TextContainer>

      <TextContainer id="section5" theme="esg">

        <h1>Polityki <span className="grText">społeczne w Grupie Grenevia</span></h1>
        <p><strong>W trosce o zapewnienie otwartego i wspierającego środowiska pracy dla wszystkich pracowników Grupy Grenevia, wprowadziliśmy na początku tego roku we wszystkich naszych segmentach zestaw polityk społecznych dotyczących różnorodności, poszanowania prac człowieka, a także działań antymobbingowych i antydyskryminacyjnych. To ważne dla nas dokumenty, dlatego chcemy, abyście mieli pełną świadomość Waszych praw, a także zasad, którymi chcemy kierować się w naszych miejscach pracy.</strong></p>
        <Image pic={pic6} />
        <h3>Polityka Różnorodności</h3>
        <p>Nasza firma zobowiązuje się do promowania i utrzymywania różnorodności w miejscu pracy. Wierzymy, że różnorodność perspektyw, doświadczeń i tożsamości wzbogaca naszą kulturę organizacyjną i przyczynia się do naszego sukcesu. Zobowiązujemy się do równych szans zatrudnienia dla wszystkich, niezależnie od rasy, płci, wieku, pochodzenia narodowego, orientacji seksualnej, niepełnosprawności czy innych czynników.</p>
        <h3>Polityka Poszanowania Praw Człowieka</h3>
        <p>Potwierdzamy nasze zobowiązanie do poszanowania praw człowieka w każdym aspekcie naszej działalności. Zapewniamy, że wszystkie nasze działania biznesowe są przeprowadzane w sposób, który szanuje prawa i godność każdej osoby. Nasze działania są w zgodzie z międzynarodowymi standardami praw człowieka.</p>
        <h3>Polityka Antymobbingowa i Antydyskryminacyjna</h3>
        <p>Zdecydowanie sprzeciwiamy się wszelkim formom mobbingu i dyskryminacji. Wprowadzamy procedury, które umożliwiają zgłaszanie i rozpatrywanie skarg w sposób sprawiedliwy i poufny. Dążymy do szybkiego i skutecznego rozwiązywania problemów, aby zapewnić, że nasze miejsce pracy jest wolne od zastraszania i dyskryminacji.</p>
        <h3>Gdzie znaleźć pełne dokumenty polityk społecznych?</h3>
        <p>Jeśli masz trudności w odnalezieniu dokumentów zawierających pełną treść naszych polityk społecznych, pamiętaj, że możesz zgłosić się do koordynatora ESG w swojej spółce lub swojego działu HR.</p>
      </TextContainer>

      

    <SiteFooter />

    </>
  )
}

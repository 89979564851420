import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue8/0_main.jpg';
import pic1 from '../../assets/issue8/1_roze.jpg';
import pst from '../../assets/issue8/3_pst.jpg';

import elgor1 from '../../assets/issue8/2_elgor_1.jpg'
import elgor2 from '../../assets/issue8/2_elgor_2.jpg'

import raport1 from '../../assets/issue7/6-raport-1.jpg'
import raport2 from '../../assets/issue7/6-raport-2.jpg'
import raport3 from '../../assets/issue7/6-raport-3.jpg'

import impact1 from '../../assets/issue8/4_impact_1.jpg'
import impact2 from '../../assets/issue8/4_impact_2.jpg'
import impact3 from '../../assets/issue8/4_impact_3.jpg'
import impact4 from '../../assets/issue8/4_impact_4.jpg'





export default function Issue2() {

  const _links = [
    {label: "FAMUR: wystartowaliśmy z programem dla kobiet Róże Wiatru", target: "section1"},
    {label: "Elgór + Hansen: zwiększamy powierzchnię produkcyjno-magazynową", target: "section1b"},
    {label: "Projekt Solartechnik: powołaliśmy PST Trade SA", target: "section2"},
    {label: "IMPACT: przedłużamy współpracę z Solarisem i wchodzimy w nowe partnerstwo z ADL", target: "section3"},
    {label: "Grenevia wśród 38 wyróżnionych spółek!", target: "section4"},
  ];
  const _title = "Newsletter #8, grudzień 2023";
;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Grenevia Spółką Świadomą Klimatycznie</h1>
        <div>
          <p>Otrzymaliśmy prestiżowy tytuł przyznawany spółkom giełdowym.  Co jeszcze wydarzyło się w ramach naszej Grupy? Spełniamy obietnice strategii biznesowej, podpisujemy nowe kontrakty, ale też stawiamy na rozwój spółek i jej pracowników. Sprawdźcie sami i przeczytajcie najnowsze wiadomości o Grupie Grenevia!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>FAMUR: <span className="grText">wystartowaliśmy z programem dla kobiet Róże Wiatru</span></h1>
        <p><strong>Róże Wiatru to inicjatywa stworzona specjalnie dla kobiet pracujących w Famurze, mająca na celu integrację, dzielenie się doświadczeniami, jak również dokształcanie w ramach w szkoleń i warsztatów.</strong></p>
        <Image pic={pic1} />

        <p>Do programu mogła się zgłosić każda kobieta pracująca w Famurze, niezależnie od zajmowanego stanowiska. W jego ramach zaplanowano 3-4 spotkania w roku, podczas których kobiety mogą dzielić się sukcesami, ale także rozmawiać o zawodowych potrzebach. Zaplanowano również szkolenia i warsztaty z profesjonalnymi trenerami. Wśród zgłoszonych tematów są szkolenia z zakresu radzenia sobie ze stresem czy rozwiązywania konfliktów.</p>
        <p>Jak podkreślają inicjatorzy programu, Róże Wiatru mają nie tylko umożliwić integrację, ale także przyczynić się do budowania silnej, wzajemnie się wspierającej społeczności kobiet Famuru. – <em>Chcemy, by kobiety czuły się pewnie w swoim miejscu pracy. To miejsce, w którym "kwitną" pomysły i inspiracje, tworzące korzystne środowisko dla sukcesu i rozwoju każdej kobiety w naszej spółce</em> – podkreśla <strong>Anna Łomnicka, dyrektorka HR w Grenevia SA FAMUR</strong>.</p>
        </TextContainer>

        <TextContainer id="section1b" theme="light">

<h1>Elgór + Hansen: <span className="grText">zwiększamy powierzchnię produkcyjno-magazynową</span></h1>
<p><strong>Elgór + Hansen SA, rozwijając swoją pozycję na rynku stacji transformatorowych, podwoił powierzchnię produkcyjno-magazynową swojej hali w Zabrzu.
</strong></p>

<p>Zabrzański zakład powiększył się z 1300 m<sup>2</sup> do 2600 m<sup>2</sup>. Otwiera to przed naszą spółką możliwość podwojenia potencjału produkcyjnego. Dodatkowo, wydzielono nową przestrzeń biurowo-projektową, ułatwiającą wdrażanie kompleksowych rozwiązań inżynierskich na miejscu. Jak zaznacza Elgór + Hansen, ta ekspansja była kluczowym posunięciem w odpowiedzi na rosnące zainteresowanie spółką na dynamicznie rozwijającym się rynku odnawialnych źródeł energii (OZE).
</p>
<Gallery>
          <Image pic={elgor1} /> 
          <Image pic={elgor2} /> 
        </Gallery>
 </TextContainer>


      <TextContainer id="section2" theme="dark">

        <h1>Projekt Solartechnik:<span className="grText"> powołaliśmy PST Trade SA</span></h1>
              <p><strong>Nowa spółka PST Trade w Grupie Projekt Solartechnik jest odpowiedzialna za obrót energią na rynku lokalnym i hurtowym. Jej powstanie to jeden z elementów strategii biznesowej PST na lata 2023-2027. </strong></p>
        <p>W ofercie PST Trade znajduje się sprzedaż energii w formule kontraktów PPA /cPPA, sprzedaż gotowych farm PV z portfolio Projekt Solartechnik oraz zakup energii od innych wytwórców wraz z usługą bilansowania handlowego i doradztwo energetyczne (PST energy care) w obszarze optymalizacji zakupu mediów energetycznych.</p>        
        <Image pic={pst  } />
        <p>W zarządzie spółki zasiadają Marcin Ludwicki, prezes zarządu oraz Tomasz Bodetko, wiceprezes zarządu.</p>
        <p><strong>Więcej o działalności PST Trade przeczytasz tutaj:</strong> <a href="https://projektsolartechnik.com/pst-trade/" target='_blank'>https://projektsolartechnik.com/pst-trade/</a>.</p>
        </TextContainer>

      <TextContainer id="section3" theme="light">

        <h1>IMPACT: <span className="grText">przedłużamy współpracę z Solarisem i wchodzimy w nowe partnerstwo z ADL</span></h1>
        
 <p><strong>Impact Clean Power Technology przedłużył o kolejne trzy lata swoją 11-letnią współpracę z Solaris Bus & Coach, producentem zeromisyjnych autobusów miejskich, oraz podpisał nowy kontrakt z brytyjskim dostawcą e-autobusów, firmą Alexander Dennis - ADL.</strong></p>
 <Gallery>
          <Image pic={impact1} /> 
          <Image pic={impact2} /> 
          <Image pic={impact3} /> 
          <Image pic={impact4} /> 
        </Gallery>
        <p>W ramach najnowszego kontraktu z Solarisem Impact dostarczy polskiemu producentowi autobusów baterie HE 100, które wyróżniają się większą gęstością energii i mocą, przy jednoczesnym zmniejszeniu wagi i objętości. Te przełomowe systemy będą produkowane w nowo powstałej GigafactoryX.</p>
        <p>Dodatkowo Impact podpisał kontrakt z ADL, brytyjskim producentem autobusów, na dostarczenie systemów bateryjnych do modeli Enviro100EV i piętrowego Enviro400EV. Dzięki temu ważnemu partnerstwu Impact rozszerzy swój wpływ na rynku europejskim.</p>
        <p>Pierwsze dostawy dla klientów w Wielkiej Brytanii i Irlandii zaplanowano na 2024 rok. Systemy bateryjne opracowano z myślą o specyficznych potrzebach ADL – mają one zwiększoną odporność na niskie temperatury, dodatkowe zabezpieczenia chroniące przed uszkodzeniami fizycznymi opracowane razem z klientem, a przede wszystkim kompaktowe wymiary, co pozwoliło ADL na zwiększenie przestrzeni dla pasażerów.</p>
      </TextContainer>

      <TextContainer id="section4" theme="dark">

        <h1>Grenevia <span className="grText">wśród 38 wyróżnionych spółek!</span></h1>
        <p><strong>Grenevia SA jest jedną z firm, która otrzymała prestiżowy tytuł Spółki Świadomej Klimatycznie. To wyróżnienie jest wynikiem Badania Świadomości Klimatycznej Spółek (Corporate Climate Crisis Awareness Study) zainicjowanego przez Fundację Standardów Raportowania, Stowarzyszenie Emitentów Giełdowych oraz Bureau Veritas, ze wsparciem partnerów MATERIALITY i Energopomiar.</strong></p>
        <Image pic={introPic  } />
<p>Badanie, które oceniało spółki na podstawie 10 stałych kryteriów, pokazało znaczący wzrost świadomości klimatycznej wśród przedsiębiorstw. W tym roku tytuł Spółki Świadomej Klimatycznie otrzymało aż 38 spółek, co stanowi prawie dwukrotny wzrost w porównaniu do poprzedniego roku. Ponadto, jak podają organizatorzy, średnia ocena wszystkich spółek wzrosła do 40,2%.</p>
<p>Grenevia otrzymała siedem na dziesięć możliwych punktów. W kontekście zaprezentowanej na początku 2023 roku Strategii Zrównoważonego Rozwoju Grupy Grenevia wyróżnienie to jest dowodem na skuteczne zaangażowanie naszej firmy w działania proekologiczne, ale również ich znaczenie dla inwestorów i dostrzegania naszej transformacji przez instytucje finansowe.</p> </TextContainer>



     

    <SiteFooter />

    </>
  )
}

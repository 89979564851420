import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Logo from '../elements/Logo';
import Button from '../elements/Button';

export default function SiteHeader(props) {

  const [menu, setMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  const toggleMenu = () => setMenu(!menu);
  var lastScrollTop = 0;
  useEffect(() => { 
    document.addEventListener("scroll", (event) => {
      var currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;  
      setScroll(currentScrollTop > lastScrollTop);
      setMenu(false);
      lastScrollTop = currentScrollTop;
    });
  }, [])

  return (
    
    <header className={`grHeader${scroll ? ' grHeader--hidden' : ''}`}>
      
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
             <Logo />
          </div>
          <div className='col-md-6'>
            <div className='grHeaderContainer'>
              <div className='grHeaderIssue'>{props.title}</div>
              <div className='grHeaderCta'><Button label='Spis treści' action={toggleMenu} /></div>
            </div>
          </div>
        </div>
      </div>

      <div className={`grHeaderSidebar ${menu ? 'grHeaderSidebar--active' : ''}`}>
        
        <button 
          className='grHeaderSidebar__close'
          onClick={() => setMenu(false)}></button>
        
        <nav className='grNav'>
          <h2>Spis treści</h2>
          {props.menu.map((item, key) => 
            <div key={key} className='grNav__item'><a href={`#${item.target}`}  className='grNav__link'>{item.label}</a></div>)}
        </nav>

        <div className='grHeaderSidebar__footer'>
          <Link to='/archiwum' className='grButton grButton--green grButton--fluid'>Zobacz archiwum</Link>
        </div>
          
      </div>
{/* 
      {popup ? <div className='grHeaderPopup'>
        <div className='grHeaderPopup__header'>
          <button className='grCloseBtn' onClick={() => setPopup(false)}></button></div>          
        <div className='grHeaderPopup__content'><Archive /></div>
      </div> : ''} */}
      
    </header>
  )
}

import React from 'react';

// component:
import Archive from '../components/Archive';
import SimpleHeader from '../components/SimpleHeader';
import SiteFooter from '../components/SiteFooter';

export default function ArchivePage() {


  return (
    <>
      <SimpleHeader />
      <Archive />
      <SiteFooter />
    </>
  )
}

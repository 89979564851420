import { Link } from 'react-router-dom';
import Logo from '../elements/Logo';

export default function SimpleHeader(props) {
  return (
    
    <header className='grSimpleHeader'>
      
      <div className='container'>
        <div className='row'>
          <div className='col'>
             <Logo />
          </div>
        </div>
      </div>

    </header>
  )
}

import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue5/cover.jpg';
import pic1_2 from '../../assets/issue5/mb.jpg';
import pic2_1 from '../../assets/issue5/elgor.jpg';
import pic3_1 from '../../assets/issue5/pst.jpg';
import pic3_2 from '../../assets/issue5/lukaszpasnik.jpg';
import pic4_1 from '../../assets/issue5/impact.jpg'
import pic5_1 from '../../assets/issue5/esg-ok.jpg'

export default function Issue2() {

  const _links = [
    {label: "FAMUR: uzupełniamy kompetencje na rynku energetyki wiatrowej", target: "section1"},
    {label: "Elgór + Hansen: zwiększamy moce oferowanych stacji transformatorowych", target: "section2"},
    {label: "Projekt Solartechnik: pozyskaliśmy 128 mln zł na budowę farm PV", target: "section3"},
    {label: "IMPACT: ruszamy z impACTIVE Challenge", target: "section4"},
    {label: "Przedstawiamy koordynatorów ESG", target: "section5"},
  ];
  const _title = "Newsletter #5, lipiec 2023";
  const _aside1 = <>
    <Aside image={pic1_2} theme='light'>
      <p>– Akwizycja Total Wind PL istotnie wpływa na proces dywersyfikacji działalności segmentu FAMUR i wzmacnia naszą pozycję na rynku energetyki wiatrowej. Spółka ma wieloletnie doświadczenie i szereg kompetencji, które pozwolą nam uzupełnić rozwijaną obecnie ofertę remontów o wymianę głównych komponentów turbin, a także serwis i usługi montażowe. Konsekwentne zwiększanie naszego zaangażowana w sektorze wiatrowym nie tylko pozwala nam rozszerzać działalność zakładów produkcyjnych Famuru, ale pozostaje także ważnym elementem wdrożenia Strategii Zrównoważonego Rozwoju – <strong>Mirosław Bendzera, prezes zarządu FAMUR SA</strong>.</p>
    </Aside>
</>
    const _aside2 = <>
    <Aside image={pic3_2} theme='light'>
      <p>– Umowa z PFR otwiera w historii Projekt Solartechnik perspektywiczny rozdział pisany wspólnie z nowym wartościowym partnerem – mówi <strong>Łukasz Paśnik, finance manager Projekt Solartechnik</strong>. – To kolejne, duże finansowanie, które jest zgodne z założeniami przyjętej w maju br. strategii biznesowej naszej firmy. Dążymy do pozyskania jak największego finansowania zewnętrznego projektów – dodaje.</p>
    </Aside>
  </>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Nowa akwizycja w ramach Grupy Grenevia</h1>
        <div>
          <p>Nabyliśmy akcje spółki Total Wind PL, by wzmocnić rozwój segmentu FAMUR na rynku energetyki wiatrowej. To nie jedyne dobre wiadomości dotyczące naszych spółek portfelowych.</p>
          <p>Zapraszamy do lektury newslettera!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark" aside={_aside1}>

        <h1>FAMUR: <span className="grText">uzupełniamy kompetencje na rynku energetyki wiatrowej</span></h1>
        <p><strong>20 lipca 2023 roku Grenevia SA nabyła pakiet ok. 75% akcji w kapitale zakładowym spółki Total Wind PL. Akwizycja to istotny krok w dywersyfikacji działalności segmentu FAMUR. Wartość transakcji wyniosła ok. 4,5 mln euro.</strong></p>

        <p>Segment FAMUR jest odpowiedzialny za technologie, produkty i usługi dla sektora wydobywczego i energetyki wiatrowej. Dzięki swojemu potencjałowi inżynieryjnemu i parkowi maszynowemu FAMUR wprowadził w 2022 roku do oferty remonty i serwis przekładni do turbin wiatrowych. Obecna akwizycja pozwoli uzupełnić kompetencje tego segmentu w obszarze rozwiązań i usług dla sektora wiatrowego.</p>
        <p>Total Wind PL to polska spółka, której głównym przedmiotem działalności są usługi instalacji turbin wiatrowych, a także serwis i wymiana głównych komponentów dla kluczowych producentów turbin. Posiada 18 lat doświadczenia, a od momentu swojego powstania zainstalowała już ponad 1 000 turbin wiatrowych. Na koniec 2022 roku Total Wind PL zatrudniał ok. 100 osób, w tym 80 pracowników technicznych. Spółka realizuje projekty w 10 krajach. W ubiegłym roku osiągnęła przychody w wysokości 36,3 mln PLN, z których ok. 90% pochodzi z działalności na rynkach zagranicznych.</p>
        </TextContainer>

      <TextContainer id="section2" theme="light">

        <h1>Elgór + Hansen: <span className="grText"> zwiększamy moce oferowanych stacji transformatorowych</span></h1>
        <p><strong>Elgór + Hansen poszerza swoją ofertę produktową o stacje transformatorowe o mocy do 8 MVA, przeznaczone do zasilania farm fotowoltaicznych (stacje jednotransformatorowe lub dwutransformatorowe).</strong></p>

        <Image pic={pic2_1} />

        <p>Spółka wkroczyła na rynek OZE w ubiegłym roku dzięki ofercie stacji transformatorowych FUTURA. Wówczas produkty te miały maksymalną moc 1MVA. W odpowiedzi na potrzeby rynku moce te obecnie zwiększono.</p>
        <p>Projekt nowego produktu jest w fazie realizacji, pojawiły się pierwsze zamówienia na stacje w obudowach metalowych o mocy 2MVA i 3,15MVA, które jeszcze w tym roku trafią do klientów segmentu.</p>
        
        </TextContainer>

      <TextContainer id="section3" theme="dark" aside={_aside2}>

        <h1>Projekt Solartechnik: <span className="grText">pozyskaliśmy 128 mln zł na budowę farm PV</span></h1>
        <p><strong>Projekt Solartechnik pozyskał od Polskiego Funduszu Rozwoju finansowanie w wysokości 128 mln zł. Przeznaczy je na budowę 26 elektrowni słonecznych.</strong></p>
        <p>Farmy PV będą zlokalizowane w województwach: dolnośląskim, kujawsko-pomorskim, lubelskim, łódzkim, mazowieckim, opolskim, pomorskim, śląskim, wielkopolskim i zachodniopomorskim. Ich łączna moc to 43,09 MW. </p>
        <Image pic={pic3_1} />
      </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>IMPACT: <span className="grText">ruszamy z impACTIVE Challenge</span></h1>
        <p><strong>15 lipca w spółce Impact Clean Power Technology ruszyła pierwsza edycja wzywania sportowego impACTIVE Challenge! Przez najbliższe 2 miesiące pracownicy tego segmentu będą dzięki aplikacji ACTIVY zbierać punkty za każdy krok lub kilometr, który przejdą, przebiegną, przejadą na rowerze, rolkach i wrotkach. Firma chce w ten sposób promować zdrowy styl życia.</strong></p>
        <Image pic={pic4_1} />
<p>Jak piszą organizatorzy akcji, „wiemy, że sport ma ogromny wpływ na nasze samopoczucie i jakość życia, dlatego promujemy aktywność fizyczną na świeżym powietrzu”. Już pierwszy weekend od rozpoczęcia akcji okazał się niesamowitym sukcesem. Mimo upałów, łącznie pracownikom Impactu udało się pokonać 1 133 km i zrobić 405 338 kroków. W wyzwaniu wzięło udział ponad 50 osób.</p>
<p>impACTIVE Challange to nie tylko świetna zabawa, ale również inspirujący sposób na rozpoczęcie lub utrzymanie aktywnego stylu życia. Liczy się dojście czy dojazd na rowerze do pracy, spacer z psem, wyjście do parku. Systematyczna aktywność będzie premiowana.</p> 
<p>Trzymamy kciuki za uczestników impACTIVE Challenge!</p>    </TextContainer>

      <TextContainer id="section5" theme="esg">

<h1>Przedstawiamy koordynatorów ESG</h1>
<p><strong>Strategia Zrównoważonego Rozwoju Grenevii to podstawa naszego odpowiedzialnego inwestowania, wyznaczająca kierunek rozwoju całej grupy kapitałowej. By usprawnić jej wdrażanie i zbieranie danych raportowych z zakresu ESG wyznaczyliśmy w każdej spółce-segmencie Grenevii koordynatora ESG. Oto oni:</strong></p>
<p><ul><li><strong>Agata Maj</strong> – menedżerka ds. zrównoważonego rozwoju ESG w Grenevii i koordynatorka ESG w FAMUR</li>
<li><strong>Maciej Frycz</strong> – dyrektor finansowy i koordynator ESG w Elgór + Hansen</li>
<li><strong>Bartosz Bendzera</strong> – koordynator RI i ESG w Projekt Solartechnik</li>
<li><strong>Karol Waszczuk</strong> – lider systemów zarządzania jakością i koordynator ESG w Impact Clean Power Technology</li></ul>
</p>
<Image pic={pic5_1} />
<p>Nasi koordynatorzy ESG to osoby, które dobrze znacie dzięki pełnionym przez nich funkcjom w spółkach Grenevii. Każdy z nich ma unikatowe umiejętności i wiedzę – od zagadnień prawnych, inwestorskich, finansowych po kontroling i zarządzanie jakością. Dzięki temu będą się mogli wspierać w swojej pracy, a przede wszystkim efektywniej wcielać w życie założenia Strategii Zrównoważonego Rozwoju całej Grupy Grenevia. By tego dokonać, potrzebują wsparcia nas wszystkich i naszego zrozumienia dla znaczenia ESG w Grupie Grenevia.</p>
<p>Wyznaczyliśmy sobie w tym obszarze ambitne cele, których osiągnięcie w ciągu najbliższych kilku lat jest dla nas priorytetem (czytaj więcej o SZR - <a href="https://grenevia.com/zrownowazony-rozwoj/" target='_blank'>https://grenevia.com/zrownowazony-rozwoj/</a>). To konieczność ze względów prawnych i z uwagi na restrykcje związane z pozyskiwaniem finansowania na rozwój naszych segmentów, ponieważ instytucje finansowe bardzo starannie przyglądają się działaniom spółek w obszarze ESG. Przede wszystkim jednak to zmiany, które chcemy wprowadzić, by dokonać autentycznej zielonej transformacji wewnątrz grupy i wspierać ją szerzej na zewnątrz.</p>

</TextContainer>

     

    <SiteFooter />

    </>
  )
}

import React, { Children } from 'react';
import Slider from "react-slick";

export default function Gallery(children) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade:true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            dots: false,
          }
        }
      ]
    };

    return (
      <>
        <Slider {...settings}>
          {children.children.map((el,index) => <div key={index}>{el}</div>)}    
        </Slider>
      </>
    );
  }
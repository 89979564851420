import React from 'react';

// elements:
import Aside from '../../elements/Aside';
import Image from '../../elements/Image';

// component:
import SiteHeader from '../../components/SiteHeader';
import SiteFooter from '../../components/SiteFooter';
import Intro from '../../components/Intro';
import TextContainer  from '../../components/TextContainer';
import Gallery from '../../components/Gallery';

// assets:
import introPic from '../../assets/issue3/intro.jpg';
import pic2_1 from '../../assets/issue3/eh1.jpg';
import pic2_2 from '../../assets/issue3/eh2.jpg';
import pic3_1 from '../../assets/issue3/famur1.jpg';
import pic3_2 from '../../assets/issue3/famur2.jpg';
import pic4_1 from '../../assets/issue3/pst1.jpg';
import pic4_2 from '../../assets/issue3/pst2.jpg';
import pic5_1 from '../../assets/issue3/esg.jpg';
import pic5_2 from '../../assets/issue3/jankowskit.jpg';
import pic5_3 from '../../assets/issue3/agatamaj.jpg';

import biuro1 from '../../assets/issue3/biuro/biuro1.jpg';
import biuro2 from '../../assets/issue3/biuro/biuro2.jpg';
import biuro3 from '../../assets/issue3/biuro/biuro3.jpg';
import biuro4 from '../../assets/issue3/biuro/biuro4.jpg';
import biuro5 from '../../assets/issue3/biuro/biuro5.jpg';
import biuro6 from '../../assets/issue3/biuro/biuro6.jpg';
import biuro7 from '../../assets/issue3/biuro/biuro7.jpg';
import biuro8 from '../../assets/issue3/biuro/biuro8.jpg';
import biuro9 from '../../assets/issue3/biuro/biuro9.jpg';
import biuro10 from '../../assets/issue3/biuro/biuro10.jpg';
import biuro11 from '../../assets/issue3/biuro/biuro11.jpg';
import biuro12 from '../../assets/issue3/biuro/biuro12.jpg';
import biuro13 from '../../assets/issue3/biuro/biuro13.jpg';
import biuro14 from '../../assets/issue3/biuro/biuro14.jpg';
import biuro15 from '../../assets/issue3/biuro/biuro15.jpg';
import biuro16 from '../../assets/issue3/biuro/biuro16.jpg';
import biuro17 from '../../assets/issue3/biuro/biuro17.jpg';
import biuro18 from '../../assets/issue3/biuro/biuro18.jpg';


export default function Issue2() {

  const _links = [
    {label: "Grenevia: oto nasze nowe miejsce pracy w .KTW", target: "section1"},
    {label: "Elgór+Hansen: promowaliśmy nasze produkty w Mongolii i Szwecji", target: "section2"},
    {label: "FAMUR: czas zagranicznych targów", target: "section3"},
    {label: "Projekt Solartechnik: nowe spółki we Francji i Rumunii", target: "section4"},
    {label: "Grenevia stawia na ESG. Zostaliśmy częścią międzynarodowej inicjatywy zrównoważonego biznesu", target: "section5"},
  ];
  const _title = "Newsletter #3, maj 2023";
  const _aside1 = <>
    <Aside image={pic5_2} theme='light'>
      <p>– Mijający miesiąc to dla nas czas intensywnej pracy nad raportem zintegrowanym, który zostanie niedługo opublikowany. To żmudny proces zbierania i analizy wielu danych. Wdrażanie w życie strategii zrównoważonego rozwoju to jednak konieczność dla naszej Grupy tak pod względem odpowiedzialności społecznej, jak i biznesowej. Dlatego jesteśmy niezwykle wdzięczni przedstawicielom wszystkich segmentów Grupy Grenevia, którzy wspierali nas w tym zadaniu – ocenił <strong>Tomasz Jankowski, dyrektor Relacji Inwestorskich Grenevia SA oraz lider zespołu zajmującego się SZR Grupy Grenevia</strong>.</p>
    </Aside>
    <Aside image={pic5_3} theme='light'>
      <p>- Nie wyobrażamy sobie prowadzenia biznesu bez zwracania uwagi na czynniki związane z ESG, czyli środowiskiem, społeczeństwem i ładem korporacyjnym. Jeśli chcemy być konkurencyjni na rynku nie tylko teraz, ale też za wiele lat, to musimy myśleć o zrównoważonym rozwoju. Bardzo nam zależy na budowaniu tej świadomości wśród pracowników segmentów Grenevii – dodaje <strong>Agata Maj, ESG Value Stream Manager w TDJ</strong>.</p>
    </Aside>
  </>;


  return (
    <>
      
      <SiteHeader menu={_links} title={_title} />

      <Intro backgroundImg={introPic}>
        <h1>Grenevia ma nową siedzibę!</h1>
        <div>
          <p>Koniec kwietnia oraz maj okazały się dla segmentów Grenevii czasem zagranicznych wyjazdów, targów i konferencji. Dla samej Grenevii mijający miesiąc był również wyjątkowy – otworzyliśmy nową siedzibę w centrum Katowic. To jednak niejedyne ciekawe wydarzenia, jakie miały miejsce w naszej Grupie. </p>
          <p>Zachęcamy do przeczytania newslettera!</p>
        </div>
      </Intro>

      <TextContainer id="section1" theme="dark">

        <h1>Grenevia: <span className="grText">oto nasze nowe miejsce pracy w .KTW</span></h1>
        <p><strong>15 maja br. oficjalnie otwarto nową siedzibę Grenevii w biurowcu .KTW I w okolicy legendarnego Spodka, w centrum Katowic.</strong></p>

        <p>
        Oryginalna bryła biurowca składa się z dwóch wież, które mają formę przesuniętych bloków – niższa mierzy 66 metrów, a wyższa 133 metry, przez co stały się punktem orientacyjnym dla wielu osób odwiedzających Katowice. Autorami projektu .KTW są architekci z pracowni Medusa Group, inwestorem zaś TDJ Estate.</p>

        <Gallery>
          <Image pic={biuro1} /> 
          <Image pic={biuro2} /> 
          <Image pic={biuro3} /> 
          <Image pic={biuro4} /> 
          <Image pic={biuro5} /> 
          <Image pic={biuro6} /> 
          <Image pic={biuro7} /> 
          <Image pic={biuro8} /> 
          <Image pic={biuro9} /> 
          <Image pic={biuro10} /> 
          <Image pic={biuro11} /> 
          <Image pic={biuro12} />
          <Image pic={biuro13} />
          <Image pic={biuro14} />
          <Image pic={biuro15} />
          <Image pic={biuro16} />
        </Gallery>

        <p>Ten nowoczesny kompleks biurowy posiada zielone certyfikaty – BREEAM na poziomie Excellent oraz WELL Health-Safety Rating – co dla Grenevii, zaznaczającej swoją obecność w sektorze OZE, nie było bez znaczenia.</p>
        <p>Otwarcie nowego biura to kolejny krok w transformacji działalności naszej Grupy – począwszy od zmiany strategii biznesowej w 2021 roku, przez ogłoszenie strategii zrównoważonego rozwoju w styczniu tego roku, a następnie zmianę nazwy spółki na początku kwietnia br.</p>
        <p>Wcześniej pracownicy Grenevia SA dzielili biura z Oddziałem FAMUR przy ul. Armii Krajowej w Katowicach. Od maja zapraszamy na 5. piętro .KTW I przy al. Roździeńskiego 1a.</p>
      </TextContainer>

      <TextContainer id="section2" theme="light">

        <h1>Elgór+Hansen: <span className="grText">promowaliśmy nasze produkty w Mongolii i Szwecji</span></h1>
        <p><strong>Przedstawiciele Elgór+Hansen byli obecni na Polsko-Mongolskim Forum Biznesowym w stolicy Mongolii – mieście Ułan Bator, a następnie wybrali się na Międzynarodowe Targi Energetyczne ELFACK 2023 w szwedzkim Göteborgu.</strong></p>

        <Image pic={pic2_1} />
        <Image pic={pic2_2} />

        <p>Polsko-Mongolskie Forum Biznesowe odbyło się 25 kwietnia i było zorganizowane przez Polską Agencję Inwestycji i Handlu (PAIH), Ambasadę RP w Ułan Bator oraz Mongolską Narodową Izbą Handlu i Przemysłu Mongolii. Jego celem była wymiana wiedzy oraz doświadczeń pomiędzy przedsiębiorcami z obydwu krajów.</p>
        <p>W forum udział wzięło 20 firm reprezentujących polski przemysł i przeszło 350 uczestników ze strony mongolskiej, odbyło się ponad 200 spotkań biznesowych, a zwieńczeniem wydarzenia było podpisanie porozumień o współpracy pomiędzy podmiotami ze strony polskiej i mongolskiej.</p>
        <p>Następnie między 9 a 12 maja przedstawiciele Elgór+Hansen wybrali się do Göteborga jako uczestnicy Międzynarodowych Targów Energetycznych ELFACK 2023. Jest to największe spotkanie branży elektroenergetycznej w Europie Północnej. W trakcie wydarzenia odbyły się liczne seminaria i wykłady prowadzone przez kluczowych przedstawicieli sektora. Była to również świetna okazja do wymiany doświadczeń i zapoznania się z najnowszymi produktami i rozwiązaniami z branży elektrycznej i energetycznej.</p>      
      </TextContainer>

      <TextContainer id="section3" theme="dark">

        <h1>FAMUR: <span className="grText">czas zagranicznych targów</span></h1>
        <p><strong>FAMUR prowadzi obecnie swoją działalność dwutorowo – w przemyśle wydobywczym oraz w ramach energetyki wiatrowej. I właśnie na targach związanych z tymi branżami przedstawiciele marki prezentowali swoje oferty. Najpierw w Kopenhadze, a później w Pittsburghu.</strong></p>
          
        <p>Od 25 do 27 kwietnia FAMUR brał udział w targach Wind Europe w Kopenhadze. To największe wydarzenie sektora energii wiatrowej w Europie. Przedstawicieli marki można było spotkać w strefie zorganizowanej przez Polskie Stowarzyszenie Energetyki Wiatrowej.</p>
        <Image pic={pic3_2} />
        <p>Z kolei między 9 a 11 maja FAMUR wziął udział w targach US Coal Show – Longwall Edition w Pittsburghu, dzieląc stoisko ze swoim amerykańskim partnerem – firmą Seetech. Uczestnicy targów mogli na własne oczy zobaczyć wyprodukowaną w Polsce sekcję obudowy, która została specjalnie wypożyczona na to wydarzenie z kopalni Longview Mine w Wirginii Zachodniej, we wschodniej części Stanów Zjednoczonych, gdzie wkrótce będzie użytkowana.</p>
        <Image pic={pic3_1} />
      </TextContainer>

      <TextContainer id="section4" theme="light">

        <h1>Projekt Solartechnik: <span className="grText">nowe spółki we Francji i Rumunii</span></h1>
        <p><strong>Ważne kroki w międzynarodowej ekspansji Projekt Solartechnik. W Bukareszcie powstała siedziba spółki Projekt Solartechnik Romania S.R.L., a we francuskim Rouen – Projekt Solartechnik France SAS.</strong></p>
          
        <Image pic={pic4_1} />
        <Image pic={pic4_2} />

        <p>Zagraniczne spółki koncentrują swoją działalność na pozyskiwaniu terenów pod budowę wielkoskalowych farm fotowoltaicznych oraz na rozwoju projektów OZE na rynkach rumuńskim i francuskim. Na dalszym etapie działalności planowane jest rozszerzenie oferty o usługi EPC i O&M dla projektów greenfield i brownfield.</p>      
      </TextContainer>

      <TextContainer id="section5" theme="esg" aside={_aside1}>

        <h1>Grenevia stawia na ESG</h1> 
        <h2>Zostaliśmy częścią międzynarodowej inicjatywy zrównoważonego biznesu</h2>
        <p><strong>Zbieranie i analiza danych do raportu zintegrowanego z zakresu zrównoważonego rozwoju oraz budowanie strategii komunikacji w tym obszarze – to kluczowe majowe działania w zakresie ESG w Grupie Grenevia. Miło nam również poinformować, że Grenevia stała się częścią UN Global Compact (UNGC), największej na świecie inicjatywy skupiającej zrównoważony biznes.</strong></p>

        <Image pic={pic5_1} />
          
        <p>Od 2024 roku raportowanie niefinansowe będzie obowiązkiem dla wszystkich podmiotów giełdowych, które zatrudniają ponad 500 osób. FAMUR jako spółka już od kilku lat podawał takie dane. W tym roku wydamy pierwszy raport zintegrowany jako Grenevia SA.</p>
        <p>Wiele instytucji finansowych uzależnia dalszą współpracę i wsparcie spółek giełdowych właśnie od wdrażania strategii zrównoważonego rozwoju i odpowiedzi na potrzeby społeczne i środowiskowe. Grenevia, świadoma roli i wagi ESG w biznesie, ogłosiła w styczniu swoją strategię zrównoważonego rozwoju. </p>
        <h2>Grenevia <span className="grText">częścią UNGC</span></h2>     
        <p>Ostatni miesiąc to jednak nie tylko przygotowania do wydania raportu zintegrowanego, ale również przystąpienie do UN Global Compact (UNGC), które zostało powołane w 2000 roku przez Kofiego Annana, sekretarza generalnego ONZ. Od tego czasu UNGC inicjuje i prowadzi działania na rzecz środowiska, praw człowieka, przeciwdziałania korupcji oraz godnej i legalnej pracy. W jej szeregach znajduje się obecnie ponad 22 000 członków z całego świata. Kluczowa dla realizacji celów UNGC jest współpraca z rządami, międzynarodowymi organizacjami, firmami i instytucjami, w ramach której prowadzi szereg działań, stając się katalizatorem globalnych zmian w obszarze zrównoważonego biznesu.</p>
        <p>W kolejnych tygodniach planujemy szersze działania komunikacyjne, które pozwolą pracownikom Grupy Grenevia lepiej zapoznać się z rolą ESG w naszym biznesie.</p>
      </TextContainer>

    <SiteFooter />

    </>
  )
}
